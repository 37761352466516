:root{
    --theme-font: 'Montserrat', sans-serif;
    --heading-font: 'Playfair Display', serif;
    --primary-color: #263238;
    --secondary-color: #FF8C8C;
    --theme-color: #B3A5DD;
    --background-gradient: linear-gradient(101deg, #FDF7F2 -1.65%, #EDF3FB 97.51%);
    --extra-light-yellow: #FFFBF2;
    --extra-light-orange: #FFF8F8;
    --extra-light-purple:  #F5F7FD;
    --extra-light-blue: #F0FAFF;
    --texet-color: #72C2B6;
    --light-grey: #DEDEDE;
    --extra-light-grey: #FBFBFB;
    --dark-grey: #797979;
    --border-color: rgba(120, 136, 155, 0.30);
    --yellow: #EDC060;
    --footer-backgrund: #FCFCFC;
    --copyright-footer: #CBCBCB;
    --case-study-bg: rgba(110, 154, 149, 0.14);
    --case-study-color: #6E9A95;
    --atmosphere-bg: #EAF1F0;
    --features-border: #92A5A3;
    --features-text-color: #78889B;
    --result-btn-bg: #6D9A95;
    --result-card-bg: #E6F5F4;
    --result-card-second: #BADCD8;
    --result-card-third: #7EACA5;
    --result-card-four: #48605D;
    --blue: #5FB2FF;
    --solfin-green: #88F231;
    --kludstory-green: #38A979;
    --yellow: #F9A62B;
    --purple: #9F80D6;
    --raheja: #4DC1DA;
    --folio-border-one: #EDA247;
    --folio-border-two: #CF9C2C;
    --folio-border-three: #239195;
    --folio-border-four: #AA9DD2;
    --folio-border-five: #91BC9A;
    --folio-border-six: #F29A6D;
    --folio-border-seven: #B2D25A;
    --folio-border-eight: #98BDD7;
    --folio-border-nine: #9BC4DF;
    --folio-border-ten: #9DB7B4;
    --folio-border-eleven: #6D82AE;
    --folio-border-twelve: #8194A4;
    --folio-border-thirteen: #8FC0B6;
    --folio-border-fourteen: #CCC7BE;
    --folio-border-fifteen: #EB9B9B;
    --folio-border-sixteen: #676D7F;
    --folio-border-seventeen: #7ABEDC;
    --light-green: #EEF5E8; 
    --solfin-bg-one: #F9F9F9;
    --solfin-bg-two: rgba(136, 242, 48, 0.40);
    --solfin-bg-three: #88F230;
    --solfin-bg-four: #161616;
    --ozen-small-heading: #798C9B;
    --ozen-background: #F8F8F8;
    --ozen-color-one: #323F48;
    --ozen-color-two: #031D2E;
    --orange: #FF7043;
    --light-orange: #FCF2F1;
    --ntt-bg: #6D82AE;
    --orange-extra-light: #FFEDED;
    --extra-dark-orange: #AE5E5E;
    --game-store-theme: #1C2C54;
    --game-store-green: #ACFC69;
    --game-store-grey: rgba(217, 216, 214, 0.20);
    --extra-dark-blue: #08163A;
    --3i-bg: #1FA49A;
    --Comet-bg: #F4F5F6;
    --ecommerce-banner: rgba(244, 200, 121, 0.10);
    --ecommerce-color: #F9C059;
    --game-store-case-study: #C4C9D7;
    --hotel-resort-case-study: #C0D1CF;
    --ozen-case-study-bg: #A6B9C8; 
    --solfin-case-study-bg: #CEE891;
    --ecommerce-hover-bg: #F4C879;
    --mob-development-color: #6171FF;
    --purple-light-bg: rgba(179, 165, 221, 0.10);
    --mob-extra-light-purple: #F8F3FF;
    --theme-hover: #8577B0;
    --accordion-border: #EAEAEA;
    --responsive-design-bg: #F5F5F5;
    --atmosphere-shadow: rgba(59, 112, 106, 0.20);
    --solfin-shadow: #A1C583;
    --gamestore-shadow: #ADB3C2;
    --light-golden-card: #F6E8C6;
    --light-green-card: #CCE0CC;
    --extra-light-green-card: #DCECE8;
    --marketing-bg-one: #8CBAC3;
    --marketing-bg-two: #4E93A3;
    --statistics-cards-bg : #70B0BF;
    --statistics-cards-two: #2A4D6B;
    --brand-elevate-one: #E3F4E7;
    --brand-elevate-two: #FDF1CF;
    --brand-elevate-three: #E4E9F0;
    --ozen-marketing-card: #303E49;
    --ozen-shadow: rgba(121, 140, 155, 0.30);
    --border-light-grey: #DADADA;
    --kanifushi-bg: #D0BEB1;
    --kanishi-dark-bg: #B07C55;
    --showcase-extra-light-purple: #C5B9DE;
    --showcase-raaya-color: #9ED2D6;
    --showcase-3ischools-color: #A1C7CA;
    --comet-slide-one-color: #E1D0C3;
    --comet-slide-two-color: #9AC2CB;
    --comet-slide-three-color: #E3D4B7;
}

@media (max-width: 1893px) {
    .marketing-stories-slider {
        .marketing-img {
            padding: 80px 75px;
        }
        .marekting-content-wrapper {
            padding: 35px 100px;
            border: 1px solid var(--light-grey);
        }
    }
    
    .overlapping-wrapper {
        height: 510px;
    }
}

@media (max-width: 1833px) {
    .marketing-stories-slider {
        .marketing-img {
            width: 954px;
        }
        .marekting-content-wrapper {
            max-width: calc(100% - 983px);
        }
    }
}

@media (max-width: 1824px) {
    .marketing-stories-slider {
        .marekting-content-wrapper {
            padding: 35px 80px;
            border: 1px solid var(--light-grey);
        }
    }
}

@media (max-width: 1820px) {
    .benefits-wrapper {
        .benefits-heading-wrapper {
            max-width: 460px;
        }
        .benefits-content-wrapper {
            width: calc(100% - 460px);
        }
    }
    .overlapping-wrapper {
        height: 480px;
    }
}

@media (max-width: 1799px) {
    h3 {
        font-size: 30px;
    }
}

@media (max-width: 1785px) {
    .marketing-stories-slider {
        .marekting-content-wrapper {
            padding: 35px 65px;
            border: 1px solid var(--light-grey);
        }
    }
}

@media (max-width: 1774px) {
    h3 {
        font-size: 28px;
    }
    .consult-inner-wrapper {
        .consult-left-pannel {
            max-width: 810px;
            margin-right: 80px;
        }
        .consult-right-pannel {
            max-width:  calc(100% - 810px);
        }
    }

    .consult-testimonial-wrapper {
        padding: 100px 40px;
    }

    .overlapping-main-wrapper {
        .offer-heading-wrapper{
            h2 {
                padding-right: 129px;
            }
        }
        .paragraph-wrapper {
            p {
                padding-left: 140px; 
            }
        }
    }

        
    .overlapping-main-wrapper {
        .offer-heading-wrapper {
            .content-management-heading {
                font-size: 50px; 
            }
        }
    }

    .digitechtabs {
        .rts___tabs___container {
            .rts___tabs {
                .rts___tab {
                    padding: 36px 20px;
                }
            }
        }
    }

    .overlapping-wrapper {
        height: 460px;
    }
}

@media (max-width: 1755px) {
    .marketing-stories-slider {
        .marketing-img {
            width: 920px;
            padding: 95px 75px;
        }
        .marekting-content-wrapper {
            max-width: calc(100% - 944px);
        }
    }
}

@media (max-width: 1744px) { 
    .atmosphere-banner {
        .atmosophere-banner-text {
            h2 {
                padding-right: 0;
            }
        }
    }

    .overlapping-wrapper {
        &.brand-overlap-wrapper {
            height: 460px;
        }
        .page {
            .offer-deals-wrapper {
                .offer-text-wrapper {
                    p {
                        margin: 32px 0 30px;
                    }
                }
            }
        }
    }

    .benefits-wrapper {
        .benefits-heading-wrapper {
            max-width: 420px;
            .features-heading {
                h2 {
                    font-size: 54px;
                }
            }
        }
        .benefits-content-wrapper {
            width: calc(100% - 420px);
        }
    }

}

@media (max-width: 1770px) {
    .ecommerce-features-card {
        .card-body {
            .digital-text {
                min-height: 117px;
            }
        }
    }
}

@media (max-width: 1720px) {
    .atmosphere-banner-right {
        padding-left: 100px;
    }
}

@media (max-width: 1716px) {
    .marketing-stories-slider {
        .marketing-img {
            width: 880px;
            padding: 107px 75px;
        }
        .marekting-content-wrapper {
            max-width: calc(100% - 904px);
        }
    }

    .overlapping-wrapper {
        height: 430px;
    }
}

@media (max-width: 1735px) {
    .overlapping-wrapper {
        height: 440px;
        .page {
            &.overlap-box-third {
                .offer-deals-wrapper {
                    padding-top: 60px;
                }
            }
        }
    }
}

@media (max-width: 1694px) {
    .overlapping-wrapper {
        height: 420px;
    }
}

@media (max-width: 1676px) {
    .marketing-stories-slider {
        .marketing-img {
            padding: 107px 70px;
        }
        .marekting-content-wrapper {
            padding: 35px 55px;
        }
    }

    .overlapping-wrapper {
        &.brand-overlap-wrapper {
            height: 430px;
        }
    }

}

@media (max-width: 1669px) {
    h3 {
        font-size: 26px;
    }
    .custom-container-fluid {
        padding: 0 85px;
    }

    header {
        .menu-image {
            margin-left: 85px;
        }
    }

    .header-space {
        padding-top: 170px !important;
    }

    h2{
         font-size: 55px;
    }
    .consult-inner-wrapper {
        padding-left: 50px;
        .consult-left-pannel {
            max-width: 770px;
            margin-right: 44px;
        }
        .consult-right-pannel {
            max-width:  calc(100% - 770px);
        }
    }

    .statistics-para {
        padding-right: 40px;
    }

    
    .service-card-wrapper {
        .service-right {
            padding-left: 68px;
        }
    }

    .get-done-cards {
        padding: 40px;
    }

    .digitech-services-wrapper {
        .digitech-card {
            padding: 60px 30px;
        }
    }

    header {
        .digitech-nav-wrapper {
            padding-left: 0px;
        }
    }

    .custom-dropdown {
        .dropdown-menu {
            width: 715px; 
        }
    }

    .digitechtabs {
        .rts___tabs___container {
            .rts___tabs {
                .rts___tab {
                    padding: 26px 20px;
                    &.active {
                        padding-bottom: 70px;
                    }
                }
            }
        }
    }

    .pricing-links-wrapper {
        .header-pricing-link {
            .header-pricing-btn {
                &:last-child {
                    margin-top: 20px;
                    margin-left: 0 !important;
                }
                &.header-digital-pricing {
                    margin-top: 0;
                    margin-left: 1.5rem !important;
                }
            }
        }
    }
}


@media (max-width: 1628px) {

    .consult-inner-wrapper {
        .consult-left-pannel {
            max-width: 740px;
            margin-right: 60px;
        }
        .consult-right-pannel {
            max-width:  calc(100% - 740px);
        }
    }

    .showcase-slider {
        .showcase-inner-wrapper {
            .showcase-left-text {
                h5 {
                    width: 200px;
                }
            }
        }
    }
}

@media (max-width: 1601px) {
    .marketing-stories-slider {
        .marketing-img {
            width: 840px;
            padding: 107px 56px;
        }
        .marekting-content-wrapper {
            max-width: calc(100% - 864px);
        }
    }
}

@media (max-width: 1578px) {
    .case-study-slider {
        .case-study-text {
            .case-study-heading {
                font-size: 30px;
            }
        }
    }
    
    .atmosphere-banner {
        .atmosophere-banner-text {
            h2 {
                font-size: 46px;
            }
        }
    }

    .overlapping-wrapper {
        height: 410px;
        .page {
            &.overlap-box-second,
            &.overlap-box-third {
                transform: translateY(470px);
            }
            .offer-deals-wrapper {
                padding-left: 114px;
                .offer-text-wrapper {
                    padding-bottom: 80px;
                }
            }
        }
    }
}

@media (max-width: 1557px) { 
    .ecommerce-features-card {
        &.digital-responsive-feature {
            padding: 30px 25px;
            .card-body {
                .digital-text {
                    min-height: 130px;
                }
            }
       } 
    }
   
}

@media (max-width: 1561px) {
    .marketing-stories-slider {
        .marketing-img {
            width: 800px;
            padding: 107px 35px;
        }
        .marekting-content-wrapper {
            max-width: calc(100% - 824px);
        }
    }
}

@media (max-width :1542px) {
    .overlapping-wrapper {
        .page {
            .offer-deals-wrapper {
                .offer-text-wrapper {
                    width: 600px;
                }
                .offer-Img-wrapper {
                    max-width: calc(100% - 600px);
                }
            }
        }
    }
}

@media (max-width: 1533px) {
    .consult-inner-wrapper {
        padding-left: 30px;
        padding-right: 30px;
    }

    .showcase-slider {
        .showcase-inner-wrapper {
            .showcase-right-img {
                img {
                    width: 100%;
                }
            }
        }
    }

    .service-card-wrapper {
        .service-right {
            padding-left: 40px;
            .col-lg-6 {
                &:nth-child(3) {
                    margin-top: 100px;
                }
                &:nth-child(4) {
                    margin-top: 100px;
                }
            }
        }
    }

    .service-card-space {
        padding-right: 20px;
    }
}

@media (max-width: 1521px) {
    .marketing-stories-slider {
        .marketing-img {
            width: 750px;
            padding: 123px 35px;
        }
        .marekting-content-wrapper {
            max-width: calc(100% - 774px);
        }
    }
}

@media (max-width: 1520px) {
    .digitech-card .btn-wrapper {
        margin-top: 40px;
    }
    .benefits-wrapper {
        .benefits-heading-wrapper {
            max-width: 360px;
            .features-heading{
                h2 {
                    font-size: 42px;
                }
            }
        }
        .benefits-content-wrapper {
            width: calc(100% - 360px);
        }
    }

    .overlapping-wrapper {  
        &.brand-overlap-wrapper {
            height: 430px;
        }
        .page {
            .offer-deals-wrapper {
                .offer-text-wrapper {
                    p {
                        margin: 22px 0;
                    }
                }
            }
        }
    }
    
}

@media (max-width: 1485px) {
    .overlapping-main-wrapper {
        .paragraph-wrapper {
            p {
                padding-left: 30px; 
            }
        }
        .offer-heading-wrapper {
            .content-management-heading {
                font-size: 42px; 
            }
        }
    }
}

@media (max-width: 1480px) {
    .features-img {
        height: 721px;
    }

    .ecommerce-features-card {
        padding: 60px 35px;
    }
}

@media (max-width: 1472px) {
    .marketing-stories-slider {
        .marekting-content-wrapper {
            padding: 35px 30px;
        }
    }
}

@media (max-width: 1456px) {
    .benefits-wrapper {
        .benefits-heading-wrapper {
            max-width: 320px;
            .features-heading{
                h2 {
                    font-size: 42px;
                }
            }
        }
        .benefits-content-wrapper {
            width: calc(100% - 320px);
        }
    }
}

@media (max-width: 1450px){
    
    h3 {
        font-size: 24px;
    }
    .atmosphere-banner {
        &::before {
            top: 30px;
            background-position: 60px;
        }
    }  
    .case-study-slider {
        .case-study-text {
            .case-study-heading {
                font-size: 24px;
            }
        }
    }
    .consult-inner-wrapper {
        .consult-left-pannel {
            max-width: 714px;
            margin-right: 45px;
        }
        .consult-right-pannel {
            max-width:  calc(100% - 714px);
        }
    }

    .mobile-sapce {
        margin-left: 50px;
    }

    .service-card-wrapper {
        .service-left {
            width: 593px;
        }
        .service-right {
            padding-left: 48px;
            max-width: calc(100% - 593px);
        }
    }

    .service-banner-heading {
        font-size: 42px;
    }

    
    .atmosphere-banner {
        .atmosophere-banner-text {
            h2 {
                font-size: 42px;
            }
        }
    }

 
    

    .consult-inner-text {
        .row {
            .col-lg-4 {
                width: 50%;
            }
        }
    }

    .overlapping-main-wrapper {
        .offer-heading-wrapper {
            h2 {
                font-size: 50px;
                padding-right: 79px;
            }
        }
    }

    .overlapping-main-wrapper {
        .paragraph-wrapper {
            p {
                padding-left: 0;
            }
        }
    }
}

@media (max-width: 1435px) {
    .overlapping-main-wrapper {
        .comet-responsive-column {
            .col-lg-6 {
                &:first-child {
                    width: 45%;
                }
                &:nth-child(2) {
                    width: 55%;
                }
            }
        }
        .offer-heading-wrapper {
            h2 {
                padding-right: 70px; 
            }
        }
    }

    .overlapping-wrapper {
        &.brand-overlap-wrapper {
            height: 390px;
        }
    }
}

@media (max-width: 1421px) {
    .features-img {
        height: 811px;
    }

    .marketing-stories-slider {
        .marekting-content-wrapper {
            .keywords-ranking-wrapper {
                .keyword-title {
                    margin-top: 30px;
                }
            }
        }
    }

    .marketing-stories-slider {
        .marketing-img {
            width: 700px;
        }
        .marekting-content-wrapper {
            max-width: calc(100% - 724px);
        }
    }

    
    .overlapping-main-wrapper {
        .offer-heading-wrapper {
            .content-management-heading {
                font-size: 38px; 
            }
        }
    }
}

@media (max-width: 1400px) {
    .primary-color-usage {
        ul {
            li {
                width: 31%;
            }
        }
    }

    .folio-logo-wrapper{
        &.ahr-logo-wrapper {
            ul {
                li {
                    padding: 0 70px;
                }
            }
        }
    }

    .typography-wrapper {
        .typography-inner-wrapper {
            padding: 40px;
        }
    }
}

@media (max-width: 1399px){
    h1 {
        font-size: 70px;
    }
    h2 {
        font-size: 50px;
    }
    .custom-container-fluid {
        padding: 0px 55px;
    }

    header {
        .menu-image {
            margin-left: 55px;
            img {
                width: 170px;
            }
        }
    }

    .digitech-hero-banner {
        .banner-paragrapgh {
            font-size: 22px;
            padding: 0 50px;
        }
    }

    .service-about-wrapper {
        padding-top: 90px !important; 
    }

    .get-done-cards{
        .card-body {
            .card-title {
                font-size: 22px  !important;
            }
        }
    }

    .brand-benefits-wrapper {
        padding-top: 90px;
    }

    .comet-features-card {
        .card-img-wrapper {
            height: 210px;
        }
    }


    .showcase-slider-wrapper {
        &::before {
            height: 65%;
        }
    }

    .service-card-wrapper {
        .service-left {
            width: 540px;
        }
        .service-right {
            max-width: calc(100% - 540px);
        }
    }

    .about-left-text .atmosphere-inner-heading {
        padding-right: 20px;
    }

    .result-wrapper {
        padding-right: 87px;
    }


    .difference-design {
        height: 608px !important;
        .ResizePanel-module_ResizeContentHorizontal__1gGbA {
            max-width: 1075px;
        }

        .ResizePanel-module_ResizeBarHorizontal__3TBZ5 {
            height: 580px;
        }
    }

    .company-service-wrapper  {
        &.service-space {
            padding-top: 20px;
        }
    }   

    .result-wrapper {
        padding-right: 154px;
    }
  
    .case-study-slider {
        .case-study-text {
            .case-study-heading {
                font-size: 21px;
            }
        }
    }

    .work-slide {
        .slick-prev {
            margin-left: 179px;
        }
        .slick-next {
            margin-right: 245px;
        }
    }

    .consult-inner-wrapper {
        .consult-left-pannel {
            max-width: 690px;
            margin-right: 0;
        }
        .consult-right-pannel {
            max-width:  calc(100% - 690px);
        }
    }

    .consult-testimonial-wrapper {
        padding: 60px 40px;
    }

    .showcase-slider {
        .showcase-inner-wrapper {
            .showcase-right-img {
                padding: 0 170px;
                img {
                    width: 100%;
                }
            }
        }
    }


    .service-card-wrapper {
        .service-right {
            .col-lg-6{
                &:nth-child(3) {
                    margin-top: 60px;
                }
                &:nth-child(4) {
                    margin-top: 60px;
                }
            }
        }
    }


    .service-column {
        .col-lg-4{
            &:nth-child(4) {
                margin-top: 90px;
            }
            &:nth-child(5) {
                margin-top: 90px;
            }
            &:nth-child(6) {
                margin-top: 90px;
            }
        }
    }

    .service-features {
        margin-bottom: 100px;
    }

    .statistics-card-wrapper {
        padding: 23px 20px;
        width: 219px;
        .card-body {
            .card-title {
                font-size: 24px !important;
            }
        }
    }

    .ecommerce-features-card {
        padding: 45px 20px;
        .card-title {
            font-size: 18px;
        }
    }


    .get-done-cards {
        padding: 25px;
    }


    .small-heading {
        font-size: 40px;
    }

    .Service-cards-innre {
        .card-inner-data {
            h4 {
                font-size: 20px;
            }
        }
    }

    .responsive-top-space {
        padding-top: 60px !important;
    }

    .digitech-features-card {
        .card-img-wrapper {
            padding: 50px 30px;
            height: 288px;
        }
    }


    .uiux-process-boxes {
        .process-inner-boxes {
            max-width: 598px;
            &:first-child {
                padding-right: 50px;
            }
            &:nth-child(2){
                padding-left: 50px;
            }
            &:nth-child(3) {
                padding-right: 50px;
            }
            &:nth-child(4){
                padding-left: 50px;
            }
        }
    }

    .digitech-services-wrapper {
        .digitech-card {
            padding: 60px 20px; 
        }
    }

    .features-img {
        height: 635px;
    }

    .uiux-process-boxes {
        .process-item-wrapper {
            width: 206px;
            &.active {
                padding: 50px 27px;
            }
        }
    }
        
    .about-left-text {
        .atmosphere-inner-heading {
            font-size: 34px;
        }
    }

    .enterprise-application-wrapper {
        .enterprise-application-left {
            width: 777px;
        }
        .enterprise-application-right {
            width: calc(100% - 777px);
        }
    }

    .service-features {
        margin-bottom: 50px;
    }
    
    .mob-card-wrapper {
        .mob-content-wrapper {
            h5 {
                font-size: 18px;
            }
            p {
                padding-right: 30px;
            }
            &.mob-inner-content {
                p {
                    padding-left: 0;
                }
            }
        }   
    }

    .overlapping-main-wrapper {
        .offer-heading-wrapper {
            h2 {
                padding-right: 70px;
            }
        }
        .paragraph-wrapper {
            p {
                padding-left: 0; 
            }
        }
    }

    .overlapping-wrapper {
        .page {
            .offer-deals-wrapper {
                padding-left: 74px;
                .offer-text-wrapper {
                    width: 530px;
                    h2 {
                        font-size: 30px;
                    } 
                    .offer-btn {
                        padding: 11px 25px;
                    }
                }
                .offer-Img-wrapper {
                    max-width: calc(100% - 530px);
                }
            }
        }
    }
    .custom-dropdown {
        .dropdown-menu { 
            width: 652px;
            margin-left: -90px !important;
        }
    }

    .uiux-process-boxes {
        .process-item-wrapper {
            .process-content {
                h4 {
                    &.process-second-title {
                        font-size: 20px;
                    }
                }
            }
        }
    }

    .digitechtabs {
        .rts___tabs___container {
            max-width: 450px;
            .rts___tabs {
                .rts___tab {
                    padding: 23px 20px;
                    &.active {
                        padding-bottom: 60px;
                    }
                }
            }
        }
    } 


    .atmosphere-banner-left {
        padding-right: 127px;
    }

    .atmosphere-banner-right {
        padding-left: 50px;
    }

 

    .typography-wrapper {
        &.iconography-wrapper {
            .typography-inner-wrapper { 
                .row {
                    .col-lg-4 {
                        &:nth-child(1) {
                            padding-right: 25px;
                        }
                        &:nth-child(2) {
                            padding: 0 25px;
                        }
                        &:nth-child(3) {
                            padding-left: 25px;
                        }
                    }
                }
            }
        }
        .typography-inner-wrapper {
            &.ahr-typography-wrapper {
                .typography-family-info {
                    .alphabets-wrapper {
                        padding-right: 60px;
                    }
                }
            }
        }
    }

    .website-tabs-wrapper {
        .rts___tabs___container {
            padding: 0 10px;
            .rts___nav___btn___container {
                display: flex !important;
                .rts___btn {
                    border: 0 !important;
                    &:hover {
                        background-color: transparent;
                    }
                }
            }
        }
        .tab-image-content {
            &.ucms-tab-image {
                padding: 40px !important;
            }
        }
    }

    .folio-tabs-navigation {
        display: flex;
        align-items: center;
        .folio-tab-back {
            border: 0;
            padding: 0;
            display: flex;
            background-color: transparent;
        }
    }
    
    .healthism-card {
        margin-top: -228px;
    }
}

@media (max-width: 1356px) {
    .showcase-slider-wrapper{
        &::before {
            max-height: 65%;
        }
    }
}

@media (max-width: 1386px) { 
    .benefits-wrapper {
        display: block !important;
        .benefits-heading-wrapper {
            max-width: 100%;
        }
        .benefits-content-wrapper  {
            width: 100%;
        }
    }
    .ecommerce-features-card{
        &.digital-responsive-feature {
            .card-body {
                .digital-text {
                    min-height: 90px;
                }
            }
        }
    }  
}

@media (max-width: 1341px) {
    .marketing-stories-slider {
        .marekting-content-wrapper {
            .keywords-cards-wrapper {
                .statistics-card-wrapper {
                    padding: 22px;
                    .card-text {
                        margin-top: 15px !important;
                    }
                }
            }
        }
        .marketing-large-title {
            font-size: 22px;
        }
    }

    .marketing-stories-slider {
        .marketing-img {
            width: 660px;
        }
        .marekting-content-wrapper {
            max-width: calc(100% - 664px);
        }
    }
}

@media (max-width: 1309px) {
    .overlapping-wrapper {
        height: 405px;
        .page {
            .offer-deals-wrapper {
                .offer-Img-wrapper {
                    padding-left: 40px;
                }
            }
        }
    }
}

@media (max-width: 1300px) {
    .features-img {
        height: 715px;
    }
    .overlapping-main-wrapper {
        .offer-heading-wrapper {
            h2 {
                font-size: 45px;
            }
        }
    }

    .overlapping-wrapper {
        height: 405px;
        .page {
            .offer-deals-wrapper {
                .offer-text-wrapper {
                    padding-bottom: 140px;
                }
            }
        }
    }
}



@media (max-width: 1286px){
    .service-card-wrapper {
        .service-left {
            width: 530px;
        }
        .service-right {
            max-width: calc(100% - 530px);
        }
    }

    .right-side-image {
        padding-left: 30px;
        padding-right: 0;
    }

    .digitech-consult-wrapper {
        .consult-form {
            h2 {
                font-size: 40px;
            }
        }
    }

    .overlapping-main-wrapper {
        .offer-heading-wrapper {
            .content-management-heading {
                font-size: 32px; 
            }
        }
    }
}

@media (max-width: 1266px) {
    .overlapping-wrapper {
        .page {
            .offer-deals-wrapper {
                padding-left: 54px;
                .offer-Img-wrapper {
                    padding-left: 20px;
                }
            }
        }
    }
}

@media (max-width: 1234px) {
    
    .atmosphere-banner {
        .atmosophere-banner-text {
            h2 {
                font-size: 38px;
            }
            .atmosphere-small-heading {
                font-size: 22px;
            }
        }
    }  

    .ecommerce-features-card {
        .card-body {
            margin-left: 17px;
        }
    }

    .features-img {
        img {
            width: 100%;
        }
    }

    .comet-features-card {
        padding: 64px 40px;
    }

    .overlapping-main-wrapper {
        .offer-heading-wrapper {
            h2 {
                font-size: 42px;
            }
        }
    }
}


@media (max-width: 1260px){
    h3 {
        font-size: 22px;
    }
}


@media (max-width: 1220px) {
    .overlapping-wrapper {
        .page {
            &.overlap-box-third {
                .offer-deals-wrapper {
                    padding-top: 60px;
                }
            }
        }
    }
}

@media (max-width: 1199px){
    h1 {
        font-size: 67px;
        font-weight: 700;
    }
    h2 {
        font-size: 55px;
    }

    .custom-container-fluid {
        padding: 0px 50px;
    }

    header {
        .menu-image {
            margin-left: 50px;
            img {
                width: 170px;
            }
        }
    }

    .header-space {
        padding-top: 180px !important;
    }

    .digitech-cards-wrapper {
        .digitech-services-card {
            padding: 40px 12px;
        }
    }

    .digitech-services-wrapper {
        padding: 80px 0 40px; 
        .digitech-card {
            margin-bottom: 25px;
        }
    }

    .atmosphere-banner {
        .atmosophere-banner-text {
            h2 {
                font-size: 30px;
            }
        }
    }  

    .case-study-slider {
        .case-study-text {
            .case-study-heading {
                font-size: 30px;
            }
        }
    }

    .result-wrapper {
        padding-right: 130px;
    }

    .difference-design {
        .ResizePanel-module_ResizeBarHorizontal__3TBZ5 {
            height: 546px;
            margin-top: -40px;
          }
    }

    .consult-inner-wrapper {
        display: block;
        .consult-left-pannel {
            max-width: 100%;
        }
        .consult-right-pannel {
            margin-top: 40px;
            max-width: 100%;
        }
    }
    .service-banner-heading {
        font-size: 42px;
    }

 

    .service-card-wrapper {
        .service-left {
            width: 419px;
        }
        .service-right {
            max-width: calc(100% - 419px);
        }
    }

    .ecommerce-features-card {
        display: block;
        padding: 20px;
        .card-img-wrapper {
            margin: 0 auto 20px;
        }
        .card-body {
            width: 100%;
            text-align: center;
            margin-left: 0;
        }
    }

    

    .ecommerce-showcase-slider {
        .slick-slider {
            .slick-next {
                right: 0;
                margin-right: -15px;
                &::before {
                    width: 30px;
                    height: 30px;
                    background-size: 13px;
                }
            }
            .slick-prev {
                left: 0;
                margin-left: -45px;
                &::before {
                    width: 30px;
                    height: 30px;
                    background-size: 13px;
                }
            }
        }
    }

    .digitech-technology-card {
        padding: 20px;
    }

    .ecommerce-main-banner {
        padding-bottom: 40px; 
    }

    .mob-card-wrapper {
        .mob-content-wrapper {
            margin-left: 15px;
            p {
                padding-right: 0;
            }
            &.mob-inner-content {
                p {
                    padding-left: 0;
                }
            }
        }
    }
    .digitechtabs {
        display: block !important;
        
        &.casestudy-desktop-tabs {
            display: none !important;
        }
        .rts___tabs___container {
            width: 100%;
            max-width: 100%;
        }
    }

    .right-side-image {
        padding-left: 0;
        padding-right: 0;
    }

    .responsive-design-inner {
        .row {
            display: block !important;
            flex-direction: row-reverse;
            .col-lg-6 {
                width: 100%;
            }
        }
    }

    .responsive-img{
        margin-bottom: 60px;
    }

    .digitech-nav-wrapper {
        .navbar {
            .navbar-collapse {
                .navbar-nav {
                    .nav-item {
                        .nav-link {
                            padding: 0 18px;
                        }
                    }
                }
            }
        }
    }

    .showcase-slider-wrapper {
        &::before {
            max-height: 60%;
        }
    }

    .showcase-slider {
        .showcase-inner-wrapper {
            .showcase-right-img {
                padding: 0 190px;
            }  
        }
    }
    

    .uiux-process-boxes {
        .process-inner-boxes {
            max-width: 508px;
            &:first-child {
                padding-right: 0;
            }
            &:nth-child(2){
                padding-left: 0;
            }
            &:nth-child(3) {
                padding-right: 0;
            }
            &:nth-child(4){
                padding-left: 0;
            }
        }
    }

    .atmosphere-design-wrapper {
        padding: 50px;
        display: block;
        .atmosphere-left-pannel {
            width: 100%;
        }
        .atmosphere-right-pannel {
            width: 100%;
            margin-top: 50px;
            margin-left: 0;
        }
    }

    .mobile-sapce {
        margin-left: 0;
    }

    .atmosphere-image-wrapper {
        margin-top: 50px;
    }

    .copywritting-para {
        p {
        padding-right: 0; }
    }

    .case-study-slider{
        .slick-prev, .slick-next {
            top: 30%;
        }
    } 

    .features-img {
        height: auto;
        img {
            object-fit: contain;
        }
    }

    .uiux-process-boxes {
        .process-item-wrapper {
            width: 170px;
            height: 590px;
            &.active {
                padding: 50px 27px;
            }
            
            .process-img {
                width: 120px;
                height: 120px;
                img {
                    width: 60px;
                }
            }
            .process-content {
                h4{ 
                    font-size: 22px;
                }
            }
                   
        }
    }
    .about-left-text {
        padding-right: 0;
        .atmosphere-inner-heading {
            font-size: 30px;
        }
    }

    .enterprise-application-wrapper {
        align-items: center;
        .enterprise-application-left {
            width: 647px;
        }
        .enterprise-application-right {
            width: calc(100% - 647px);
        }
    }

    .service-features {
        margin-bottom: 50px;
    }

    .mob-card-wrapper {
        margin-bottom: 50px;
    }

    .service-banner-heading {
        font-size: 36px;
    }

    .mob-card-wrapper {
        .mob-card-icon {
            width: 60px;
            height: 60px;
            img {
                width: 30px;
            }
        }
        .mob-content-wrapper {
            margin-left: 15px;
            width: calc(100% - 69px);
            h5 {
                font-size: 16px;
            }
            &.mob-inner-content {
                margin-right: 15px;
            }
        }
    }
    
    .comet-features-card {
        padding: 64px 23px;
        .card-img-wrapper {
            height: 195px;
        }
    }

    .overlapping-main-wrapper {
        .offer-heading-wrapper {
            h2 {
                padding-right: 0;
            }
        }
    }

    .overlapping-wrapper {
        .page {
            .offer-deals-wrapper {
                .offer-text-wrapper {
                    h2 { 
                        font-size: 32px;
                    }
                }
            }
        }
    }

    .menu {
        .digitech-nav {
            .nav-list-wrapper {
                li {
                    .custom-dropdown {
                        .dropdown-menu {
                            .dropdown-content-wrapper {
                                .dropdown-list-wrapper {
                                    li {
                                        width: 39%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .uiux-process-boxes {
        .process-item-wrapper {
            .process-content {
                h4 {
                    &.process-second-title {
                        font-size: 16px;
                    }
                }
            }
            &.active {
                h4 {
                    &.process-second-title {
                        font-size: 26px;
                    }
                }
            }
        }
    }

    .marketing-slide-wrapper  {
        display: block !important;
    }

    .marketing-stories-slider {
        .marketing-img {
            width: 100%;
            padding: 65px 35px;
            margin-bottom: 50px;
        }
        .marekting-content-wrapper {
            max-width: 100%;
        }
    }

    .responsive-digitech-tab {
        display: block;
        margin-top: 50px;
        &.responsive-gamestore-wrapper {
            .rts___btn {
                &.rts___tab___selected {
                    color: var(--game-store-theme);
                    background-color: var(--game-store-green) !important;
                }
            }
        }
        &.ozen-responsive-tab {
            .rts___btn {
                &.rts___tab___selected {
                    background-color: var(--ozen-small-heading) !important;
                }
            }
        }
        &.solfin-responsive-tab {
            .rts___btn {
                &.rts___tab___selected {
                    color: var(--solfin-bg-four);
                    background-color: var(--solfin-bg-three) !important;
                }
            }
        }
        &.uiux-responsive-tab {
            .rts___btn {
                &.rts___tab___selected {
                    background-color: var(--secondary-color) !important;
                }
            }
        }
        .atmosphere-image-wrapper {
            margin-top: 40px;
            padding-top: 0;
            padding-bottom: 0;
            margin-bottom: 30px;
        }
        .rts___nav___btn___container {
            display: none;
        }
        .rts___btn {
            padding: 40px;
            font-size: 30px;
            margin: 0 5px;
            border-radius: 0;
            font-weight: 600;
            border: 1px solid var(--accordion-border) !important;
            &.rts___tab___selected {
                background-color: var(--case-study-color);
            }
            .tab-name {
                font-size: 30px;
                font-weight: 600;
                font-family: var(--theme-font);
                margin-left: 10px;
            }
        }
    }

    .process-accordion-wrapper {
        .accordion {
            .accordion-item {
                .accordion-header {
                    .accordion-button {
                        padding: 27px 50px;
                    }
                }
                .accordion-collapse {
                    .accordion-body {
                        padding: 27px 50px 40px;
                    }
                }
            }
        }
    }

    .ecommerce-showcase-slider {
        .slick-slider {
            .slick-prev {
                top: 35%;
            }
            .slick-next {
                top: 35%;
            }
        }
    } 

    .responsive-cards-wrapper {
        padding-left: 0;
    }
    
    .about-service-wrapper {
        padding: 80px 0 40px;
    }

    .responsive-design-wrapper {
        padding: 40px 0;
    }

    .faqs-wrapper {
        padding-bottom: 40px;
    }

    .consult-main-wrapper {
        padding: 40px 0;
    }

    .company-service-wrapper {
        padding-bottom: 80px;
    }

    .faqs-consult-wrapper {
        padding-bottom: 80px !important;
    }

    .uiux-design-wrapper {
        padding-bottom: 40px;
    }

    .marketing-success-wrapper {
        padding-bottom: 40px;
    }

    .marketing-success-wrapper {
        padding-bottom: 30px;
    }

    .looking-packages-wrapper {
        margin-top: 80px;
    }

    .looking-packages-wrapper {
        margin-top: 60px;
    }

    .overlapping-main-wrapper {
        padding-bottom: 40px;
    }

    .comet-features-wrapper {
        padding: 40px 0 40px;
    }

    .about-atmosphere {
        padding: 80px 0 40px;
    }

    .digitech-solution {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .casestudy-result-space {
        padding-bottom: 80px !important;
    }

    .casestudy-result-space {
        padding-bottom: 60px !important;
    }

    .games-stores-bottom-space {
        padding-bottom: 90px !important;
        .slick-slider {
            .slick-dots {
                bottom: -45px;
            }
        }
    }

    .digitech-consult-wrapper {
        padding-bottom: 80px; 
    }

    .atmosphere-case-study {
        padding-top: 40px;
    }

    .software-technologies-card {
        .card-body {
            .card-text {
                min-height: 120px;
            }
        }
    }

    .consult-right-pannel {
        .back-home-btn {
            text-align: left !important;
        }
    }

    .pricing-wrapper {
        .container {
            width: 100%;
        }
    }

    .primary-color-usage {
        ul {
            li {
                padding: 30px 16px;
                .color-wrapper {
                    p {
                        line-height: 22px;
                    }
                }
            }
        }
    }

    .atmosphere-website-folio {
        padding-bottom: 40px;
    }

    .atmosphere-mobile-folio {
        padding: 40px 0;
    }

    .healthism-btn-wrapper {
        padding: 100px 0;
        margin-top: 80px;
        span {
            margin-bottom: 25px;
        }
    }

    .atmosphere-brochure-folio {
        padding: 40px 0 0;
    }

    .ahr-color-wrapper {
        ul {
            li {
                height: 100%;
            }
        }
    }

    .atmosphere-banner-right {
        padding-left: 0;
    }

    .atmosphere-banner-left {
        padding-right: 50px;
    }

    .compare-wrapper {
        .payment-wrapper {
            padding: 48px 30px;
            .list-group-item {
                padding: 0 18px 0 22px;
                &:nth-child(1) {
                    padding-left: 0;
                    padding-right: 27px;
                }
                &:nth-child(3) {
                    padding-right: 0;
                }

                &:nth-child(4) {
                    padding-left: 0;
                    padding-right: 27px;
                }
            }
        }
    }

    .pricing-info-wrapper {
        .list-group {
            .list-group-item {
                .package-info {
                    padding: 90px 43px 43px;
                }
                &:nth-child(2) {
                    .package-info {
                        padding: 54px 43px 43px;
                    }
                }
            }
        }
    }

    .marketing-stories-slider {
        .slick-slider {
            .slick-prev, .slick-next {
                top: 23%;
                &::before {
                    background-size: 25px;
                }
            }
        }
    }

    .folio-logo-wrapper {
        ul {
            li {
                padding: 0 74px; 
            }
        }
    }

    .atmosphere-website-folio {
        .tab-image-contant {
            .upper-frame {
                width: 820px;
            }
            .right-side-image {
                width: 820px;
            }
        }
    }

    .typography-wrapper {
        .typography-inner-wrapper {
            padding: 80px 50px;
            .typography-family-info {
                .alphabets-wrapper {
                    padding-right: 60px;
                }
            }
            .typography-font-info {
                .typography-font {
                    h1 {
                        margin-left: 80px;
                    }
                    h2 {
                        margin-left: 80px;
                    }
                    h3 {
                        margin-left: 80px;
                    }
                    h4 {
                        margin-left: 80px;
                    }
                    h5 {
                        margin-left: 80px;
                    }
                    h6 {
                        margin-left: 80px;
                    }
                }
            }
        }
    }

    .typography-wrapper{
        &.iconography-wrapper {
            margin-top: 80px;
            .typography-inner-wrapper {
                padding: 60px;
                .row {
                    .col-lg-4 {
                        &:nth-child(1) {
                            padding-right: 15px;
                        }
                        &:nth-child(2) {
                            padding: 0 15px;
                        }
                        &:nth-child(3) {
                            padding-left: 15px;
                        }
                    }
                }
            }
        }
    }

    .website-tabs-wrapper {
        .ucms-tab-image {
            .hide-scroll-animation {
                height: 100% !important;
            }
        }

        .tab-image-content {
            .upper-frame {
                width: 80%;
                height: 100% !important;
            }
            .right-side-image {
                width: 80%;
            }
        }
    }
    .folio-logo-wrapper{
        &.ahr-logo-wrapper {
            ul {
                li {
                    &:nth-child(3) {
                        border-right: 0;
                    }
                }
            }
        }
    }

    .healthism-card {
        margin-top: -190px;
    }
}

@media (max-width: 1187px) {
    .overlapping-wrapper {
        height: 732px !important;
        &.brand-overlap-wrapper {
            height: 684px !important;
        }
        .page {
            &.overlap-box-second,
            &.overlap-box-third {
                transform: translateY(690px);
            }
            .offer-deals-wrapper {
                padding: 90px 60px;
                display: block !important;
                .offer-text-wrapper {
                    width: 100%;
                    padding-bottom: 0;
                    .next-desktop-btn {
                        display: none;
                    }
                }
                .offer-Img-wrapper {
                    max-width: 100%;
                    padding: 0;
                    img {
                        width: 60%;
                    }
                    &.brand-img-wrapper {
                        padding: 0;
                    }
                }
            }
            
            .next-slide-btn {
                display: flex;
                margin-top: 30px;
                .offer-btn {
                    border-radius: 0;
                    padding: 11px 25px;
                    color: var(--bs-white);
                    background-color: var(--primary-color);
                }
            }
            
        }
    }
}

@media (max-width: 1100px) {
    .showcase-slider {
        .showcase-inner-wrapper {
            .showcase-left-text {
                margin-bottom: 70px;
            }
        }
    }
}

@media (max-width: 1094px) {
    .menu {
        .digitech-nav {
            .nav-list-wrapper {
                li {
                    .custom-dropdown {
                        .dropdown-menu {
                            width: 730px;
                        }
                    }
                }
            }
        }
    }

    .company-service-wrapper {
        .company-service-box {
            .row {
                .col-lg-4 {
                    width: 49% !important;
                }
            }
        }
    }  

    .overlapping-main-wrapper {
        .comet-responsive-column {
            .col-lg-6 {
                &:first-child {
                    width: 50%;
                }
                &:nth-child(2) {
                    width: 100%;
                    margin-top: 30px;
                }
            }
        }
    }

    header .menu-image {
        margin-top: 30px;
        img {
            width: 150px;
        }
    }

    .overlapping-wrapper {
        height: 714px !important;
        &.brand-overlap-wrapper {
            height: 654px !important;
        }
    }
}

@media (max-width: 1150px) {
    .custom-dropdown {
        .dropdown-menu { 
            width: 652px;
            margin-left: -90px !important;
        }
    }
}

@media (max-width: 992px){

    h1 {
        font-size: 60px;
        font-weight: 700;
    }

    h2 {
        font-size: 43px;
    }

    .digitech-hero-banner {
        .banner-paragrapgh {
            font-size: 20px;
        }
    }

    .digitech-nav-wrapper {
        display: none !important;
    }
    
    header {
        .digitech-responsive-nav {
            justify-content: end !important;
        }
        .navbar-toggler{
            border: 1px solid var(--primary-color);
            width: 40px;
            height: 35px;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 30px;
            border-radius: 5px;
            .navbar-toggler-icon {
                background-image: url(../images/menu.svg);
            }
            &:focus{
                box-shadow: none;
                outline: 0;
            }
        }

        .navbar-collapse{
            display: none;
        }

        
        #digitech-nav {
            .nav-item  {
                width: 100%;
                .nav-link {
                    padding: 13px 20px;
                    transition: all 0.3s ease-in;
                    &:hover, &:focus {
                        color: var(--bs-white);
                        background-color: var(--secondary-color);
                    }
                }
            }
        }
    }

    .consult-inner-wrapper {
        padding-left: 0;
        padding-right: 0;
    }

    .custom-dropdown {
        .dropdown-menu {
            width: 664px;
            padding: 25px 19px;
            overflow-y: auto;
            margin-top: 10px;
            margin-left: 70px !important;
        }
    }
    .digitech-consult-wrapper {
        .col-md-12 {
            padding-left: 15px !important;
        }
        .form-check {
            padding-left: 1.5rem !important;
        }
        .consult-form {
            padding-right: 0;
        }
    }

    .digitech-services-wrapper {
        .digitech-card {
            .btn-wrapper {
                opacity: 1;
                visibility: visible;
                margin-top: 30px;
                .learn-more-btn {
                    color: var(--primary-color);
                }
            }
            &:hover {
                .btn-wrapper {
                    .learn-more-btn {
                        color: var(--bs-white);
                    } 
                }  
            }
        }
    }

    .consult-testimonial-wrapper {
        margin-top: 30px;
    }

    footer {
        .social-links {
            margin-top: 30px;
        }
        .social-media-wrapper {
            margin-bottom: 30px !important;
            h5 {
                margin-top: 45px; 
            }
        }
    }

    .atmosphere-banner {
        .banner-img {
            margin-top: 50px;
            &.solfin-wrapper {
                &::before {
                    top: -20px;
                }
            }
            &.ozen-wrapper {
                &::before {
                    top: -20px;
                }
            }
            &.game-wrapper {
                &::before {
                    top: -20px;
                }
            }
        }

        .solfin-bg {    
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                width: 80% !important;
            }
        }

        .atmosophere-banner-text {
            h2 {
                font-size: 40px;
            }
        }

        .solfin-banner-wrapper {
            flex-direction: column-reverse;
        }

       
    }
    
    .right-side-image {
        padding-left: 0;
        margin-top: 30px;
    }

    .header-space {
        padding-top: 167px !important;
    }

    .digitech-technology-card {
        margin-bottom: 25px;
    }

    .work-slide {
        .slick-slide {
            padding: 0 25px;
        }
    }

    

    .copywriting-tabs {
        padding-left: 0;
    }

    .digitech-features-card {
        margin-bottom: 30px;
        .card-img-wrapper {
            padding: 35px;
            height: 328px;
        }
    }

    .result-right-wrapper {
        margin-top: 30px;
        .row {
            margin-top: 30px;
        }
    }

    .right-content {
        margin-top: 50px;
        padding-left: 0;
    }

    .deliverables-tabs {
        flex-direction: inherit;
        flex-wrap: wrap;
        span {
            width: auto !important;
        }
    }

    .copywritting-para {
        p {
            padding-right: 0; 
        }
    }

    .healthism-card {
        margin-top: 0;
    }

    .difference-design {
        height: 481px !important;
        &::before {
            height: 5%;
            top: 36px;
        }
        .ResizePanel-module_ResizeContentHorizontal__1gGbA {
            max-width: 650px;
        }
        .ResizePanel-module_ResizeBarHorizontal__3TBZ5 {
            height: 425px;
            margin-top: -45px;
        }
    }
    
    .work-slide {
        .slick-prev {
            margin-left: 202px;
        }
        .slick-next {
            margin-right: 235px;
        }
        .slick-prev, .slick-next {
            &::before {
                width: 30px;
                height: 30px;
            }
        }
    }

    .menu {
        .digitech-nav {
            margin: 0 auto 0;
            ul {
                display: block !important;
            }
        }
    }

    .quotes-text {
        p {
            font-size: 28px;
            padding-right: 0; 
            line-height: 40px;
        }
    }



    .service-main-banner {
        padding-top: 120px;
        padding-bottom: 90px;
        .row {
            flex-direction: column-reverse;
        }
    }

    .service-banner-right {
        margin-bottom: 50px;
        img {
            max-width: 90%;
            margin-bottom: 0; 
        }
    }

    .service-column {
        .col-lg-4 {
            &:nth-child(3) {
                margin-top: 30px;
            }
            &:nth-child(4) {
                margin-top: 30px;
            }
            &:nth-child(5) {
                margin-top: 30px;
            }
            &:nth-child(6) {
                margin-top: 30px;
            }
        }
    }

    .service-card-wrapper {
        &.service-web-cards {
            display: block !important;
        }

        .Service-cards-innre {
            margin-top: 50px;
        }
        .service-left {
            width: 100%;
        }
        .service-right {
            padding-left: 0;
            max-width: 100%;
            .col-lg-6{
                &:nth-child(3) {
                    margin-top: 60px;
                }
                &:nth-child(4) {
                    margin-top: 60px;
                }
            }
        }
    }


    .showcase-slider {
        .showcase-inner-wrapper {
            flex-direction: column-reverse;
            .showcase-left-text {
                width: 100%;
                margin-top: 40px;
                padding-bottom: 0;
                padding-left: 60px;
                margin-bottom: 0;
                position: relative;
                h6 { 
                    width: 100%;
                }
            }
            .showcase-right-img {
                padding: 0 40px;
            }
        }
    }

    .service-features {
        margin-bottom: 68px;
    }

    .statistics-right {
        margin-top: 50px;
    }

    .statistics-card-wrapper {
        padding: 41px 35px;
        width: 323px;
    }

    .showcase-slider-wrapper{
        &::before {
            max-height: calc(100% - 35%);
        }
    }

    .digitech-cards-wrapper {
        .row {
            .col-md-4 {
                margin-bottom: 30px;
            }
        }
    }

    .ecommerce-features-card {
        padding: 60px;
    }

    .features-img {
        img{
            width: auto;
        }
    }

  

    .copywritting-para {
        .row {  
            .col-md-4 {
                margin-bottom: 25px;
            }
        }
    }

    .mob-card-wrapper {
        align-items: center;
        margin-bottom: 60px;
        &.mob-inner-wrapper {
            flex-direction: inherit;
        }
        .mob-content-wrapper {
            margin-left: 30px;
            &.mob-inner-content {
                text-align: left;
                margin-left: 30px;
                margin-right: 0;
            }
            p{
                margin-bottom: 0;
            }
        }
    }
    
    .feature-responsive-wrapper {
        .col-md-12 {
            &:nth-child(2) {
                margin: 30px 0;
            }
        }
    }

    .service-main-banner {
        padding-top: 180px;
        padding-bottom: 40px;
        .row {
            flex-direction: column;
        }
    }

    .service-banner-right {
        margin-top: 50px;
        justify-content: center;
    }

    .menu {
        .digitech-nav {
            ul {
                li {
                    margin-bottom: 15px;
                }
            }
        }
    }

    .uiux-process-boxes {
        .process-inner-boxes {
            max-width: 387px;
        }
    }

    
    .case-study-slider{
        .slick-prev, .slick-next {
            top: 35%;
        }
    }


    .uiux-process-boxes {
        display: block;
        .process-item-wrapper {
            width: 100%;
            height: 150px !important;
            display: flex;
            align-items: center;
            justify-content: space-between;
            &.active {
                height: 510px !important;
                display: block;
                padding: 50px 27px;
                .process-content  {
                    h4 {
                        &.process-second-title {
                            font-size: 28px;
                        }
                    }
                }  
            } 
            .process-content  {
                h4 {
                    &.process-second-title {
                        font-size: 22px;
                    }
                }
            }    
        }
    }

    .work-slide {
        .slick-list {
            padding-top: 60px !important;
        }
        .slick-slide {  
            &.slick-active {
                .case-study-text{
                    .work-content {
                        display: block !important;
                        .work-btn-wrapper {
                            width: 100%;
                            max-width: 100%;
                            text-align: end;
                        }
                    }
                }
            }
        }
    }



    .menu {
        .digitech-nav {
            padding: 0;
            .nav-inner {
                height: 100%;
                width: 100%;
                margin-top: 50px;
                max-height: 574px;
                overflow-y: scroll;
                overflow-x: hidden;
            }
            .nav-list-wrapper {
                li {
                    .custom-dropdown {
                        &.show {
                            margin-top: 30px !important;
                        }
                        .dropdown-menu {
                            width: 750px;        
                            position: relative;
                            margin-left: 0 !important;
                            padding: 0 20px;
                            .dropdown-content-wrapper {
                                margin-top: 30px !important;
                                .dropdown-list-wrapper {
                                    display: flex !important;
                                    li {
                                        width: 47%;
                                    }
                                }
                            }
                            .dropdown-link {
                                .dropdown-inner-wrapper {
                                    .dropdown-heading {
                                        max-width: 100%;
                                    }
                                }
                            }
                        }
                        &.show {
                            transform: translateY(-0px);
                            .nav-link {
                                &::after {
                                    top: 39%;
                                    width: 3%;
                                    background-size: 14px;
                                    background-image: url(../images/down-arrow.svg);
                                }
                            }
                        }
                    }
                }
            }
        }
    }


    .responsive-cards-wrapper {
        padding-left: 0;
    }

    .enterprise-application-wrapper {
        display: block !important;
        .enterprise-application-left {
            width: 100%;
        }
        .enterprise-application-right {
            width: 100%;
            margin-top: 60px;
            padding-left: 0 ;
            .mob-feature-img {
               text-align: center;
                
                img {
                    width: 60% !important;
                }
            }
        }
    }
    .features-inner-cards {
        margin-top: 20px;
    }

    .overlapping-wrapper {
        height: 660px !important;
        &.brand-overlap-wrapper {
            height: 614px !important;
        }
    }

    .brand-benefits-wrapper {
        padding-top: 90px;
    }

    .consult-btn-wrapper {
        justify-content: start !important;
    }
    

    .digitech-services-wrapper {
        .digitech-card {
            height: 100%;
            margin-bottom: 0;
        }
                
        .row {
            .col-xl-3 {
                &:nth-child(2) {
                    margin-top: 0;
                }
                &:nth-child(3) {
                    margin-top: 25px;
                }
                &:nth-child(4) {
                    margin-top: 25px;
                }
            }
        }
    }

    .service-pages-spacing {
        padding-top: 40px !important;
    }

    .software-technologies-card {
        display: block;
        padding: 30px 20px;
        margin-bottom: 0;
        .technologies-left-icon {
            text-align: center;
            margin-bottom: 20px;
            width: 50px;
        }
        .card-body {
            text-align: center;
            padding-left: 0;
            .card-text {
                min-height: 148px;
            }
        }
    }



    .case-study-slider {
        .case-study-text {
            .case-study-heading {
                font-size: 26px;
            }
        }
    }

    .company-service-wrapper {
        .company-service-box {
            .row {
                .col-lg-4{
                    &:nth-child(4) {
                        margin-bottom: 30px;
                    }
                }
            }
        }
    }
    .statistics-wrapper-left {
        padding-top: 0;
    }

    .features-img {
        margin-top: 30px; 
        margin-bottom: 30px;
        img {
            width: 50%;
        }
    }

    .mob-feature-img {
        display: flex;
        justify-content: center;
        img {
            width: 60% !important;
        }
    }

    .technologies-card-wrapper {
        .col-md-6 {
            margin-bottom: 24px;
            &:nth-child(5){
                margin-bottom: 0;
            }
            &:nth-child(6){
                margin-bottom: 0;
                .software-technologies-card  {
                    .card-body {
                        .card-text {
                            min-height: 121px;
                        }
                    }
                }
            }
        }
    }

    .pricing-tabs-wrapper {
        margin-top: -190px;
    }

    .pricing-banner-wrappeer {
        padding-bottom: 230px;
    }

    .pricing-info-wrapper {
        .list-group {
            flex-direction: column;
            .list-group-item {
                margin-bottom: 24px;
                border: 1px solid var(--border-color) !important;
                .package-info {
                    padding: 50px 30px 43px;
                }
                &:nth-child(2) {
                    margin-top: auto;
                    .package-info {
                        padding: 54px 30px 43px;
                    }
                }
            }
        }
    }
    
    .compare-wrapper {
        .payment-wrapper {
            padding: 35px 20px;
            .list-group-item {
                padding: 0 19px 0 40px;
                &:nth-child(1) {
                    padding-left: 0;
                }
                &:nth-child(4) {
                    padding-left: 0;
                }
            }
        }
    }

    .atmosphere-banner-right {
        margin-top: 50px;
        .folio-banner-badge {
            display: flex;
            flex-wrap: wrap;
        }
    }

    .atmosphere-banner-left {
        padding-right: 0;
    }

    .color-usage-wrapper {
        flex-direction: column;
    }

    .primary-color-usage {
        width: 100%;
    }

    .atmosphere-banner-right {
        .atmosphere-info-wrapper {
            li {
                padding: 0;
            }
        }
    }

    .primary-color-usage {
        ul {
            li {
                width: 48%;
                &:nth-child(2) {
                    margin-right: 0;
                }
                &:nth-child(3) {
                    margin-right: 24px;
                }
                &:nth-child(4) {
                    margin-right: 0;
                }
                &:nth-child(6) {
                    margin-right: 0;
                }
            }
        }
        .primary-heading {
            margin-top: 50px;
        }
        &.secondry-color-usage {
            margin-top: 50px;
            margin-right: 0;
        }

        .color-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }

    .switches-container {
        margin-left: auto;
        margin-right: auto;
    }
          
    .monthly-toggle-btn {
        margin-bottom: 80px;
    }

    .compare-wrapper {
        .payment-wrapper {
            .payment-inner-wrapper {
                flex-direction: column;
                .payment-icon {
                    margin: 0 auto 30px;
                }
                .payment-text {
                    text-align: center;
                }
            }
        }
    }

    .mob-showcase-slider {
        .slick-prev, .slick-next {
            &::before {
                width: 30px;
                height: 30px;
            }
        }
    }

    .folio-logo-wrapper  {
        padding: 33px 0;
        ul {
            li {
                padding: 0 54px; 
                .logo-image-wrapper  {
                    img {
                        width: 92px;
                    }
                }
            }
        }
    }

    .colo-usage-main {
        margin-top: 80px;
    }

    .webasite-para-wrapper {
        flex-direction: column;
        .website-para {
            width: 100%;
        }
        .visit-site-btn  {
            margin-top: 30px;
            text-align: left !important;
        }
    }

    .typography-wrapper {
        &.iconography-wrapper {
            .typography-inner-wrapper {
                padding: 50px;
            }
        }
        .typography-inner-wrapper {
            padding: 50px;
            .row {
                .col-md-6 {
                    &:last-child {
                        margin-top: 24px;
                    }
                }
            }
            .typography-family-info {
                .alphabet-font-wrapper {
                    justify-content: space-between;
                }
                .alphabets-wrapper {
                    margin-top: 40px;
                    padding-right: 0 !important; 
                }
            }
            .typography-font-info {
                .typography-font {
                    margin-top: 40px;
                    justify-content: space-between;
                }
            }
        }
    }

    .website-tabs-wrapper {
        .tab-image-content {
            .upper-frame {
                width: 80%;
                height: auto !important;
            }
            .right-side-image {
                width: 80%;
                img {
                    height: 550px !important;
                }
            }
        }
    }

    .ozen-inner-icons {
        justify-content: space-between;
    }

    .responsive-icon-wrapper {
        justify-content: space-between;
    }

    .folio-logo-wrapper {
        margin-top: -160px; 
    }

    .folio-banner {
        padding-bottom: 130px; 
    }

    .folio-deliverables-tabs {
        width: 50%;
    }

    .folio-logo-wrapper{
        &.ahr-logo-wrapper {
            ul {
                li {
                    padding: 0 60px;
                    &:first-child {
                        .logo-image-wrapper {
                            img {
                                width: 140px;
                            }
                        }
                    }
                    &:nth-child(3) {
                        border-right: 0;
                    }
                    &:nth-child(4) {
                        margin-top: 24px;
                    }
                }
            }
        }
    }

    .typography-wrapper {
        .typography-inner-wrapper {
            &.ahr-typography-wrapper {
                .row {
                    .col-lg-6 {
                        &:nth-child(2) {
                            margin-top: 30px;
                        }
                    }
                }
            }
        }
    }

    .folio-logo-wrapper{
        &.games-stores-logo {
            margin-top: -145px;
            ul {
                li {
                    .logo-image-wrapper {
                        img {
                            width: 192px;
                        }
                    }
                }
            }
        }
    }
}


@media (max-width: 880px) {
    
    .overlapping-main-wrapper {
        .comet-responsive-column {
            .col-lg-6 {
                &:first-child {
                    width: 100%;
                }
            }
        }
    }
}

@media (max-width: 850px){
    .overlapping-wrapper {
        height: 655px !important;
        &.brand-overlap-wrapper {
            height: 614px !important;
        }
        .page {
            &.brand-Overlapping-wrapper {
                .elevate-brand-wrapper {
                    padding: 80px 80px 90px; 
                }
            }
        }
    }
}


@media (max-width: 821px){
    .showcase-slider-wrapper{
        &::before {
            max-height: calc(100% - 43%);
        }
    }

    
   
    .menu {
        .digitech-nav {
            .nav-list-wrapper {
                li {
                    .custom-dropdown {
                        .dropdown-menu {
                            .dropdown-content-wrapper {
                                .dropdown-list-wrapper {
                                    display: block !important;
                                    li {
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}

@media (width: 800px) {
    .pricing-table-wrapper {
        .pricing-table-inner {
            position: relative;
            .pricing-data-inner {
                position: relative;
                overflow-x: scroll;
            }
        }
    }
}

@media (max-width: 768px){

    h1 {
        font-size: 57px;
        font-weight: 700;
    }

    .custom-container-fluid {
        padding: 0 15px;
    }

    .header-space {
        padding-top: 150px !important;
    }

    header {
        .menu-image {
            margin-left: 15px;
        }
    }

    

    .digitech-services-card {
        margin-bottom: 30px;
    }

    .digitech-services-wrapper {
        .service-card-heading {
            margin-bottom: 30px;
        }
    }

    .unified-btn  {
        text-align: left !important;
    }


    .digitech-services-wrapper {
        .digitech-card {
            .btn-wrapper {
                opacity: 1;
                visibility: visible;
                margin-top: 30px;
                .learn-more-btn {
                    color: var(--primary-color);
                }
                &:hover {
                    .learn-more-btn {
                        color: var(--bs-white);
                    }   
                }
            }
        }
        .row {
            .col-lg-3 {
                &:nth-child(1) {
                    margin-top: 0;
                }
                &:nth-child(3) {
                    margin-top: 30px;
                }
                &:nth-child(odd) {
                    margin-top: 30px;
                }
            }
        }
    }

    .small-heading {
        font-size: 45px;
    }

  
    .atmosphere-banner{
        &::before {
            width: 60%;
            height: 82%;
            background-size: 346px;
        }
        .atmosophere-banner-text {
            h2 {
                font-size: 35px;
            }
        }
    }

    .atmosphere-banner {
        &::before {
            background-position: 273px !important;
            background-size: 375px !important;
        }

        .solfin-bg {    
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                width: 100% !important;
            }
        }

        .atmosophere-banner-text {
            h2 {
                font-size: 36px;
            }
        }

        .solfin-banner-wrapper {
            flex-direction: column-reverse;
        }

       
    }

    .right-content {
        margin-top: 30px;
        padding-left: 0;
    }

    .deliverables-tabs {
        flex-direction: inherit;
        flex-wrap: wrap;
    }

    

    .digitech-technology-card {
        margin-bottom: 25px;
    }
    
    .right-side-image {
        margin-top: 30px;
        padding-left: 0;
    }

    .copywritting-para {
        p {
            padding-right: 0; 
        }
    }

    .about-paragraph {
        p {
            margin-top: 30px;
        }
    }

    
    .result-wrapper {
        padding-right: 0;
        margin-bottom: 40px;
        .result-suv-heading {
            margin-top: 45px;
        }
    }

    
    .case-study-slider {
        .case-study-text  {
            .case-study-heading {
                font-size: 27px;
            }
        }
        .slick-prev {
            left: 0;
        }
        .slick-next {
            right: 0;
        }
        .slick-dots {
            bottom: -15px !important;
        }
    }

    .work-slide {
        .slick-slide {
            padding: 0 20px;
        }
        .slick-prev {
            margin-left: 65px;
        }
        .slick-next {
            margin-right: 90px;
        }

        .slick-prev, .slick-next {
            &::before {
                width: 27px;
                height: 27px;
            }
        }
    }

    .atmosphere-design-wrapper {
        padding: 20px;
    }

    .digitech-features-card {
        .card-img-wrapper {
            height: auto;
        }
    }

    .custom-dropdown {
        .dropdown-menu {
            width: 320px;
            .dropdown-item {
                margin-bottom: 15px;
            }
        }
    }
    
    .atmosphere-design-wrapper {
        padding: 40px 20px;
        display: block !important;
        .atmosphere-left-pannel {
            width: 100%;
        }


        .atmosphere-right-pannel {
            width: 100%;
            margin-left: 0;
            margin-top: 30px;
        }
    }

    
    .solfin-right {
        padding-left: 0;
    }

    .right-design {
        padding: 0 !important;
    }

    .difference-design {
        height: 375px !important;
        .ResizePanel-module_ResizeContentHorizontal__1gGbA {
            max-width: 484px;
        }
        .ResizePanel-module_ResizeBarHorizontal__3TBZ5 {
            height: 308px;
            width: 4px !important;
            margin-top: -31px;
        }
        
        .ResizePanel-module_ResizeHandleHorizontal__PkS9u {
            width: 40px !important;
            height: 40px !important;    
            span{
                &::after {
                    background-size: 20px;
                }
            }
        }
    }

    
    .mobile-sapce {
        margin-right: 30px;
        margin-left: 0;
    }
    

    .difference-design {
        padding: 53px 0 60px;
    }

    
    .quotes-text {
        p {
            font-size: 24px;
            padding-right: 0; 
            line-height: 40px;
        }
    }

    .quotes-wrapper {
        margin-bottom: 20px;
    }

    .testimonal-slider {
        .slick-next {
            right: -15px;
        }

        .slick-prev {
            left: -15px;
        }
    }

    

    // banner responsive tabs

    .digitech-hero-banner {
        .digitech-desktop-banner {
            display: none;
        }
    }

    .hero-responsive-banner {
        display: block !important;
        .rts___tabs___container {
            margin-top: 40px;
            padding: 0;
            .rts___tabs {
                display: flex;
                overflow-y: visible !important;
            }
            .rts___tab {
                border: 0;
                height: 170px;
                padding: 30px 20px;
                width: 100%;
                min-width: 200px;
                border-radius: 0;
                text-align: center;
                margin-right: 15px;
                margin-left: 15px;
                transform: translateY(0);
                transition: all 0.3s ease-in;
                background-color: var(--bs-white);
                box-shadow: 10px 10px 10px 0px rgba(143, 143, 143, 0.1);
                &:hover {
                    background-color: var(--theme-color);
                    .card {
                        .card-header {
                            .card-img {
                                img {
                                    filter: brightness(14);
                                }
                            }
                        }
                        .card-body {
                            .card-paragraph {
                                color: var(--bs-white);
                            }
                        }
                    }
                }
                &.active {
                    transform: translateY(-10px);
                    background-color: var(--theme-color);
                    .card {
                        .card-img {
                            img {
                                filter: brightness(7) invert(0);
                            }
                        }
                        .card-body {
                            .card-paragraph {
                                color: var(--bs-white);
                            }
                        }
                    }
                }
            }
        }
        .digitech-card {
            background-color: transparent;
        }

        
        
    }

    .tab-content {
        padding: 0 60px;
        .banner-heading {
            .banner-img {
                margin-bottom: 20px;
            }
            .banner-paragrapgh {
                width: 100%;
                margin: 20px auto;
                max-width: 823px;
                padding: 0;
            }
        }
    }

    .banner-theme-btn {
        border: 1px solid var(--primary-color);
    }


    .service-card-wrapper {
        .service-right {
            .col-lg-6{
                &:nth-child(2) {
                    margin-top: 40px;
                }
                &:nth-child(3) {
                    margin-top: 40px;
                }
                &:nth-child(4) {
                    margin-top: 40px;
                }
            }
        }
    }

    .service-column {
        .col-lg-4{
            &:nth-child(2) {
                margin-top: 60px;
            }
            &:nth-child(3) {
                margin-top: 60px;
            }
            &:nth-child(4) {
                margin-top: 60px;
            }
            &:nth-child(5) {
                margin-top: 60px;
            }
            &:nth-child(6) {
                margin-top: 60px;
            }
        }
    }
    .statistics-card-wrapper {
        width: 270px;
    }

   

    .ecommerce-showcase-slider {
        .slick-slider {
            .slick-next {
                right: 0;
                margin-right: 23px;
            }
            .slick-prev {
                margin-left: -10px;
            }
        }
    }

    .responsive-top-space {
        padding-top: 60px !important;
    }

    .statistics-para {
        padding-right: 0;
    }

    .software-feature-wrapper {
        display: block;
    }

    .mob-card-wrapper{
        &.mob-feature-space {
            margin-bottom: 60px;
        }
    }

    .feature-responsive-wrapper {
        .col-md-12 {
            &:nth-child(2) {
                margin-bottom: 0;
            }
        }
    }

    .web-banner-heading {
        padding-right: 0;
    }

    .hero-responsive-banner {
        .tab-content {
            padding: 0;
        }
    }

    .uiux-process-boxes {    
        margin-top: 80px;
        &::before {
            left: 0;
            margin-left: 40px;
        }
        .process-item-wrapper  {
            &.active {
                height: 540px !important;
                .process-img {
                    width: 120px;
                    height: 120px;
                    margin: 0 auto 30px;
                }
                .process-content {
                    h4{
                        &.process-second-title {
                            font-size: 22px;
                        }   
                    }
                }
            }
            .process-img {
                height: 0;
                width: 0;
            }
            .process-inner-boxes {
                max-width: 100%;
                flex-direction: inherit !important;
            }
            .process-content {
                h4{
                    &.process-second-title {
                        font-size: 19px;
                    }   
                }
            }
        }
    }

    .uiux-process-heading {
        h2 {
            font-size: 40px;
        }
    }

    .service-card-inner-wrapper {
        .row {
            .col-xl-3{
                &:nth-child(2) {
                    margin-top: 0;
                }
                &:nth-child(4) {
                    margin-top: 0;
                    .digitech-card {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    .showcase-slider {
        .slick-prev {
            z-index: 9;
            left: -12px;
        }
        .slick-next {
            right: -20px;
        }
    }

    .case-study-slider {
        .slick-next {
            margin-right: 10px;
        }
        .slick-prev {
            z-index: 1;
            margin-left: 10px;
        }
    }
    
    .nav-tgl {
        height: 50px;
        width: 50px;
    }


    .consult-wrapper {
        padding: 40px 20px;
    }

    .testimonal-slider {
        .testimonial-wrapper {
            &::before {
                top: 0;
                margin-top: 31px;
                z-index: -1;
                opacity: 0.1;
            }
        }
    }

    .testimonal-slider  {
        .slick-dots {
            bottom: -76px !important;
        }
    }

    .digitech-services-wrapper { 
        .digitech-card {
            height: 490px;
            padding: 40px;
        }
    }

    .desktop-service-cards {
        display: none;
    }

    // responsive service cards

    .service-accordion-wrapper {
        display: block;
        .accordion-item {
            border: 0;
            margin-bottom: 24px;
            .accordion-header {
                .accordion-button {
                    box-shadow: none;
                    font-size: 32px;
                    padding: 25px 40px;
                    font-weight: 600;
                    font-family: var(--theme-font);
                    background-color: var(--extra-light-yellow);
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                    .service-card-img {
                        margin-right: 50px;
                    }
                    &:not(.collapsed) {
                        color: var(--bs-white);
                        background-color: var(--theme-color) !important;
                        &::after {
                            filter: invert(1) brightness(2);
                        }
                        .service-card-img {
                            img {
                                filter: invert(2) brightness(2);
                            }
                        }
                    }
                }
            }
            
            &:nth-child(2) {
                .accordion-header {
                    .accordion-button { 
                        background-color: var(--extra-light-orange);
                    } 
                }
            }

            &:nth-child(3) {
                .accordion-header {
                    .accordion-button { 
                        background-color: var(--extra-light-purple);
                    } 
                }
            }

            &:nth-child(4) {
                .accordion-header {
                    .accordion-button { 
                        background-color: var(--extra-light-blue);
                        border-bottom-left-radius: 0;
                        border-bottom-right-radius: 0;
                    } 
                }
            }
            .accordion-collapse {
                .accordion-body {
                    padding-top: 0;
                    padding-bottom: 30px;
                    padding-left: 40px;
                    padding-right: 100px;
                    color: var(--bs-white);
                    background-color: var(--theme-color);
                }
            }
        }
    }

    .menu {
        .digitech-nav {
            .nav-list-wrapper {
                li {
                    .custom-dropdown {
                        .dropdown-menu {
                            padding: 0 20px 0 40px;
                            .row {
                                .col-sm-12 {
                                    &:last-child {
                                        .dropdown-link {
                                            margin-bottom: 0 !important;
                                        }
                                    }
                                }
                            }
                            .dropdown-link {
                                margin-bottom: 40px !important;
                                .dropdown-inner-wrapper {
                                    p {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    

    .ecommerce-features-card{
        &.digital-responsive-feature {
            margin-bottom: 24px;
        }
    }

    
    .technology-slider-wrapper {
        margin-top: 60px;
        display: block;
    }
    .technologies-card-wrapper {
        display: none;
    }

    .responsive-digitech-tab {
        display: block;
        position: relative;
        margin-top: 50px;
        .atmosphere-image-wrapper {
            margin-top: 20px;
            margin-bottom: 30px;
        }
        .rts___nav___btn___container {
            display:flex !important;
            position: relative;
            &:first-child {
                .rts___nav___btn {
                    &::before {
                        content: '';
                        width: 100%;
                        height: 30%;
                        top: 0;
                        bottom: 0;
                        margin-top: 34px;
                        left: 0;
                        position: absolute;
                        background-image: url(../images/prev.svg);
                        background-repeat: no-repeat;
                        background-size: 12px;
                    }
                }
            }
            &:last-child {
                .rts___nav___btn {
                    &::after {
                        content: '';
                        width: 100%;
                        height: 30%;
                        top: 0;
                        bottom: 0;
                        margin-top: 34px;
                        right: -5px;
                        position: absolute;
                        background-image: url(../images/next.svg);
                        background-repeat: no-repeat;
                        background-size: 12px;
                    }
                }
            }
            .rts___nav___btn {
                padding: 0 7px; 
                border: 0 !important;
                &:hover {
                    background-color: transparent !important;
                }
                &>svg {
                    display: none;
                    width: 18px;
                }
            }
        }
        .rts___btn {
            padding: 20px 40px;
            font-size: 20px;
            &.rts___tab___selected {
                background-color: var(--case-study-color);
            }
            .tab-name {
                font-size: 20px;
            }
        }
    }

    .back-top-btn {
        bottom: 170px;
    }

    .benefits-content-wrapper  {
        .features-inner-cards {
            .row {
                .col-sm-12 {
                    &:nth-child(2) {
                        margin-top: 0;
                    }
                }
            }
        }
    }

    .features-inner-cards {
        .row {
            .col-sm-12 {
                &:nth-child(2) {
                    margin-top: 30px;
                }
            }
        }
    }

    .company-service-wrapper {
        .company-service-box {
            .row {
                .col-lg-4{
                    &:nth-child(5) {
                        margin-bottom: 30px;
                    }
                    // &:nth-child(6) {
                    //     margin-bottom: 30px;
                    // }
                }
            }
        }
    }

    .service-column {
        .col-lg-6{
            &:nth-child(4) {
                margin-top: 40px;
            }
            &:nth-child(3) {
                margin-top: 40px;
            }
            &:nth-child(2) {
                margin-top: 40px;
            }
        }
    }

    .mobile-feature-space  {
        margin-bottom: 30px !important;
    }

    .get-done-cards {
        .card-body {
            .card-title {
                font-size: 19px !important;
            }
        }
    }
    
    .ecommerce-features-card {
        &.brand-feature-responsive {
            margin-top: 30px;
        }
    }

    .features-inner-cards {
        .row {
            .col-lg-4 {
                &:nth-child(4) {
                    margin-bottom: 30px;
                }
                &:nth-child(5) {
                    margin-bottom: 30px;
                }
                &:nth-child(6) {
                    margin-bottom: 0;
                }
            }
        }
    }

    .showcase-slider {
        .showcase-inner-wrapper {
            .showcase-left-text {
                h5 {
                    width: 100%;
                }
            }
        }
    }

    .faqs-wrapper {
        .faqs-big-heading {
            font-size: 40px;
        }
    }
    .pricing-table-inner {
        .table-recommended-wrapper {
            padding: 11px 7px;
            font-size: 13px;
        }
    }
    .pricing-table-wrapper {
        .package-table-headings {
            width: 210px;
        }
        .pricing-data-inner {
            overflow-x: scroll;
            width: calc(100% - 210px);
        }
        .blank-box {
            height: 149.2px;
        }
    }

    .pricing-tabs-wrapper {
        .rts___tabs___container {
            .rts___tabs {
                .rts___tab {
                    margin: 0 13px;
                }
            }
        }
    }

    .primary-color-usage {
        ul {
            li {
                width: 47%; 
            }
        }
    }

    .atmosphere-website-folio {
        .tab-image-contant {
            padding: 60px 0;
        }
    }

    .atmosphere-ui-sheet {
        .row {
            .col-md-6 {
                &:last-child {
                    margin-top: 24px;
                }
            }
        }
    }

    .atmosphere-ui-sheet {
        padding-top: 30px;
    }

    .folio-deliverables-tabs {
        width: 100%;
    }

    .folio-logo-wrapper {
        ul {
            flex-wrap: wrap;
            li {
                &:nth-child(2) {
                    border: 0;
                }
                &:last-child {
                    margin-top: 25px;
                }
            }
        }
    }
        
    

    .typography-wrapper {
        &.iconography-wrapper {
            .typography-inner-wrapper {
                padding: 50px;
            }
        }
        .typography-inner-wrapper {
            .row {
                .col-md-6 {
                    &:nth-child(2) {
                        margin-top: 24px;
                    }
                }
            }
        }
    }

    .folio-logo-wrapper{
        &.ahr-logo-wrapper {
            ul {
                li {
                    padding: 0 60px;
                    &:nth-child(3) {
                        padding: 0 84px;
                        margin-top: 24px;
                        border-right: 1px solid var(--border-color);
                    }
                }
            }
        }
    }

    .pricing-table-wrapper {
        .site-feature-heading {
            padding: 11px 10px;
            h6 {
                font-size: 14px;
            }
        }
        .custom-domain-wrapper {
            padding: 11px 10px;
            p {
                font-size: 14px;
            }
        }
    }

    .pricing-links-wrapper {
        .header-pricing-link {
            align-items: start !important;
            flex-direction: column;
            .header-pricing-btn {
                margin-bottom: 20px;
                margin-left: 0 !important;
                &.header-digital-pricing {
                    margin-bottom: 0;
                    margin-left: 0 !important;
                }
            }
        }
    }

    .website-tabs-wrapper {
        .ucmstab-image-content {
            padding: 60px 0;
            .ahr-website-dropshadow {
                margin-top: 20px;
            }
        }
    }
}

@media (max-width: 700px) {
    .company-service-wrapper {
        .company-service-box {
            .row {
                .col-md-6 {
                    width: 100% !important;
                }
            }
        }
    }
    .overlapping-wrapper {
        height: 640px !important;
        &.brand-overlap-wrapper {
            height: 700px !important;
            .page{
                &.brand-Overlapping-wrapper {
                    .elevate-brand-wrapper {
                        padding: 80px 70px;
                        .brand-img-wrapper {
                            img {
                                width: 100%;
                            }
                        }
                    }
                }
            } 
        }
    }


    .pricing-table-wrapper {
        .custom-domain-wrapper {
            p {
                font-size: 14px;
                line-height: 20px;
            }
        }
        .table-responsive-wrapper  {
            .custom-domain-wrapper {
                span {
                    font-size: 14px;
                }
            }
        }
    }

    

}

@media (max-width: 615px) {
    .statistics-card-wrapper {
        width: 100%;
        margin-right: 0;
    }

    .faqs-wrapper {
        .faqs-big-heading {
            font-size: 36px;
        }
    }

 
    .overlapping-wrapper {
        height: 730px !important;   
        .page {
            .offer-deals-wrapper {
                .offer-Img-wrapper {
                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media (max-width: 653px) {
    .atmosphere-banner{
        &::before {
            background-position: 214px !important;
            background-size: 347px !important;
        }
    }

    .statistics-right {
        display: block !important;
    }

    .statistics-img {
        margin-top: 30px;
    }

    .statistics-card-wrapper {
        width: 100%;
    }

    .marketing-stories-slider {
        .marekting-content-wrapper {
            .keywords-cards-wrapper {
                .statistics-card-wrapper {
                    margin-right: 24px;
                }
            }
        }
    }
    
    .right-design {
        display: block !important;
        .atmosphere-mobile {
            padding-left: 0 !important;
        }
    }

    .right-design {
        .atmosphere-mobile {
            &.mobile-sapce {
                margin-bottom: 30px;
                img {
                    width: 60% !important;
                }
            }
            &.ozen-reserve-space {
                margin-bottom: 30px;
            }
            &.solfin-mobile-space {
                margin-bottom: 30px;
                margin-left: 0 !important;
            }
            &.solfin-design {
                margin-left: 0 !important;
            }
        }
    }
}

@media (max-width: 681px) {
    .compare-wrapper {
        .payment-wrapper {
            .list-group-item {
                width: 100%;
                border-right: 0;
                padding: 0;
                margin-bottom: 24px;
                &:nth-child(1) {
                    padding-right: 0;
                }
                &:nth-child(4) {
                    padding-right: 0;
                }
                &:nth-child(5) {
                    padding-right: 0;
                }
                &:nth-child(6) {
                    padding-right: 0;
                }
            }
        }
    }


    .payment-accepted-method {
        flex-direction: column;
        .payment-accepted-text {
            margin-right: 0;
            margin-bottom: 24px;
        }
    }
}

@media (max-width: 630px) {
    
}

@media (max-width: 575px){
    .case-study-slider {
        .case-study-image {
            img {
                width: 70%;
            }
        }
        .case-study-text {
            padding: 30px 0;
            .case-study-heading {
                padding-right: 0;
            }
        }
    }

    .footer-links {
        margin-top: 30px;
    }

    .atmosphere-banner{
        &::before {
            background-position: 133px !important;
        }
    }

    .working-wrapper {
        display: block !important;
        margin-bottom: 20px;
        h5 {
            margin-bottom: 13px;
        }
    }

    .work-slide {
        .slick-prev {
            margin-left: 35px;
        }
        .slick-next {
            margin-right: 61px;
        }
    }

    .testimonal-slider {
        .slick-prev {
            left: -14px;
        }
        .slick-next {
            right: -14px;
        }
    }

    .difference-design {
        .ResizePanel-module_ResizeHandleHorizontal__PkS9u {
            width: 32px !important;
            height: 32px !important;
            .ResizePanel-module_ResizeHandleHorizontal__PkS9u {
                span{
                    &::after {
                        background-size: 18px; 
                    }
                }
            }
        }
    }

    .difference-design{
        &::before {
            height: 6%;
        }
    }

    .difference-design {
        .ResizePanel-module_ResizeContentHorizontal__1gGbA {
            max-width: 491px;
        }
    }

    .testimonial-wrapper {
        p {
            padding-right: 15px !important;
        }
    }


    .copyright {
        .row {
            flex-direction: column;
        }
        .copyright-left {
                text-align: center;
        }
        .social-media-wrapper {
            margin-top: 30px;
                .social-media-links  {
                    justify-content: center !important;
                }
        }
    }

    .work-slide {
        .slick-slide {
            padding: 0 15px;
        }
    }

    .case-study-slider {
        .slick-prev, .slick-next{
            top: 31%;
        }
    } 

    .service-accordion-wrapper {
        .accordion-item {
            .accordion-collapse {
                .accordion-body {
                    padding-right: 83px;
                }
            }
        }
    }

    .overlapping-wrapper {
        .page {
            .offer-deals-wrapper {
                .offer-text-wrapper {
                    h2 {
                        font-size: 24px;
                    }
                }
            }
        }
    }

    .uiux-process-boxes {
        .process-item-wrapper {
            height: 110px;
            .process-content {
                h4 {
                    font-size: 16px;
                }
            }
        }
    }

    .showcase-slider {
        .showcase-inner-wrapper {
            .showcase-right-img {
                padding: 0 20px;
            }
            .showcase-left-text {
                padding-left: 32px;
            }
        }
    }

    .gamestore-design {
        margin-bottom: 30px;
        margin-left: 0 !important;
    }

    // .responsive-digitech-tab {
    //     display: block;
    //     margin-top: 50px;
    //     // .rts___nav___btn___container {
    //     //     display: flex !important;
    //     //     margin: 0;
    //     //     .rts___nav___btn {
    //     //         padding: 0;
    //     //         border: 0 !important;
    //     //         &>svg {
    //     //             width: 25px;
    //     //         }
    //     //         &:hover {
    //     //             background-color: transparent !important;
    //     //         }
    //     //     }
    //     // }
    // }

    .testimonal-slider {
        .testimonial-wrapper {
            .home-testimonial-heading {
                padding-right: 100px;
            }
        }
    }

    .digitech-services-wrapper {
        padding: 60px 0 30px; 
    }

    
    .about-service-wrapper {
        padding: 60px 0 30px;
    }

    .responsive-design-wrapper {
        padding: 30px 0;
    }

    .faqs-wrapper {
        padding-bottom: 30px;
    }

    .consult-main-wrapper {
        padding: 30px 0;
    }

    .company-service-wrapper {
        padding-bottom: 60px;
    }

    .faqs-consult-wrapper {
        padding-bottom: 60px !important;
    }

    .uiux-design-wrapper {
        padding-bottom: 30px;
    }

    .overlapping-main-wrapper {
        padding-bottom: 30px;
    }

    .comet-features-wrapper {
        padding: 30px 0 30px;
    }
    
    .about-atmosphere {
        padding: 60px 0 30px;
    }
    
    .about-list-wrapper {
        .list-group {
            .list-group-item {
                padding: 0;
                margin-bottom: 30px !important;
            }
        }
    }

    .digitech-solution {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .result-inner {
        .row {
            .col-lg-6 {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .small-heading {
        font-size: 35px;
    }

    .atmosphere-heading {
        h2 {
            margin-bottom: 40px;
        }
    }

    .responsive-digitech-tab {
        margin-top: 40px;
    }

    .atmosphere-design-wrapper {
        margin-top: 40px;
    }

    .digitech-consult-wrapper {
        padding-bottom: 60px; 
    }

    .atmosphere-case-study {
        padding-top: 30px;
    }

    .digitech-features-card {
        .card-img-wrapper {
            .card-img {
                width: 80%;
            }
        }
    }

    .atmosphere-mobile-folio {
        padding: 30px 0;
    }

    .atmosphere-website-folio {
        padding-bottom: 30px;
    }

    .atmosphere-brochure-folio {
        padding: 30px 0 0;
    }

    .collaterals-wrapper {
        .row {
            .col-lg-4 {
                &:nth-child(4) {
                    margin-bottom: 24px;
                }
                &:nth-child(5) {
                    margin-bottom: 24px;
                }
            }
        }
    }

    .healthism-btn-wrapper {
        margin-top: 60px;
        padding: 60px 0;
    }

    .ahr-kanifushi-wrapper {
        padding-bottom: 30px;
    }

    .service-card-heading {
        margin-bottom: 40px;
    }

    .color-usage-heading {
        margin-bottom: 40px;
    }

    .primary-color-usage {
        ul {
            flex-direction: column;
            li {
                width: 100%;
                height: 100%;
                margin-right: 0;
                margin-bottom: 24px;
                &:nth-child(2) {
                    margin-top: 0px;
                }
                &:nth-child(4) {
                    margin-bottom: 24px;
                }
                &:nth-child(5) {
                    margin-bottom: 24px;
                }
            }
        }
    }
    .pricing-table-wrapper {
        .blank-box {
            height: 148.2px;
            &.feature-right-blank {
                min-width: 451px;
            }
        }
        .responsive-heading-box {   
            min-width: 150px;
        }
        .customer-accounts-text {
            min-width: 150px;
        }
    }
    
    .ozen-color-wrapper {
        ul{
            &.ozen-color-usage {
                li {
                    &:nth-child(4) {
                        margin-bottom: 24px;
                    }
                    &:nth-child(5) {
                        margin-bottom: 24px;
                    }
                    &:nth-child(3) {
                        margin-bottom: 24px;
                    }
                }
            }
        }
    }

    .folio-logo-wrapper {
        padding: 20px 0;
        margin-top: -140px;
    }

    .about-atmosphere {
        padding: 60px 0 30px;
    }
    .atmosphere-case-study {
        padding-top: 30px;
    }
    .colo-usage-main {
        margin-top: 60px;
    }

    .website-tabs-wrapper {
        .tab-image-content {
            padding: 50px 0;
            .website-dropshadow {
                margin-top: 40px;
            }
        }
    }

    .typography-wrapper {
        &.iconography-wrapper {
            margin-top: 60px;
        }
    }

    .website-tabs-wrapper {
        .ucmstab-image-content {
            padding: 60px 0 40px;
        }
    }

    .ecommerce-slider-wrapper {
        .slick-slider {
            .slick-list {
                .slick-track {
                    .slick-slide {
                        .ecommerce-logo-slide {
                            height: 140px;
                            width: 140px !important;
                            img {
                                width: 100%;
                                &.ecommerce-img {
                                    width: 70%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 535px){
    .difference-design {
        height: 326px !important;
    }
    .difference-design {
        .ResizePanel-module_ResizeContentHorizontal__1gGbA {
            max-width: 442px;
        }
    }

    .difference-design {
        padding: 45px 0 60px;
      }

      
    .atmosphere-banner {
        .atmosophere-banner-text {
            h2 {
                font-size: 32px;
            }
        }
    }

    .small-heading {
        font-size: 40px;
    }

    .looking-packages-wrapper {
        margin-top: 60px;
        padding: 70px 40px;
    }

    .service-accordion-wrapper {
        .accordion-item {
            .accordion-header {
                .accordion-button {
                    padding: 25px;
                    font-size: 26px;
                    .service-card-img {
                        margin-right: 30px;
                    }
                }
            }
            .accordion-collapse {
                .accordion-body {
                    padding-bottom: 40px;
                }
            }
        }
    }

    .custom-dropdown {
        .technology-used {
            display: block;
        }
    }

    .menu {
        .digitech-nav {
            .nav-list-wrapper {
                li {
                    .custom-dropdown {
                        .technology-used {
                            .dropdown-technologies-img {
                                margin-top: 20px;
                                .responsive-tech-img {
                                    margin-left: 0;
                                }
                            }
                            a {
                                display: block;
                                margin-top: 20px;
                                margin-left: 0 !important;
                            }
                        }
                    }
                }
            }
        }
    }

 
    .responsive-card-inner {
        display: block;
        text-align: center;
        padding: 30px !important;
        .responsive-icon-wrapper {
            margin: 0 auto 24px;
        }
        .responsive-mobile-view {
            margin: 0 auto 24px;
        }
        .responsive-content-wrapper {
            width: 100%;
            margin-left: 0;
        }
    } 

    .responsive-design-inner {
        padding: 53px 25px; 
    }

        .testimonal-slider {
            .testimonial-wrapper{
                &::before {
                    background-size: 70px;
                    margin-top: 71px;
                }
            }
        }

        .marketing-stories-slider {
            .marekting-content-wrapper {
                .keywords-cards-wrapper {
                    display: block !important;
                }
            }
        }

        .overlapping-wrapper{
            &.brand-overlap-wrapper {
                height: 660px !important;
            }
        }

        .overlapping-wrapper {
            height: 710px !important;
        }
        .overlapping-wrapper {
            .page {
                .offer-deals-wrapper {
                    padding: 90px 40px;
                }
            }
        }

        .folio-logo-wrapper{
            &.ahr-logo-wrapper {
                ul {
                    li {
                        padding: 0 40px;
                        &:nth-child(3) {
                            padding: 0 64px;
                        }
                    }
                }
            }
        }
}

@media (max-width: 480px){
    h2 {
        font-size: 42px;
    }


    .small-heading {
        font-size: 33px;
    }

    .quotes-text {
        p {
            font-size: 20px;
            line-height: 32px;
        }
    }

    .about-left-text {
        margin-top: 30px;
    }

    .about-left-text {
        .atmosphere-inner-heading {
            font-size: 35px;
        }
    }

    .about-atmosphere {
        .atmosphere-heading {
            h2 {
                margin-bottom: 20px;
            }   
        }
        .atmosphere-link {
            margin-bottom: 30px;
            text-align: left !important; 
        }
    }

    .digitech-solution {
        .atmosphere-link {
            text-align: left !important; 
        }
    }

    .digitechtabs {
        margin-top: 20px;
    }
    .casestudy-banner-wrapper {
        width: 100% !important;
    }

    .atmosphere-banner{
        &::before {
            background-position: right !important;
            background-size: 296px !important;
        }
        .solfin-banner-wrapper {
            align-items: start!important;
        }
        .atmosophere-banner-text {
            .solfin-heading {
                max-width: 100%;
            }
            .atmosphere-small-heading {
                font-size: 20px;
            }
        }
    }

    .difference-design {
        .ResizePanel-module_ResizeContentHorizontal__1gGbA {
            max-width: 400px;
        }

        .ResizePanel-module_ResizeBarHorizontal__3TBZ5 {
            height: 269px;
        }
    }

    .features-img {
        img {
            width: 70%;
        }
    }

    .digitech-services-wrapper {
        .service-card-heading {
            display: block !important;
            a {
                display: flex;
                margin-top: 15px;
                justify-content: flex-end;
            }
        }
    }

    .service-accordion-wrapper {
        .accordion-item {
            .accordion-collapse {
                .accordion-body {
                    padding-right: 20px;
                }
            }
        }
    }

    .testimonal-slider {
        .testimonial-wrapper{
            &::before {
                background-size: 45px;
            }
        }
    }

    
    .overlapping-wrapper {
        height: 670px !important;
        &.brand-overlap-wrapper {
            height: 630px !important;
            .page{
                &.brand-Overlapping-wrapper {
                    .elevate-brand-wrapper {
                        padding: 80px 40px;
                    }
                }
            }
        }
        .page {
            .offer-deals-wrapper {
                padding: 60px 40px;
                .offer-Img-wrapper {
                    img {
                        width: 100%;
                    }
                }
            }

            &.overlap-box-third {
                .offer-deals-wrapper {
                    padding-top: 30px;
                }
            }
        }
    }

    .consult-main-wrapper {
        padding: 30px 0;
    }

    
    .work-desktop-img {
        display: none !important;
    }

    .work-responsive-img {
        display: block !important;
    }

    .hide-responsive-image {
        display: none !important;
    }

    .show-responsive-image {
        display: block !important;
    }

    .show-brand-responsive {
        display: block !important;
    }

    .marketing-stories-slider {
        .slick-slider {
            .slick-prev, .slick-next {
                top: 14%;
            }
        }
    }

    .pricing-links-wrapper {
        .header-pricing-btn {
            display: block;
            margin-bottom: 20px;
            margin-left: 0 !important;
        }
    }

    .software-wrapper {
        padding: 20px 0;
    }

    .overlapping-wrapper {
        height: 655px !important;
        .page {
            .next-slide-btn {
                margin-top: 20px;
            }
            .offer-deals-wrapper {
                padding: 90px 40px;
            }
        }
    }
    .folio-logo-wrapper {
        ul {
            flex-direction: column;
            li {
                padding: 0;
                border-right: 0;
                margin-bottom: 24px;
                &:last-child {
                    margin-top: 0;
                    margin-bottom: 0;
                }
            }
        }
    }

    .website-tabs-wrapper {
        .tab-image-content {
            padding: 40px 0;
        }
    }

    .webasite-para-wrapper {
        margin-bottom: 40px;
    }

    .typography-wrapper {
        .typography-inner-wrapper {
            padding: 30px;
            .typography-family-info {
                .alphabet-font-wrapper {
                    h1 {
                        margin-right: 50px;
                        font-size: 100px;
                    }
                    span {
                        font-size: 25px;
                    }
                }
                .alphabets-wrapper {
                    h2 {
                        font-size: 26px;
                    }
                }
                        
            }
            .typography-font-info {
                .typography-font {
                    margin: 30px 0;
                    h1 {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    .typography-wrapper{
        &.iconography-wrapper {
            margin-top: 60px;
            .typography-inner-wrapper {
                padding: 30px;
            }
        }
    }

    .website-tabs-wrapper {
        .tab-image-content {
            &.ucms-tab-image {
                padding: 20px !important;
            }
        }
    }

    .healthism-btn-wrapper {
        span {
            font-size: 24px;
            margin-bottom: 5px;
        }
        h2 {
            font-size: 32px;
        }
    }

        .folio-logo-wrapper{
            &.ahr-logo-wrapper {
                ul {
                    flex-direction: row;
                    li {
                        &:first-child {
                            border-right: 1px solid var(--border-color);
                        }
                    }
                }
            }
        }

        .website-tabs-wrapper {
            .ucmstab-image-content {
                padding: 40px 0 20px;
            }
        }

        .folio-logo-wrapper{
            &.games-stores-logo {
                margin-top: -125px;
                ul {
                    li {
                        .logo-image-wrapper {
                            img {
                                width: 150px;
                            }
                        }
                    }
                }
            }
        }
}

@media (max-width: 451px) {
    .overlapping-wrapper {
        height: 650px !important;  
        .page {
            .offer-deals-wrapper {
                padding: 60px 20px;
                .offer-text-wrapper {
                    h2 {
                        font-size: 22px;
                    }
                    p {
                        margin: 12px 0 16px;
                    }
                    
                }
                .offer-Img-wrapper {
                    img {
                        width: 100%;
                    }
                }

                .next-slide-btn {
                    margin-top: 20px;
                }
            }
        }
    }

           
}

@media (max-width: 460px) {
    .typography-wrapper {
        .typography-inner-wrapper {
            .typography-family-info {
                .alphabets-wrapper {
                    h3 {
                        font-size: 24px;
                    }
                }
            }
        }
    } 
}

@media (max-width: 431px){
    h1 {
        font-size: 50px;
    }
    .work-slide {
        .slick-next {
            margin-right: 47px;
        }
        .slick-prev {
            margin-left: 24px;
        }
    }

    .difference-design {
        .ResizePanel-module_ResizeContentHorizontal__1gGbA {
            max-width: 375px;
        }
    }
    .atmosphere-banner{
        .atmosophere-banner-text {
            .atmosphere-small-heading {
                font-size: 18px;
            }
        }
    }

    .digitechtabs {
        .rts___tabs___container {
            .rts___tabs {
                .rts___tab {    
                    .tab-name {
                        font-size: 16px;
                    }
                    &.active {
                        .tab-name {
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }

    .case-study-slider {
        .slick-next {
            margin-right: -14px;
        }
        .slick-prev {
            z-index: 1;
            margin-left: -14px;
        }

        .slick-prev, .slick-next {
            top: 25%;
        }
    }


    .testimonal-slider {
        .testimonial-wrapper {
            padding: 30px 20px;
            p {
                padding-right: 0 !important;
            }
        }
    }

    .service-accordion-wrapper {
        .accordion-item {
            .accordion-header {
                .accordion-button {
                    font-size: 22px;
                    .service-card-img {
                        margin-right: 27px;
                        img {
                            width: 50px;
                        }
                    }
                }
            }
        }
    }

    .menu {
        .digitech-nav {
            .nav-inner {
                max-height: 844px;
            }
        }
    }

    .footer-links {
        margin-top: 30px;
    }

    .overlapping-wrapper {
        .page{
            &.overlap-box-first {
                .offer-deals-wrapper {
                    padding-bottom: 90px;
                }
            }
            &.overlap-box-second {
                .offer-deals-wrapper {
                    padding-bottom: 70px;
                }
            }
        }
    }

    .process-accordion-wrapper {
        .accordion {
            .accordion-item {
                .accordion-header {
                    .accordion-button {
                        padding: 27px 30px;
                        &:not(.collapsed) {
                            font-size: 28px;
                        }
                    }
                }
                .accordion-collapse {
                    .accordion-body {
                        padding: 18px 30px 40px;
                        .accordion-number  {
                            span {
                                font-size: 45px;
                            }
                        }
                    }
                }
            }
        }
    }

    .pricing-table-wrapper {
        .package-table-headings {
            width: 200px;
        }
        .pricing-data-inner {
            width: calc(100% - 200px);
        }
        .blank-box {
            height: 148px;
        }
    }


    .folio-logo-wrapper {
        ul {
            li {
                .logo-image-wrapper {
                    &.atmosphere-living-logo {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        img {
                            width: 80%;
                        }
                    }
                }
            }
        }
    }

    .website-tabs-wrapper {
        .tab-image-content {
            .website-dropshadow {
                margin-top: 30px;
            }
            .right-side-image {
                img {
                    height: 350px !important;
                }
            }
        }
    }

    .typography-wrapper {
        .typography-inner-wrapper {
            .typography-family-info {
                .alphabet-font-wrapper {
                    flex-direction: column;
                    align-items: start !important;
                }
            }
        }
    }
}

@media (max-width: 417px) {
    .folio-logo-wrapper{
        &.ahr-logo-wrapper {
            ul {
                li {
                    padding: 0 20px;
                    &:nth-child(3) {
                        padding: 0 44px;
                    }
                }
            }
        }
    }

    .typography-wrapper {
        .typography-inner-wrapper {
            .typography-font-info {
                .typography-font {
                    flex-direction: column;
                    align-items: start !important;
                    span {
                        display: block;
                        margin-bottom: 15px;
                    }
                    h1 {
                        margin-left: 0;
                    }
                    h2 {
                        margin-left: 0;
                    }
                    h3 {
                        margin-left: 0;
                    }
                    h4 {
                        margin-left: 0;
                    }
                    h5 {
                        margin-left: 0;
                    }
                    h6 {
                        margin-left: 0;
                    }
                }
            }
        }
    }
}

@media (max-width: 380px){

    h2 {
        font-size: 36px;
    }
    header {
        .menu-image {
            img {
                width: 139px;
            }
        }
    }

    .tab-content {
        padding: 0 15px;
    }

    .atmosphere-banner{
        &::before {
            width: 100%;
            height: 40%;
            top: 18%;
            background-size: 253px;
            background-position: 120px;
        }
        .atmosophere-banner-text {
            .atmosphere-inner-heading {
                font-size: 35px;
            }
            h2 {
                font-size: 35px; 
            }
        }
    }

    .menu {
        .digitech-nav {
            .nav-list-wrapper {
                li {
                    .custom-dropdown {
                        .dropdown-menu {
                            .dropdown-inner-item {
                                .dropdown-heading {
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .custom-dropdown {
        .dropdown-menu {
            width: auto;
            .left-box {
                margin-bottom: 10px;
            }
        }
    }
    .difference-design {
        .ResizePanel-module_ResizeContentHorizontal__1gGbA {
            max-width: 280px;
        }
    }

    .difference-design {
        height: 296px !important;
        padding: 30px 0 60px;
        &::before {
            top: 29px;
        }
        .ResizePanel-module_ResizeContentHorizontal__1gGbA {
            max-width: 329px;
        }

        .ResizePanel-module_ResizeBarHorizontal__3TBZ5 {
            height: 236px;
            margin-top: -15px !important;
        }
    }

    .about-atmosphere {
        padding: 40px 0;
    }

    .service-card-inner-wrapper {
        .row {
            .col-xl-3 {
                &:nth-child(2) {
                    margin-top: 0;
                }
                &:nth-child(4) {
                    margin-top: 0;
                }
            }
        }
    }

    .case-study-slider {
        .slick-slider {
            padding-left: 0;
        }
    }

    .case-study-slider {
        .slick-prev {
            left: 3px;
        }
        .slick-next {
            right: 3px;
        }
        .case-study-text {
            padding: 20px 0;
            margin-top: 0;
        }

        .case-study-image {
            margin-top: 20px;
        }

        .slick-dots {
            bottom: -20px !important;
        }
    }

    .service-accordion-wrapper {
        .accordion-item {
            .accordion-header {
                .accordion-button {
                    font-size: 18px;
                    padding: 20px;
                }
            }

            .accordion-collapse {
                .accordion-body {
                    padding-left: 20px;
                }
            }
        }
    }

    .overlapping-wrapper {
        height:700px !important;
        .page{
            &.brand-Overlapping-wrapper {
                padding: 90px 30px 60px;
                .elevate-brand-wrapper {
                    padding: 0 !important;
                }
                &.overlap-box-third {
                    .offer-deals-wrapper {
                        .offer-text-wrapper {
                            h2 {
                                font-size: 26px;
                                padding-right: 0;
                            }
                        }
                    }
                }
            }

            &.overlap-box-first {
                .offer-deals-wrapper {
                    padding-bottom: 120px;
                } 
            }

            &.overlap-box-second  {
                .offer-deals-wrapper {
                    padding-bottom: 90px;
                } 
            }
            .offer-deals-wrapper {
                .offer-text-wrapper {
                    h2 {
                        font-size: 26px;
                        padding-right: 0;
                    }
                }
            }  
        }
    } 

    .uiux-process-boxes {
        .process-item-wrapper {
            &.active {
                .process-img {
                    width: 90px;
                    height: 90px;
                    margin: 0 auto 30px;
                }
                .process-content {
                    h4 {
                        margin-bottom: 20px !important; 
                    }
                }
            }
        }
    }

    .pricing-table-wrapper {
        .package-table-headings {
            width: 150px;
        }
        .pricing-inner-table {
            white-space: normal;
        }
        .pricing-data-inner {
            width: calc(100% - 150px);
        }
        .site-feature-heading {
            h6 { 
                font-size: 14px;
            }
        }    
    }

    .payment-accepted-method {
        .payment-accepted-wrapper {
            flex-wrap: wrap;
            .payment-image {
                margin-bottom: 15px;
            }
        }
    }

    .typography-wrapper {
        .typography-inner-wrapper {
            &.ahr-typography-wrapper {
                .typography-family-info {
                    .alphabets-wrapper {    
                        padding-right: 0;
                        h3 {
                            word-break: break-all;
                        }
                    }
                    &.typography-family-right {
                        .alphabets-wrapper {
                            padding-right: 0;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 347px) {
    .uiux-process-boxes {
        .process-item-wrapper {
            &.active {
                height: 580px !important;
            }
            .process-number {
                margin-right: 15px;
            }
        }
    }
    .ozen-inner-icons {
        display: flex;
        justify-content: space-between;
    }
}

@media (max-width:  331px) {
    .process-accordion-wrapper {
        .accordion {
            .accordion-item {
                .accordion-header {
                    .accordion-button {
                        &:not(.collapsed) {
                            font-size: 24px;
                        }
                    }
                }
                .accordion-collapse {
                    .accordion-body {
                        .accordion-number  {
                            margin-right: 20px;
                            span {
                                font-size: 35px;
                            }
                        }
                    }
                }
            }
        }
    }

    
}

@media (max-width: 321px) {
    h1 {
        font-size: 48px;
    }

    .digitech-hero-banner {
        .banner-paragrapgh {
            padding: 0;
        }
    }

    .typography-wrapper {
        .typography-inner-wrapper {
            padding: 30px;
            .typography-family-info {
                .alphabet-font-wrapper {
                    h1 {
                        margin-right: 00px;
                        font-size: 80px;
                    }
                    span {
                        font-size: 20px;
                    }
                }
                .alphabets-wrapper {
                    h2 {
                        font-size: 24px;
                    }
                }
                        
            }
            .typography-font-info {
                .typography-font {
                    margin: 30px 0;
                    h1 {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    
}