:root{
    --theme-font: 'Montserrat', sans-serif;
    --heading-font: 'Playfair Display', serif;
    --raleway-font: "Raleway", sans-serif;
    --primary-color: #263238;
    --secondary-color: #FF8C8C;
    --theme-color: #B3A5DD;
    --background-gradient: linear-gradient(101deg, #FDF7F2 -1.65%, #EDF3FB 97.51%);
    --extra-light-yellow: #FFFBF2;
    --extra-light-orange: #FFF8F8;
    --extra-light-purple:  #F5F7FD;
    --extra-light-blue: #F0FAFF;
    --texet-color: #72C2B6;
    --light-grey: #DEDEDE;
    --extra-light-grey: #FBFBFB;
    --dark-grey: #797979;
    --border-color: rgba(120, 136, 155, 0.30);
    --yellow: #EDC060;
    --footer-backgrund: #FCFCFC;
    --copyright-footer: #CBCBCB;
    --case-study-bg: rgba(110, 154, 149, 0.14);
    --case-study-color: #6E9A95;
    --atmosphere-bg: #EAF1F0;
    --features-border: #92A5A3;
    --features-text-color: #78889B;
    --result-btn-bg: #6D9A95;
    --result-card-bg: #E6F5F4;
    --result-card-second: #BADCD8;
    --result-card-third: #7EACA5;
    --result-card-four: #48605D;
    --blue: #5FB2FF;
    --solfin-green: #88F231;
    --kludstory-green: #38A979;
    --yellow: #F9A62B;
    --purple: #9F80D6;
    --raheja: #4DC1DA;
    --folio-border-one: #EDA247;
    --folio-border-two: #CF9C2C;
    --folio-border-three: #239195;
    --folio-border-four: #AA9DD2;
    --folio-border-five: #91BC9A;
    --folio-border-six: #F29A6D;
    --folio-border-seven: #B2D25A;
    --folio-border-eight: #98BDD7;
    --folio-border-nine: #9BC4DF;
    --folio-border-ten: #9DB7B4;
    --folio-border-eleven: #6D82AE;
    --folio-border-twelve: #8194A4;
    --folio-border-thirteen: #8FC0B6;
    --folio-border-fourteen: #CCC7BE;
    --folio-border-fifteen: #EB9B9B;
    --folio-border-sixteen: #676D7F;
    --folio-border-seventeen: #7ABEDC;
    --light-green: #EEF5E8; 
    --solfin-bg-one: #F9F9F9;
    --solfin-bg-two: rgba(136, 242, 48, 0.40);
    --solfin-bg-three: #88F230;
    --solfin-bg-four: #161616;
    --ozen-small-heading: #798C9B;
    --ozen-background: #F8F8F8;
    --ozen-color-one: #323F48;
    --ozen-color-two: #031D2E;
    --orange: #FF7043;
    --light-orange: #FCF2F1;
    --ntt-bg: #6D82AE;
    --orange-extra-light: #FFEDED;
    --extra-dark-orange: #AE5E5E;
    --game-store-theme: #1C2C54;
    --game-store-green: #ACFC69;
    --game-store-grey: rgba(217, 216, 214, 0.20);
    --extra-dark-blue: #08163A;
    --3i-bg: #1FA49A;
    --Comet-bg: #F4F5F6;
    --ecommerce-banner: rgba(244, 200, 121, 0.10);
    --ecommerce-color: #F9C059;
    --game-store-case-study: #C4C9D7;
    --hotel-resort-case-study: #C0D1CF;
    --ozen-case-study-bg: #A6B9C8; 
    --solfin-case-study-bg: #CEE891;
    --ecommerce-hover-bg: #F4C879;
    --mob-development-color: #6171FF;
    --purple-light-bg: rgba(179, 165, 221, 0.10);
    --mob-extra-light-purple: #F8F3FF;
    --theme-hover: #8577B0;
    --accordion-border: #EAEAEA;
    --responsive-design-bg: #F5F5F5;
    --atmosphere-shadow: rgba(59, 112, 106, 0.20);
    --solfin-shadow: #A1C583;
    --gamestore-shadow: #ADB3C2;
    --light-golden-card: #F6E8C6;
    --light-green-card: #CCE0CC;
    --extra-light-green-card: #DCECE8;
    --marketing-bg-one: #8CBAC3;
    --marketing-bg-two: #4E93A3;
    --statistics-cards-bg : #70B0BF;
    --statistics-cards-two: #2A4D6B;
    --brand-elevate-one: #E3F4E7;
    --brand-elevate-two: #FDF1CF;
    --brand-elevate-three: #E4E9F0;
    --ozen-marketing-card: #303E49;
    --ozen-shadow: rgba(121, 140, 155, 0.30);
    --border-light-grey: #DADADA;
    --kanifushi-bg: #D0BEB1;
    --kanishi-dark-bg: #B07C55;
    --showcase-extra-light-purple: #C5B9DE;
    --showcase-raaya-color: #9ED2D6;
    --showcase-3ischools-color: #A1C7CA;
    --comet-slide-one-color: #E1D0C3;
    --comet-slide-two-color: #9AC2CB;
    --comet-slide-three-color: #E3D4B7;
    --atmosphere-color-one: #2D5E59;
    --atmosphere-color-two: #FFFBF4;
    --atmosphere-color-three: #D2AE5F;
    --atmosphere-color-four: #E7C16D;
    --atmosphere-color-five: #606060;
    --atmosphere-color-six: #333333;
    --healthism-btn: #2CAA8A;
    --pale-green: #A9D1C5;
    --off-white: #FDFAF7;
    --ozen-charcoal: #323E48;
    --ozen-timberwolf: #D9D8D6;
    --ozen-rich-black: #031C2D;
    --ozen-ecru: #D1AD6E;
    --ozen-bettleship-grey: #9C9994;
    --month-text: #A6ACAF;
    --folio-banner: #f2f2f2;
    --shadow-color: rgba(196, 210, 209, 0.2);
    --ozen-website-background: #BBCBD8;
    --ozen-gery-bg: #9C9894;
    --atmosphere-living-bg: #F4F1EA;
    --ahr-folio-bg: #E7ECEA;
    --ahr-moonstone-color: #549DAE;
    --ahr-chamoisee-color: #B07C56;
    --atmosphere-living-website-color: #98ACAA;
    --atmosphere-living-light-bg: #F9F6EF;
    --al-ui-sheet-bg: #D7C399;
    --games-stores-bg: #F2F3F8;
    --game-stores-green: #ACFC69;
    --game-stores-light-grey: #F9F9F9;
    --game-stores-shadow : #818FB6;
    --poppins-font: "Poppins", sans-serif;
}
::selection{
    background-color: var(--theme-color);
    color: var(--bs-white);
}
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
h1, h2, h3, h4, h5, h6{
    font-family: var(--heading-font);
}
h1{
    font-size: 80px;
    font-weight: 700;
}
h2{
    font-size: 60px;
}
h3 {
    font-size: 32px;
}
p{
    line-height: 30px;
    color: var(--paragraph);
}
@mixin transition($x...) {
    -webkit-transition: $x;
    -moz-transition: $x;
    -ms-transition: $x;
    -o-transition: $x;
    transition: $x;
}

.smooth {
    @include transition(all 400ms ease-in-out 0s);
}

@font-face {
    font-family: "Georgia";
    src: url("../../../public/assets/font/georgia.woff") format('woff');
}

@font-face {
    font-family: "Arial";
    src: url("../../../public/assets/font/arial.woff2") format('woff');
}

@font-face {
    font-family: "DunbarTall";
    src: url("../../../public/assets/font/DunbarTall-Regular.woff2") format('woff');
}

body{
    font-family: var(--theme-font);
    color: var(--primary-color);
}

.custom-container-fluid {
    padding: 0 112px;
}


// start header
header{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 99;
    padding: 40px 0;
    @extend .smooth;

    .menu-image {
        left: 0;
        top: 0;
        margin-top: 22px;
        margin-left: 112px;
        position: absolute;
    }

    .right-top-header {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .btn.theme-btn{
            &:hover, &:focus, &:not(:disabled):not(.disabled):active{
                background-color: transparent;
            }
        }
    }

    .toggle-button{
        span{
            border: none;
            height: 5px;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            cursor: pointer;
        }
    }

    &.sticky{
        padding: 0;
        .menu-image {
            display: none;
        }
        .digitech-nav-wrapper {
            display: none !important;
        }
    }

    &.header-v{
        background-color: transparent;
    }
}


// start navigation
// Navigation
.menu{
    position: fixed;
    right: 0;
    top: 0;
    margin-right: 20px;
    margin-top: 22px;
    z-index: 99;
    .digitech-nav {
        visibility: hidden;
        opacity: 0;
        display: flex;
        height: 100vh;
        width: 100%;
        max-width: 1320px;
        position: fixed;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 0;
        align-items: center;
        @extend .smooth;

        .nav-inner{
            @extend .smooth;
            transform: translateY(100px);
            -o-transform: translateY(100px);
            -ms-transform: translateY(100px);
            -moz-transform: translateY(100px);
            -webkit-transform: translateY(100px);
            opacity: 0;
            transition-delay: 0.5s;
            -o-transition-delay: 0.5s;
            -ms-transition-delay: 0.5s;
            -moz-transition-delay: 0.5s;
            -webkit-transition-delay: 0.5s;
        }
        .nav-list-wrapper {
            margin: 0;
            padding: 0;
            display: block !important;
            position: relative;

            li{
                margin-bottom: 40px;
                list-style-type: none;

                .nav-link{
                    padding: 12px 0;
                    color: #383c44;
                    font-weight: 500;
                    font-size: 28px;
                    padding: 0 55px 0 20px;
                    position: relative;
                    &::after {
                        border: 0;
                        position: absolute;
                        background-repeat: no-repeat;
                        background-image: url(../images/down-arrow.svg);
                        height: 15px;
                        top: 36%;
                        background-position: center;
                        width: 20px;
                        background-size: 14px;
                        transform: rotate(-89deg);
                    }

                    &:hover, &:focus{
                        text-decoration: none;
                    }

                }
                
                &:nth-child(3) {
                    .custom-dropdown { 
                        .dropdown-menu {
                            .dropdown-inner-item  {
                                .dropdown-heading {
                                    max-width: 135px;
                                }
                            }
                        }
                    }    
                }
                
                &:nth-child(4) {
                    .custom-dropdown { 
                        .dropdown-menu {
                            .dropdown-inner-item  {
                                .dropdown-heading {
                                    max-width: 157px;
                                }
                            }
                        }
                    }    
                }

                .custom-dropdown { 
                    .dropdown-menu {
                        padding: 0;
                        width: 966px;
                        box-shadow: none;
                        display: block !important;
                        height: 0;
                        visibility: hidden;
                        opacity: 0;
                        margin-top: 0 !important;
                        margin-left: 290px !important;
                        background-color: transparent;
                        .dropdown-content-wrapper {
                            margin-top: -110px !important;
                            .dropdown-list-wrapper {
                                li {
                                    width: 31%;
                                    list-style-type: none;
                                }
                            }
                        }
                        .dropdown-inner-item  {
                            .dropdown-heading {
                                font-size: 20px;
                                width: 100%;
                                max-width: 128px;
                            }
                        }
                        &.show {
                            height: auto;
                            visibility: visible;
                            opacity: 1;
                        }
                    }
                    &.show {
                        transition-duration: 700ms;
                        .nav-link {
                            font-size: 36px;
                            font-weight: 500;
                            &::after {
                                width: 20%;
                                top: 41%;
                                background-size: 36px;
                                transform: rotate(0deg);
                                background-image: url(../images/long-arrow-orange.svg);
                            }
                        }
                    }
                }
            }
        }
        .digitech-social-icons {
            padding: 0 25px;
            margin: 50px 0 0;

            h6{
                color: #383c44;
                font-weight: 500;
                opacity: 0.8;
            }

            .social-icons{

                a{
                    padding: 0 10px;
                    display: inline-block;
                    @extend .smooth;

                    img{
                        width: 18px;
                        opacity: 0.8;
                        @extend .smooth;
                    }

                    &:first-child{
                        img{
                            width: 10px;
                        }
                    }

                    &:last-child{
                        img{
                            width: 25px;
                        }
                    }

                    &:hover, &:focus{
                        text-decoration: none;

                        img{
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}

.digitech-nav-wrapper {
    .navbar {
        .navbar-collapse {
            .navbar-nav {
                .nav-item {
                    .nav-link {
                        padding: 0 25px;
                        color: var(--primary-color);
                    }
                }
            }
        }
    }
}

.enterprise-application-wrapper {
    .enterprise-application-left {
        width: 100%;
        max-width: 907px;
    }  
    .enterprise-application-right {
        width: calc(100% - 907px);
        padding-left: 40px;
        .software-feature-img {
            img {
                filter: drop-shadow(10px 10px 16px rgba(0, 0, 0, 0.2));
            }
        }
    }
}

.consult-info-wrapper {
    h6 {
        font-weight: 700;
        font-family: var(--theme-font);
    }
}

.nav-tgl {
    cursor: pointer;
    z-index: 100;
    border: none;
    height: 56px;
    width: 56px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.663);
    position: relative;
    border-radius: 50px;
    backdrop-filter: blur(20px);
    // making the dividers
    > span {
        // the second divider
        display: inline-block;
        position: relative;
        // the first & the third dividers
        &::after, &::before{
        display: inline-block;
            position: absolute;
            content: "";
            height: 2px;
            width: 27px;
            left: 0;
            right: 0;
            border-radius: 1px;
            margin: 5px -13px 0;
            background-color:var(--dark-grey);
            // for the hover state
            transition: all 200ms;
        }
        &::before {
            top: -11px;
        }
        
        &::after {
            top: 0;
        }
    }
    
    // ofcorse we should find a replacement for the focus state but it's not our topic
    &:focus {outline: none}
    
    &:hover > span:after, &:hover > span:before {
        width: 27px;
        left: 0;
    }
}

.menu {
    .digitech-nav {
        &::before{
            display: block;
            position: fixed;
            top: 0;
            left: 0;
            content: '';
            width: 100vw;
            height: 100vh;
            background-color: rgba(255,255,255,0.8);
            transition: all 500ms ease-in-out;
            backdrop-filter: blur(15px);
            clip-path: circle(30px at calc(100% - 35px) 20px);
            visibility: hidden;
        }
    }
}

.opened-menu {
    overflow-y: hidden;
    .menu {
        .digitech-nav {
            opacity: 1;
            visibility: visible;
            transition-duration: 1000ms;
            .nav-inner {
                transform: translateY(0);
                -o-transform: translateY(0);
                -ms-transform: translateY(0);
                -moz-transform: translateY(0);
                -webkit-transform: translateY(0);
                opacity: 1;
            }
            &::before {
                visibility: visible;
                clip-path: circle(100%); /* Change clip-path to circle(100%) */
            }
        }
        .nav-tgl > span {
            height: 0;
            &::after, &::before {
                top: -6px;
                left: 0;
                width: 27px;
                background-color: #383c44;
            }
            &::after {
                transform: rotate(-45deg);
            }
            &::before {
                transform: rotate(45deg);
            }
        }
    }
}
// End opened menu on body class
// end navigation



// dropdown
.custom-dropdown {
    .nav-link {
        &::after {
            border: 0;
            position: absolute;
            background-repeat: no-repeat;
            background-image: url(../images/down-arrow.svg);
            height: 15px;
            top: 25%;
            background-position: center;
            width: 20px;
            background-size: 14px;
        }
    }
    .dropdown-menu {
        width: 764px;
        padding: 50px 34px;
        border-radius: 0;
        margin-top: 30px !important;
        display: none;
        border: 0;
        transition: all 0.3s ease-in;
        box-shadow: 0px 0px 13px -7px var(--purple);
        &.show {
            display: block;
        }
        .dropdown-list-wrapper {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            padding-left: 0;
            li {
                width: 47%;
                list-style-type: none;
                margin-right: 15px;
            }
        }
        .left-box {
            width: 16px;
            height: 16px;
            background-color: var(--case-study-color);
            img {
                display: none;
            }
        }
        
        .rheja-bg {
            width: 16px;
            height: 16px;
            background-color: var(--raheja);
            img {
                display: none;
            }
        }
        .dropdown-link{
            display: block;
            text-decoration: none;
            margin-top: 0 !important;
            color: var(--primary-color);
            margin-bottom: 20px !important;
            &:hover {
                background-color: transparent;
            }
            .dropdown-inner-item {
                .dropdown-heading {
                    font-size: 15px;
                }
                p {
                    font-size: 14px;
                    margin-top: 10px;
                    line-height: 24px;
                }
            }
        }
    }

    .technology-used {
        margin-top: 20px;
        display: flex;
        align-items: center;
        .dropdown-technologies-img {
            display: flex;
            align-items: center;
        }
        span {
            display: block;
            margin-left: 15px;
            &.responsive-tech-img {
                margin-left: 0;
            }
            img {
                width: 30px;
            }
        }
        .figma-img {
            img {
                width: 30px;
            }
        }
    }
}

// banner
.digitech-hero-banner {
    position: relative;
    padding-bottom: 110px;
    background: var(--background-gradient);
    border-radius: 0px 0px 60px 60px;
    .banner-paragrapgh {
        font-size: 24px;
        margin-top: 20px;
        line-height: 38px;
        padding: 0 15px;
    }
    .hero-responsive-banner  {
        display: none;
    }

    .digitech-desktop-banner {
        width: 100%;
        max-width: 1320px;
        margin: 0 auto;
    }
}



.banner-heading {
    padding-bottom: 10px;
    position: relative;
    &:before {
        content: '';
        width: 120px;
        height: 2px;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        border-radius: 20px;
        background-color: var(--features-text-color);
        position: absolute;
    }
}

.digitech-services-card {
    border: 0;
    height: 100%;
    width: 100%;
    padding: 45px 20px 30px;
    border-radius: 0;
    transform: translateY(0);
    transition: all 0.3s ease-in;
    background-color: var(--bs-white);
    box-shadow: 10px 10px 10px 0px rgba(143, 143, 143, 0.10);
    &:hover {
        transform: translateY(-10px);
        background-color: var(--theme-color);
        .card-img {
            filter: brightness(12);
        }
        .card-title {
            opacity: 0;
            visibility: hidden;
            height: 0;
            color: var(--bs-white)
        }
        
        .get-started-btn {
            opacity: 1;
            height: auto;
            margin-top: 0;
            visibility: visible;
            color: var(--bs-white);
        }
    }
    
    
    .card-img {
        width: 50px;
        height: 50px;
        margin-bottom: 30px !important;
    }
    .card-title {
        opacity: 1;
        height: auto;
        visibility: visible;
        font-size: 16px;
    }
    .get-started-btn {
        opacity: 0;
        height: 0;
        margin-top: -40px;
        visibility: hidden;
        color: var(--bs-white);
    }
    .digitech-services-card {
        border: 0;
        background-color: transparent;
    }
}

.header-space {
    padding-top: 200px !important;
}

.brand-color {
    color: var(--secondary-color);
}

// digitech-services-wrapper


.service-card-heading {
    margin-bottom: 60px;
}

.digitech-services-wrapper {
    padding: 100px 0 50px;
    overflow: hidden;
    position: relative;
    .row {
        .col-lg-3{
            &:nth-child(even) {
                margin-top: 50px;
            }
        }
    }
}


.digitech-card {
    height: 580px;
    display: block;
    border-radius: 0;
    padding: 60px 40px;
    transition: all 0.3s ease-in;
    background-color: var(--extra-light-yellow);
    &.edtech-card {
        background-color: var( --extra-light-orange);
    }
    &.ecommerce-card {
        background-color: var(--extra-light-purple);
    }
    &.healthcare-card {
        background-color: var(--extra-light-blue);
    }
    &:hover {
        background-color: var(--theme-color);
        .card-header {
            .card-img {
                img {
                    filter: brightness(12);
                }
            }
            h3 {
                color: var(--bs-white);
            }
        }
        .card-body {
            p {
                color: var(--bs-white);
            }
        }
        .btn-wrapper {
            opacity: 1;
            visibility: visible;
        }
    }
    .card-header {
        h3 {
            margin: 60px 0 40px;
            font-family: var(--theme-font);
        }
    }
    .btn-wrapper{
        opacity: 0;
        margin-top: 60px;
        transform: translateY(0);
        transition: all 0.3s ease-in;
        visibility: hidden;
        .learn-more-btn {
            border: 0;
            color: var(--bs-white);
            letter-spacing: 2.5px;
        }
    }
}

.slick-dots {
    li {
        button{
            &::before {
                opacity: 1 !important;
            }
        } 
    }
}



// case study slider

.case-study-slider{
    position: relative;
    .case-study-image  {
        background-color: var(--game-store-case-study);
        &.hotel-resort-case-study {
            background-color: var(--hotel-resort-case-study);
        }
        &.ozen-case-study {
            background-color: var(--ozen-case-study-bg);
        }
        &.solfin-case-study-background {
            background-color: var(--solfin-case-study-bg);
        }
        img {
            width: 80%;
            margin: 0 auto;
        }

        &.texet-image {
            &::before {
                background-color: rgba(114, 197, 184, 0.20);
            }
        }

        &.solfin-light-bg {
            &::before {
                background-color: rgba(136, 242, 49, 0.20);
            }
        }

        &.atmosphere-image {
            &::before {
                background-color: rgba(109, 154, 149, 0.20);
            }
        }
    }

    .slick-slide {
        width: 100%;
        padding: 0 10px;
    }

    .case-study-text {
        padding: 40px 0 40px;
        .case-study-heading {
            font-size: 32px;
            padding-right: 60px;
            text-decoration: none;
            color: var(--primary-color);
            font-family: var(--theme-font);
            &:hover {
                transition: all 0.2s ease-in;
                text-decoration: underline;
                color: var(--secondary-color);
            }
        }
        .smallheading {
            margin: 30px 0;
            display: inline-block;
            font-size: 14px;
        }
    }

    
    .slick-next {
        &::before {
            content: url('../images/next.svg') !important;
        }
    }

    .slick-prev, .slick-next {
        top: 35%;
    }

    .slick-prev {
        &::before {
            content: url('../images/prev.svg')  !important;
        }
    }
} 

.theme-btn {
    text-decoration: none;
    color: var(--primary-color);
    transition: all 0.2s ease-in;
    &:hover {
        color: var(--secondary-color);
    }
}




.slick-dots {
    li {
        margin: 0 21px !important;
        button {
            width: 0 !important;
            height: 0 !important;
            &::before {
                width: 47px !important;
                height: 2px !important;
                font-size: 0px !important;
                background-color: var(--light-grey) !important;
            }
        }
        &.slick-active {
            button {
                &::before {
                    background-color: var(--secondary-color) !important;
                }
            }
        }
    }
}

// consultUs

.consult-wrapper {
    padding: 60px;
    background-color: var(--extra-light-grey);
}

.consult-list {
    li {
        list-style-type: none;
    }
}

.consult-btn {
    padding: 13px 25px;
    color: var(--bs-white);
    text-transform: uppercase;
    border-radius: 0;
    background-color: var(--secondary-color);
    &:hover {
        color: var(--bs-white);
        background-color: var(--primary-color);
    }

}

.theme-font-family {
    font-family: var(--theme-font);
    letter-spacing: 1.6px;
    .linkedin-link {
        color: var(--primary-color);
    }
}

.work-slide {
    .slick-list {
        padding-top: 60px !important;
    }
    .slick-slide {  
        padding: 0 50px;
        transform: translateY(0);
        transition: all 0.3s ease-in;
        &.slick-active {
            .case-study-text{
                .work-content {
                    display: flex;
                    margin-top: 20px;
                    justify-content: space-between;
                }
            }
            &.slick-center {
                transform: translateY(-60px);
            }
        }
        .case-study-text {
            .work-image {
                img {
                    width: 100%;
                    &.work-responsive-img {
                        display: none;
                    }
                }
            }
            .work-content {
                display: none;
                transition: all 0.2s ease-in;
                .work-btn-wrapper {
                    width: 100%;
                    max-width: 150px;
                    text-align: end;
                }
            }
        }
    }

    .slick-prev {
        top: 30%;
        margin-left: 274px;
        z-index: 2;
        &::before {
            width: 70px;
            height: 70px;
            position: absolute;
            background-repeat: no-repeat;
            background-position: center;
            color: transparent;
            background-image: url(../images/large-arrow-left.svg);
        }
    }

    .slick-next{
        top: 30%;
        margin-right: 340px;
        z-index: 2;
        &::before {
            width: 70px;
            height: 70px;
            position: absolute;
            background-repeat: no-repeat;
            background-position: center;
            color: transparent;
            background-image: url(../images/work-next.svg);
        } 
    }
}
.work-heading {
    letter-spacing: 0;
}
.read-more-btn {
    color: var(--dark-grey)
}

// testimonial

.testimonal-slider {
    .slick-slide {
        padding: 0 10px;
    }
    .slick-dots {
        bottom: -74px !important;
    }
    .testimonial-wrapper {
        padding: 40px;
        z-index: 9;
        position: relative;
        border: 1px solid var(--border-color);
        &::before {
            content: '';
            top: 30px;
            right: 30px;
            width: 20%;
            height: 30%;
            position: absolute;
            background-repeat: no-repeat;
            background-position: right top;
            background-size: 100px;
            background-image: url(../images/quotes.svg);
        }

        .home-testimonial-heading {
            padding-right: 110px;
        }

        &.testimonial-inner-page {
            &::before {
                background-image: url(../images/quotes-purple.svg);
            }
        }
        .testimonial-name {
            h5 {
                span {
                    font-size: 14px;
                    margin-left: 15px;
                }
            }
        }
    }

    .slick-next {
        &::before {
            content: url('../images/next.svg') !important;
        }
    }


    .slick-prev {
        &::before {
            content: url('../images/prev.svg')  !important;
        }
    }
}


.star {
    span {
        margin-right: 5px;
        color: var(--yellow);
    }
}

// We Work On
.software-wrapper {
    padding: 30px;
    height: 100%;
    border: 1px solid var(--border-color);
    .we-work-img {
        width: 80px;
        height: 80px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.software-main-wrapper {
    padding-bottom: 90px;
}

// footer

footer {
    position: relative;
    padding: 60px 0 0;
    background-color: var(--footer-backgrund);
    .footer-links {
        ul {
            li {
                a {
                    margin-left: 0;
                    color: var(--primary-color);
                    transition: all 0.3s ease-in;
                    &:hover {
                        margin-left: 5px;
                        color: var(--secondary-color);
                    }
                }
            }
        }
    }
        .social-media-wrapper {
            h5 {
                margin-top: 75px;
                margin-bottom: 30px !important;
            }
            .social-media-links {
                li {
                    margin-top: 15px;
                    margin-right: 15px;
                    a {
                        width: 40px;
                        height: 40px;
                        display: flex;
                        padding: 10px;
                        color: var(--bs-white);
                        align-items: center;
                        justify-content: center;
                        transition: all 0.2s ease-in;
                        background-color: var(--primary-color);
                        &:hover {
                            background-color: var(--secondary-color);
                        }
                    }
                }
            }
        }
    .copyright {
        padding: 40px 0;
        margin-top: 60px;
        border-top: 1px solid var(--copyright-footer);
        span {
            font-size: 14px;
        }
    }

    .footer-email {
        text-decoration: none;
        color: var(--primary-color);
    }
}


.custom-input {
    &:hover, &:focus {
        box-shadow: none;
        border-color: var(--secondary-color);
    }
}


.back-top-btn {
    bottom: 90px;
    right: 0px;
    transform: rotateZ(269deg);
    position: absolute;
    .footer-btn {
        font-size: 18px;
    }
}


// consult us page

.digitech-consult-wrapper {
    padding-bottom: 100px;
    .consult-form {
        padding-right: 150px;
        h2 {
            font-size: 50px;
        }
    }
    
    .form-check {
        .form-check-input {
            margin-right: 10px;
        }
    }

    .form-check-input[type=checkbox] {  
        border-radius: 0;
        margin-right: 8px;
    }
}

.consult-inner-wrapper {
    display: flex;
    padding-left: 70px;
    .consult-left-pannel {
        max-width: 868px;
        margin-right: 80px;
    }
    .consult-right-pannel {
        max-width:  calc(100% - 868px);
    }
}

.PhoneInput {
    width: 100%;
    position: relative;
    flex-direction: row-reverse;
    .PhoneInputCountry {
        padding: 0 !important;
        margin: 0 !important;
        position: absolute;
        right: 20px;
        top: 0;
        left: initial;
        bottom: 0;
    }
    .PhoneInputInput {
        color: var(--dark-grey);
        padding: 13px 100px 13px 25px;
        border: 1px solid var(--bs-border-color) !important;
        &:focus {
            box-shadow: none;
            border: 1px solid var(--secondary-color) !important;
        }
        &:focus-visible {
            outline: 0 !important;
        }
    }
}

.consult-testimonial-wrapper {
    padding: 100px 60px;
    background-color: var(--extra-light-yellow);
    .custult-testimonial-content {
        .user-img {
            width: 134px;
            height: 134px;
            img {
                width: 100%;
            }
        }
    }
}

.user-review-content {
    p {
        font-size: 18px;
    }
}

// case study

.atmosphere-banner {
    position: relative;
    overflow: hidden;
    padding-bottom: 90px;
    background: var(--case-study-bg);
    &.game-store-banner-background {
        background: var(--ozen-background);
    }
    &.solfin-banner-background {
        background: var(--solfin-bg-one);
    }
    .atmosophere-banner-text {
        position: relative;
        .atmosphere-small-heading {
            font-size: 24px;
            font-weight: 600;
            color: var(--case-study-color);
        }
        h2 {
            font-size: 50px;
            padding-right: 80px;
            font-weight: 700;
        }
    }
    .banner-img {
        position: relative;
        img {
            position: relative;
            z-index: 1;
            width: 100%;
        }
        &::before {
            content: '';
            position: absolute;
            width: 100%;
            right: 0;
            top: 0px;
            height: 100%;
            background-image: url(../images/bg-shape.webp);
            background-repeat: no-repeat;
            background-position: right;
            background-size: contain;
        }
        &.solfin-wrapper {
            &::before {
                top: -50px;
                height: 100%;
                background-image: url(../images/solfin-bg.webp);
                background-repeat: no-repeat;
            }
    
        }
    
        &.ozen-wrapper {
            &::before {
                top: -50px;
                height: 100%;
                background-image: url(../images/ozen-bg.webp);
                background-repeat: no-repeat;
            }
        }
        &.game-wrapper {
            &::before {
                top: -50px;
                height: 100%;
                background-image: url(../images/game-store-shape.webp);
                background-repeat: no-repeat;
            }
        }
    }
    
}

.brand-heading-color {
    color: var(--solfin-green) !important;
}

.about-atmosphere {
    padding-top: 100px;
    padding-bottom: 50px;
}


.folio-banner {
    overflow: hidden;
    padding-bottom: 160px;
    border-radius: 0 0 60px 60px;
    background: var(--folio-banner);
    &.atmosphere-living-background {
        background-color: var(--atmosphere-living-bg);
    }
    &.ahr-background {
        background-color: var(--ahr-folio-bg);
    }
    &.game-stores-background {
        background-color: var(--games-stores-bg);
    }
}


.testimonial-bottom-space {
    padding-top: 0 !important;
}

.atmosphere-heading {
    h2 {
        font-weight: 700;
        margin-bottom: 60px;
    }
}

.about-left-text {
    padding-right: 30px;
    .atmosphere-inner-heading {
        font-size: 40px;
        margin-top: 20px;
        font-weight: 600;
        padding-right: 98px;
        font-family: var(--theme-font);
    }
}

.atmosphere-space {
    margin-top: 90px;
}

.about-list-wrapper {
    .list-group {
        .list-group-item {
            border: 0;
        }
        &:last-child {
            .list-group-item {
                margin-bottom: 0 !important;
            }
        }
    }
}

.right-content {
    padding-left: 120px;
    .about-right-heading {
        font-family: var(--theme-font);
    }
}

.digitech-solution {
    padding-top: 50px;
    padding-bottom: 50px;
}

.digitechtabs {
    margin-top: 60px;
    overflow: hidden;
    align-items: center;
    .rts___tabs___container {
        width: 100%;
        max-width: 530px;
        display: block;
        .rts___tabs{
            width: 100%;
            padding-top: 0;
            display: block;
            scroll-behavior: smooth;
            .rts___tab {
                width: 100%;
                padding: 46px 20px;
                white-space: normal;
                border: 1px solid var(--light-grey) !important;
                border-bottom: 0 !important;
                background-color: transparent;
                transition: all 0.3s ease-in;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                .tab-name {
                    font-size: 20px;
                    font-family: var(--theme-font);
                }
                p {
                    padding-top: 15px;
                    padding-left: 61px;
                    margin-bottom: 0;
                    display: none;
                }
                &:first-child {
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                }
                &:last-child {
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                    border-bottom: 1px solid var(--light-grey) !important;
                }
                .serial-number {
                    opacity: 0;
                    font-size: 36px;
                    visibility: hidden;
                    transform: translateY(-5px);
                }
                &.active {
                    padding-bottom: 90px;
                    color: var(--bs-white);
                    box-shadow: none !important;
                    background-color: var(--case-study-color);
                    border-left: 1px solid var(--case-study-color) !important;
                    border-right: 1px solid var(--case-study-color) !important;
                    .tab-name {
                        font-size: 24px;
                    }
                    .serial-number {
                        opacity: 1;
                        transform: translateY(-5px);
                        visibility: visible;
                        margin-right: 15px;
                        font-family: var(--heading-font);
                    }
                    p {
                        display: block;
                    }
                }
            }
        }

        .rts___nav___btn {
            display: none;
        }
    }
}

.solfin-tabs-wrapper {
    .rts___tabs___container {
        .rts___tabs{
            scroll-behavior: smooth;
            .rts___tab {
                &.active {
                    color: var(--solfin-bg-four);
                    background-color: var(--solfin-bg-three);
                    border-left: 1px solid var(--solfin-bg-three) !important;
                    border-right: 1px solid var(--solfin-bg-three) !important;
                }
            }
        }
    }
}


.ozen-tabs-wrapper {
    .rts___tabs___container {
        .rts___tabs{
            scroll-behavior: smooth;
            .rts___tab {
                &.active {
                    color: var(--bs-white);
                    background-color: var(--ozen-small-heading);
                    border-left: 1px solid var(--ozen-small-heading) !important;
                    border-right: 1px solid var(--ozen-small-heading) !important;
                }
            }
        }
    }
}

.game-tabs-wrapper {
    .rts___tabs___container {
        .rts___tabs{
            scroll-behavior: smooth;
            .rts___tab {
                &.active {
                    color: var(--game-store-theme);
                    background-color: var(--game-store-green);
                    border-left: 1px solid var(--game-store-green) !important;
                    border-right: 1px solid var(--game-store-green) !important;
                }
            }
        }
    }
}

.tab-image-right {
    width: calc(100% - 542px);
}

.right-side-image {
    padding: 50px 30px 30px 70px;  
    img {
        width: 100%;
        filter: drop-shadow(-2px 3px 20px rgba(143, 143, 143, 0.2));
    }
}

.copywritting-para {
    p {
        padding-right: 100px;
    }
}

.atmosphere-design-wrapper {
    padding: 70px;
    display: flex;
    margin-top: 50px;
    background-color: var(--atmosphere-bg);
    .atmosphere-left-pannel {
        width: 35%;
        .left-design {
            img {
                border-radius: 4px;
            }
        }
    }   
    .atmosphere-right-pannel {
        width: 65%;
        margin-left: 67px;
        .right-design {
            img {
                border-radius: 4px;
                filter: drop-shadow(6px 6px 18px var(--atmosphere-shadow));
            }
        }
    }
}


.atmosphere-design {
    img {
        box-shadow: 6px 6px 18px var(--atmosphere-shadow);
    }
}
.gamestore-design {
    img {
        box-shadow: 0px 0px 18px -5px var(--gamestore-shadow);
    }
}
.ozen-design {
    img {
        box-shadow: 6px 6px 18px -5px var(--ozen-shadow);
    }
}
.solfin-design {
    img {
        box-shadow: 0px 0px 18px -5px var(--solfin-shadow);
    }
}

.ozen-showcase-wrapper {
    background-color: rgba(232, 232, 232, 0.40);
}

.solfin-showcase {
    background-color: var(--light-green);
}

.gamestore-showcase {
    background-color: var(--game-store-case-study);
}

.heading-font {
    margin-bottom: 20px;
    font-family: var(--theme-font);
}

.solfin-right {
    padding-left: 40px;
}

.digitech-technology-card {
    padding: 30px 25px;
    height: 100%;
    object-fit: cover;
    .card-img {
        width: 45px;
        height: 100%;
        object-fit: contain;
        &.technology-img {
            width: 33px;
            height: 100%;
            object-fit: contain;
        }
        &.marekting-card-img {
            width: 80px;
        }
    }
    .card-title {
        font-size: 18px;
    }
    &.marketing-cards-wrapper {
        padding: 30px 20px;
    }
    &.marketing-margin {
        margin-top: 25px;
    }
}

.digitech-cards-wrapper {
    margin-top: 91px;
    .row {
        .col-lg-2 {
            &:nth-child(4){
                .digitech-services-card {
                    .card-img {
                        width: 37px;
                    }
                }
            }
        }
    }
}


.digitech-features-card {
    padding: 0;
    border: 0;
    .card-img-wrapper {
        padding: 70px 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 378px;
        border-radius: 0;
        border: 1px solid var(--border-light-grey);
        .card-img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}


.digitech-form-wrapper {
    padding-right: 35px;
    .custom-input {
        &:focus {
            outline: 0;
        }
    }
}

.small-heading {
    font-size: 50px;
}

.subbrand-color {
    color: var(--theme-color);
}

.service-card-space {
    padding-right: 40px;
}

.result-wrapper {
    padding-right: 194px;
    .result-suv-heading {
        margin-top: 100px;
        h3 {
            font-size: 40px;
            font-family: var(--theme-font);
        }
    }
    .result-btn-wrapper {
        margin-top: 60px;
    }
}


.result-btn {
    background-color: var(--result-btn-bg);
}

.result-inner {
    .row {
        .col-lg-6 {
            margin-bottom: 25px;
        }
    }
}

.result-card-wrapper {  
    padding: 35px 30px;
    height: 100%;
    background-color: var(--result-card-bg);

    .card-body {
        display: flex;
        padding: 0;
        align-items: center;
        justify-content: space-between;
    }
    .result-card-img {
        width: 30px;
    }
}

.result-right-wrapper {
    p {
        margin-bottom: 30px;
    }
    .row {
        .col-lg-6 {
            &:nth-child(1) {
                .result-inner-wrapper {
                    background-color: var(--solfin-bg-one) !important;
                    .card-body {
                        .result-card-img {
                            filter: invert(1);
                        }
                    }
                }
                .ozen-result-card {
                    background-color: var(--ozen-background);
                }
                .game-store-result {
                    background-color: var(--game-store-grey);
                }
            }
            &:nth-child(2) {
                .result-card-wrapper {
                    background-color: var(--result-card-second);
                }
                .result-inner-wrapper {
                    background-color: var(--solfin-bg-two);
                }
                .ozen-result-card {
                    background-color: var(--ozen-small-heading);.card-body {
                        .card-title {
                            color: var(--bs-white);
                        }
                            .result-card-img {
                                filter: invert(1);
                            }
                    }
                    .card-text {
                        color: var(--bs-white);
                    }
                }
                .game-store-result {
                    background-color: var(--game-store-green);
                }
            }
            &:nth-child(3) {
                .result-card-wrapper {
                    background-color: var(--result-card-third);
                    .card-body {
                        .card-title {
                            color: var(--bs-white);
                        }
                    }
                    .card-text {
                        color: var(--bs-white);
                    }
                    
                    &.result-inner-wrapper {
                        background-color: var(--solfin-bg-three);
                        .card-body {
                            .card-title {
                                color: var(--primary-color);
                            }
                        }
                        .card-text {
                            color: var(--primary-color);
                        }
                    }
                    &.ozen-result-card {
                        background-color: var(--ozen-color-one);
                    }
                    &.game-store-result {
                        background-color: var(--game-store-theme);
                        .result-card-img {
                            filter: invert(1);
                        }
                    }
                }
            }
            &:nth-child(4) {
                .result-card-wrapper {
                    background-color: var(--result-card-four);
                    .card-body {
                        .card-title {
                            color: var(--bs-white);
                        }
                        .result-card-img {
                            filter: invert(1);
                        }
                    }
                    .card-text {
                        color: var(--bs-white);
                    }
                    &.result-inner-wrapper {
                        background-color: var(--solfin-bg-four);
                        .card-body {
                                img {
                                    filter: invert(0);
                                }
                        }
                    }
                    &.game-store-result {
                        background-color: var(--extra-dark-blue);
                        .card-body {
                            img {
                                filter: invert(0);
                            }
                        }
                    }
                    &.ozen-result-card {
                        background-color: var(--ozen-color-two);
                    }
                }
                
                
            }
        }
    }
}

.quotes-heading {
    padding-bottom: 50px;
}

.quotes-text {
    p {
        font-size: 32px;
        line-height:48px;
        padding-right: 36px;
    }
}


.varu-image {
    margin-top: 55px;
}

.left-panel {
    position: relative;
    &::before {
        content: '';
        top: 0;
        right: 0;
        width: 5px;
        height: 100%;
        position: absolute;
        background-color: var(--result-btn-bg);
    }
}

// digitech folio 
.digitech-folio {
    .folio-card-wrapper {
        .folio-img {
            img {
                width: 100%;
            }
        }
    }
    .row {
        .col-lg-4 {
            &:nth-child(2) {
                .folio-card-wrapper  {
                    .folio-img {
                        &:hover {
                            border: 1px solid var(--folio-border-two);
                            box-shadow: 0 0 12px var(--folio-border-two);
                        }
                    }
                }
            }
            &:nth-child(3) {
                .folio-card-wrapper  {
                    .folio-img {
                        &:hover {
                            border: 1px solid var(--folio-border-three);
                            box-shadow: 0 0 12px var(--folio-border-three);
                        }
                    }
                }
            }
            &:nth-child(4) {
                .folio-card-wrapper  {
                    .folio-img {
                        &:hover {
                            border: 1px solid var(--folio-border-thirteen);
                            box-shadow: 0 0 12px var(--folio-border-thirteen);
                        }
                    }
                }
            }
            &:nth-child(5) {
                .folio-card-wrapper  {
                    .folio-img {
                        &:hover {
                            border: 1px solid var(--folio-border-five);
                            box-shadow: 0 0 12px var(--folio-border-five);
                        }
                    }
                }
            }
            &:nth-child(6) {
                .folio-card-wrapper  {
                    .folio-img {
                        &:hover {
                            border: 1px solid var(--folio-border-eight);
                            box-shadow: 0 0 12px var(--folio-border-eight);
                        }
                    }
                }
            }
            &:nth-child(7) {
                .folio-card-wrapper  {
                    .folio-img {
                        &:hover {
                            border: 1px solid var(--folio-border-four);
                            box-shadow: 0 0 12px var(--folio-border-four);
                        }
                    }
                }
            }
            &:nth-child(8) {
                .folio-card-wrapper  {
                    .folio-img {
                        &:hover {
                            border: 1px solid var(--folio-border-eleven);
                            box-shadow: 0 0 12px var(--folio-border-eleven);
                        }
                    }
                }
            }
            &:nth-child(9) {
                .folio-card-wrapper  {
                    .folio-img {
                        &:hover {
                            border: 1px solid var(--folio-border-six);
                            box-shadow: 0 0 12px var(--folio-border-six);
                        }
                    }
                }
            }
            &:nth-child(10) {
                .folio-card-wrapper  {
                    .folio-img {
                        &:hover {
                            border: 1px solid var(--folio-border-ten);
                            box-shadow: 0 0 12px var(--folio-border-ten);
                        }
                    }
                }
            }
            &:nth-child(11) {
                .folio-card-wrapper  {
                    .folio-img {
                        &:hover {
                            border: 1px solid var(--folio-border-twelve);
                            box-shadow: 0 0 12px var(--folio-border-twelve);
                        }
                    }
                }
            }
            
            &:nth-child(12) {
                .folio-card-wrapper  {
                    .folio-img {
                        &:hover {
                            border: 1px solid var(--folio-border-fourteen);
                            box-shadow: 0 0 12px var(--folio-border-fourteen);
                        }
                    }
                }
            }
            &:nth-child(13) {
                .folio-card-wrapper  {
                    .folio-img {
                        &:hover {
                            border: 1px solid var(--folio-border-two);
                            box-shadow: 0 0 12px var(--folio-border-two);
                        }
                    }
                }
            }
            &:nth-child(14) {
                .folio-card-wrapper  {
                    .folio-img {
                        &:hover {
                            border: 1px solid var(--folio-border-seventeen);
                            box-shadow: 0 0 12px var(--folio-border-seventeen);
                        }
                    }
                }
            }
            &:nth-child(15) {
                .folio-card-wrapper  {
                    .folio-img {
                        &:hover {
                            border: 1px solid var(--folio-border-three);
                            box-shadow: 0 0 12px var(--folio-border-three);
                        }
                    }
                }
            }
            &:nth-child(16) {
                .folio-card-wrapper  {
                    .folio-img {
                        &:hover {
                            border: 1px solid var(--folio-border-twelve);
                            box-shadow: 0 0 12px var(--folio-border-twelve);
                        }
                    }
                }
            }
            &:nth-child(17) {
                .folio-card-wrapper  {
                    .folio-img {
                        &:hover {
                            border: 1px solid var(--folio-border-four);
                            box-shadow: 0 0 12px var(--folio-border-four);
                        }
                    }
                }
            }
            &:nth-child(18) {
                .folio-card-wrapper  {
                    .folio-img {
                        &:hover {
                            border: 1px solid var(--folio-border-fifteen);
                            box-shadow: 0 0 12px var(--folio-border-fifteen);
                        }
                    }
                }
            }
            &:nth-child(19) {
                .folio-card-wrapper  {
                    .folio-img {
                        &:hover {
                            border: 1px solid var(--folio-border-seven);
                            box-shadow: 0 0 12px var(--folio-border-seven);
                        }
                    }
                }
            }
            &:nth-child(20) {
                .folio-card-wrapper  {
                    .folio-img {
                        &:hover {
                            border: 1px solid var(--folio-border-ten);
                            box-shadow: 0 0 12px var(--folio-border-ten);
                        }
                    }
                }
            }
            &:nth-child(21) {
                .folio-card-wrapper  {
                    .folio-img {
                        &:hover {
                            border: 1px solid var(--folio-border-ten);
                            box-shadow: 0 0 12px var(--folio-border-ten);
                        }
                    }
                }
            }
            &:nth-child(22) {
                .folio-card-wrapper  {
                    .folio-img {
                        &:hover {
                            border: 1px solid var(--folio-border-twelve);
                            box-shadow: 0 0 12px var(--folio-border-twelve);
                        }
                    }
                }
            }
            &:nth-child(23) {
                .folio-card-wrapper  {
                    .folio-img {
                        &:hover {
                            border: 1px solid var(--folio-border-eleven);
                            box-shadow: 0 0 12px var(--folio-border-eleven);
                        }
                    }
                }
            }
            &:nth-child(24) {
                .folio-card-wrapper  {
                    .folio-img {
                        &:hover {
                            border: 1px solid var(--folio-border-sixteen);
                            box-shadow: 0 0 12px var(--folio-border-sixteen);
                        }
                    }
                }
            }
        }
    }
    .folio-card-wrapper {
        .folio-img {
            display: block;
            border: 1px solid transparent;
            transition: all 0.3s ease-in;
            img {
                width: 100%;
            }
            &:hover {
                border: 1px solid var(--folio-border-one);
                box-shadow: 0 0 12px var(--folio-border-one);
            }
        }
    
        .folio-content-wrapper {
            padding: 38px 0;
            .small-folio-heading {
                font-size: 14px;
                display: block;
                margin-bottom: 10px;
                color: var(--dark-grey);
            }
            .large-folio-heading {
                font-size: 18px;
                font-family: var(--theme-font);
                font-weight: 500;
            }
        }
    }
    
}

.solfin-btn-bg {
    background-color: var(--solfin-bg-four);
    &:hover {
        color: var(--primary-color);
        background-color: var(--solfin-green);
    }
}


.healthism-card {
    margin-top: -267px;
}

// mordern design 

.atmosphere-resizable-pannel {
    margin-top: 50px;
    .diffrence-text {
        h6 {
            font-family: var(--theme-font);
        }
    }
}

.casestudy-result-space {
    padding-bottom: 100px !important;
}

.difference-design {
    padding: 59px 0 50px;
    position: relative;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 8%;
        top: 30px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: top;
        background-image: url(../images/upper-frame.webp);
    }
    .left-pannel {
        width: 100%;
        background-position: inherit;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url(../images/varu-before.webp);
        box-shadow: 10px 10px 10px 0px rgba(143, 143, 143, 0.10);
        &.ozen-before-wrapper {
            background-image: url(../images/ozen-before.webp) !important;
        }
    }


    .right-pannel {
        background-position: right;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url(../images/varu-after.webp);
        box-shadow: 10px 10px 10px 0px rgba(143, 143, 143, 0.10);
        &.ozen-after-wrapper {
            background-image: url(../images/ozen-after.webp);
        }
    }

    .ResizePanel-module_ResizeContentHorizontal__1gGbA {
        max-width: 1269px;
    }

    .ResizePanel-module_ResizeBarHorizontal__3TBZ5 {
        height: 730px;
        margin-top: -59px;
        margin-left: 0 !important;
        margin-right: 0 !important;
        width: 6px !important;
        border-radius: 10px;
        z-index: 2;
        background: var(--case-study-color) !important;
    }
    .ResizePanel-module_ResizeHandleHorizontal__PkS9u {
        position: absolute;
        width: 48px !important;
        height: 48px !important;
        border-radius: 50% !important;
        border: 3px solid var(--case-study-color) !important;
        span {
            &::after {
                content: "" !important;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;            
                position: absolute;
                background-position: center;
                background-repeat: no-repeat;
                background-image: url(../images/green-arrow.svg);
            }
        }
    }
}

// ozen case study

.Ozen-heading-color {
    color: var(--ozen-small-heading) !important;
}

.ozen-about {
    .atmosphere-inner-heading {
        padding-right: 60px;
    }
}


.solfin-heading-left {
    padding-right: 0 !important;
}

.ozen-result-btn {
    background-color: var(--ozen-small-heading);
}

.game-result-btn {
    color: var(--game-store-theme);
    background-color: var(--game-store-green);
}

// consult-btn

.consult-btn-wrapper {
    position: relative;
    .consult-bottom-btn {
        position: fixed;
        bottom: 20px;
        right: 20px;
        width: 60px;
        height: 60px;
        display: flex;
        font-size: 30px;
        color: var(--bs-white);
        border-radius: 50px;
        align-items: center;
        justify-content: center;
        background-color: var(--secondary-color);
    }
}

// services
.service-main-banner {
    padding-bottom: 70px;
    background-color: var(--light-orange);
}
.service-banner-heading {
    font-size: 50px;
}

.service-banner-right {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: end;
    img {
        width: 100%;
        margin-bottom: -10px;
    }
}

// service about 
.about-service-wrapper {
    padding: 100px 0 50px;
    .service-about-para {
        width: 100%;
        margin: 0 auto;
        max-width: 935px;
    }
}


.atmosphere-case-study {
    padding-top: 50px;
}


.letter-size {
    font-size: 50px;
    font-weight: 700;
}

.service-btn-wrapper {
    margin-top: 57px;
}

.service-get-started-btn {
    border-radius: 0;
    padding: 14px 20px;
    text-transform: uppercase;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    background-color: transparent;
    transition: all ease-in 0.3s;
    &:hover {
        color: var(--bs-white);
        background-color: var(--primary-color);
    }
}

.service-card-wrapper  {
    .service-left {
        width: 633px;
    }
    .service-right {
        width: 100%;
        padding-left: 100px;
        max-width: calc(100% - 633px);
        .col-lg-6 {
            &:nth-child(3) {
                margin-top: 100px;
            }
            &:nth-child(4) {
                margin-top: 100px;
            }
        }
    }
}

.service-card-inner-wrapper {
    .row {
        .col-xl-3 {
            &:nth-child(2) {
                margin-top: 60px;
            }
            
            &:nth-child(4) {
                margin-top: 60px;
            }
        }
    }
}

.service-features {
    margin-bottom: 60px;
}

.Service-feature-card {
    border: 0;
    .card-img {
        width: 60px;
        margin-bottom: 28px;
    }
    .card-title {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 17px;
    }
}

.service-column {
    .col-lg-6 {
        &:nth-child(4) {
            margin-top: 90px;
        }
        &:nth-child(3) {
            margin-top: 90px;
        }
        &:nth-child(6) {
            margin-top: 90px;
        }
    }
}

.showcase-slider-wrapper {
    padding-top: 58px;
    position: relative;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-height: calc(100% - 30%);
        background-color: var(--showcase-extra-light-purple);
    }
    &.comet-slide-one {
        &::before {
            background-color: var(--comet-slide-one-color) ;
        }
    }
    &.comet-slide-two {
        &::before {
            background-color: var(--comet-slide-two-color) ;
        }
    } 
    &.comet-slide-three {
        &::before {
            background-color: var(--comet-slide-three-color) ;
        }
    }
    &.slide-two {
        &::before {
            background-color: var(--showcase-raaya-color) ;
        }
    }
    &.slide-three {
        &::before { 
            background-color: var(--showcase-3ischools-color) ;
        }
    }
    &.slide-four {
        &::before {
            background-color: var(--ozen-case-study-bg);
        }
    }

}

.showcase-slider {
    .showcase-inner-wrapper {
        display: flex;
        align-items: end;
        .showcase-right-img {
            position: relative;
            margin-left: auto;
            margin-right: auto;
            img {
                width: 100%;
            }
        }
    
        .showcase-left-text {
            bottom: 0;
            left: 0;
            position: absolute; 
            margin-bottom: 100px;
            h6 {
                color: var(--dark-grey);
                font-family: var(--theme-font);
                margin-bottom: 20px;
            }
            h5 {
                font-weight: 600;
                font-family: var(--theme-font);
            }
        }
    }

    .slick-next {
        right: -40px;
        &::before {
            color: transparent;
            background-image: url('../images/next.svg') !important;
        }
    }

    .slick-prev, .slick-next {
        top: 35%;
        width: 30px !important;
        height: 30px !important;
        &::before {
            height: 40px;
            display: flex;
            background-size: 20px;
            background-repeat: no-repeat;
        }
    }

    .slick-prev {
        left: -40px;
        &::before {
            color: transparent;
            background-image: url('../images/prev.svg')  !important;
        }
    }
}

.mobile-sapce {
    margin-left: 55px;
}

.statistics-para {
    margin-top: 49px;
    padding-right: 184px;
    margin-bottom: 30px;
}

.statistics-card-wrapper {
    padding:61px 35px;
    margin-right: 25px;
    width: 312px;
    background-color: var(--extra-light-orange);
    .card-img {
        width: 30px;
    }
    .card-text {
        font-size: 18px;
    }
    &.mob-statistic-card-one {
        background-color: var(--mob-extra-light-purple);
    }
    &.statistics-wrapper-two {
        margin-top: 30px;
        margin-bottom: 30px;
        background-color: var(--secondary-color) ;
        .card-title {
            color: var(--bs-white);
        }
        .card-img {
            filter: invert(0) !important;
            &.web-development-statistics-icon {
                filter: invert(1) !important;
            }
            &.brand-communication-img {
                filter: invert(1) !important;
            }
        }
        .card-text {
            color: var(--bs-white);
            font-weight: 500;
        }
    }
    &.mob-statistics-wrapper-two {
        margin-top: 30px;
        background-color: var(--theme-color);
        margin-bottom: 30px;
        .card-title {
            color: var(--bs-white);
        }
        .card-img {
            filter: invert(2);
        }
        .card-text {
            color: var(--bs-white);
            font-weight: 500;
        }
    }
    
    &.mob-statitics-cards-third {
        background-color: var(--primary-color);
        
        .card-title {
            color: var(--bs-white);
        }
        .card-img {
            filter: invert(2);
        }
        .card-text {
            color: var(--bs-white);
            font-weight: 500;
        }
    }
    &.statitics-cards-third {
        background-color: var(--extra-dark-orange);
        .card-title {
            color: var(--bs-white);
        }
        .card-img {
            filter: invert(2);
        }
        .card-text {
            color: var(--bs-white);
            font-weight: 500;
        }
    }
}

.statistics-img {
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

// game store case study
.game-heading-color {
    color: var(--game-store-theme) !important;
}

// web development responsive design section

.responsive-design-wrapper {
    padding: 50px 0;
}

.responsive-design-inner {
    padding: 56px 45px ;
    background-color: var(--responsive-design-bg);
}



.consult-portfolio-wrapper {
    padding: 70px 0 90px;
}



// web development services faqs

.faqs-wrapper {
    margin: 0 auto;
    width: 100%;
    max-width: 840px;
    padding-bottom: 50px;
    .faqs-big-heading {
        font-size: 50px;
    }
}


.faqs-accordion-wrapper {
    margin-top: 60px;
    .accordion-item {
        border: 0;
        .accordion-button {
            font-size: 24px;
            font-weight: 500;
            padding: 39px 30px 39px 20px ;
            font-family: var(--theme-font);
            border-radius: 0 !important;
            border-top: 1px solid var(--light-grey);
            &::after {
                background-image: url(../images/plus.svg);
            }
            &:focus {
                box-shadow: none;
            }
            &:not(.collapsed) {
                color: var(--primary-color);
                background-color: transparent;
                box-shadow: none;
                &::after {
                    background-image: url(../images/minus.svg);
                }
            }
        }
        .accordion-collapse {
            .accordion-body {
                padding: 0 20px 36px;
            }
            &:last-child {
                border-radius: 0;
                border-bottom: 1px solid var(--light-grey);
            }
        }
    }
}

.faqs-consult-wrapper {
    padding-bottom: 100px !important;
}

// Comet banner
.comet-main-banner {
    background-color: var(--purple-light-bg) !important;
}

.comet-heaidng-color {
    color: var(--theme-color);
}

.web-banner-heading {
    padding-right: 100px;
}
.uiux-heading {
    padding-right: 30px;
}

.company-service-wrapper {
    padding-bottom: 100px;
    .company-service-box {
        margin-top: 60px;
        .row {
            .col-lg-4 {
                margin-bottom: 30px;
                &:nth-child(4) {
                    margin-bottom: 0;
                }
                &:nth-child(5) {
                    margin-bottom: 0;
                }
                &:nth-child(6) {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.service-space {
    padding-top: 60px;
}

.get-done-cards {
    padding: 53px; 
    height: 100%;
    border: 1px solid var(--border-light-grey);
    transition: 0.3s all ease-in;
    .card-body {
        .card-text {
            padding-right: 30px;
        }
        .get-done-btn {
            font-size: 30px;
            color: var(--primary-color);
        }
    }
    &:hover {
        border: 1px solid var(--secondary-color);
        background-color: var(--secondary-color);
        .card-body {
            .card-title {
                color: var(--bs-white);
            }
            .card-text {
                color: var(--bs-white);
            }
            .get-done-btn {
                color: var(--bs-white);
            }
        }
    }
}


.comet-get-done-wrapper {
    &:hover {
        border: 1px solid var(--theme-color);
        background-color: var(--theme-color);
    }
}

.ecommerce-get-done-wrapper {
    &:hover {
        border: 1px solid var(--secondary-color);
        background-color: var(--secondary-color);
    }
}




.comet-brand-color {
    color: var(--features-text-color);
}

.comet-consult-btn {
    background-color: var(--features-text-color);
}

.ecommerce-consult-btn {
    background-color: var(--ecommerce-hover-bg);
}

.comet-feature-wrapper {
    margin-top: 68px;
}

.mob-consult-btn {
    background-color: var(--theme-color);
    &:hover {
        background-color: var(--primary-color);
    }
}

.comet-features-wrapper {
    padding: 50px 0 50px;
}

.benefits-wrapper {
    .benefits-heading-wrapper {
        width: 100%;
        max-width: 510px;
    }
    .benefits-content-wrapper {
        width: calc(100% - 510px);
    }
}

.features-inner-cards {
    margin-top: 60px;
    .row {
        .col-lg-4 {
            margin-bottom: 25px;
            &:nth-child(4) {
                margin-bottom: 0;
            }
            &:nth-child(5) {
                margin-bottom: 0;
            }
            &:nth-child(6) {
                margin-bottom: 0;
            }
        }
    }
}

.comet-features-card {
    padding: 64px;
    height: 100%;
    object-fit: cover;
    .card-img-wrapper {
        height: 295px;
        .card-img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}


.box .boxcontent ~ .boxtitle {
    transform: rotate(180deg);
}


// e-commerce

.ecommerce-main-banner {
    background-color: var(--light-orange);
}

.ecommerce-heading {
    padding-right: 20px;
}

.ecommerce-heaidng-color {
    color: var(--secondary-color);
}

.ecommerce-features-card {
    width: 100%;
    padding: 60px 35px;
    margin-bottom: 30px;
    align-items: center;
    flex-direction: initial;
    &:last-child {
        margin-bottom: 0;
    }
    &.brand-features-wrapper {
        .card-img-wrapper {
            &.ecommerce-feature-img {
                background-color: var(--theme-color);
            }
        }
    }
    .card-img-wrapper {
        width: 80px;
        height: 80px;
        display: flex;
        border-radius: 50px;
        align-items: center;
        justify-content: center;
        background-color: var(--primary-color);
        box-shadow: 10px 10px 34px -13px var(--dark-grey);
        .card-img {
            width: 40px;
        }
        &.ecommerce-feature-img {
            background-color: var(--secondary-color);
        }
    }
    .card-body {
        margin-left: 30px;
        text-align: start;
        width: 70%;
        .digital-text {
            min-height: 90px;
        }
    }
}


.features-img {
    margin: 0 auto;
    height: 724px;
    img {
        height: 100%;
        display: block;
        object-fit: cover;
    }
}

.ecommerce-showcase-slider {
    margin-top: 60px;
    .ecommerce-slide-text {
        margin-top: 30px;
        h6 {
            color: var(--dark-grey);
            letter-spacing: normal;
            text-decoration: underline;
        }
        h5 {
            letter-spacing: normal;
        }
    }
    .slick-slider {
        // .ecommerce-inner-slide {
        //     .ecommerce-image {
        //     }
        // }
        .slick-prev {
            top: 40%;
            margin-left: -70px;
            z-index: 2;
            &::before {
                width: 70px;
                height: 70px;
                position: absolute;
                background-repeat: no-repeat;
                background-position: center;
                color: transparent;
                background-image: url(../images/large-arrow-left.svg);
            }
        }
    
        .slick-next{
            top: 40%;
            margin-right: 0;
            z-index: 2;
            &::before {
                width: 70px;
                height: 70px;
                position: absolute;
                background-repeat: no-repeat;
                background-position: center;
                color: transparent;
                background-image: url(../images/work-next.svg);
            } 
        }
    }
}

// Mob Development

.mob-main-banner {
    background-color: var(--purple-light-bg);
}

.mob-heaidng-color {
    color: var(--theme-color);
}

.mob-feature-img {
    img {
        position: relative;
    }
}

.mob-card-wrapper {
    display: flex;
    margin-bottom: 100px;
    justify-content: space-between;
    align-items: center ;
    &.mob-inner-wrapper {
        flex-direction: row-reverse;
    }
    &.mob-feature-space {
        margin-bottom: 0;
    }
    .mob-card-icon {
        width: 80px;
        height: 80px;
        display: flex;
        border-radius: 50px;
        align-items: center;
        justify-content: center;
        background-color: var(--primary-color);
        box-shadow: 10px 10px 34px -13px var(--dark-grey);
        &.mob-feature-bg {
            background-color: var(--theme-color);
        }
    }
    .mob-content-wrapper {
        margin-left: 25px;
        width: calc(100% - 105px);
        &.mob-inner-content {
            text-align: end;
            margin-right: 25px;
            margin-left: 0;
            p {
                padding-left: 47px;
                padding-right: 0;
            }
        }
        p {
            padding-right: 60px;
            margin-bottom: 0;
        }
    }
}

.mob-slider-wrapper {
    .mob-slider-img {
        img {
            width: 100%;
        }
    }
    .mob-slider-content {
        margin-top: 28px;
    }
}

.mob-showcase-slider {
    .slick-slide {
        padding: 0 15px;
    }
    .slick-prev {
        top: 40%;
        margin-left: 50px;
        z-index: 2;
        &::before {
            width: 70px;
            height: 70px;
            position: absolute;
            background-repeat: no-repeat;
            background-position: center;
            color: transparent;
            background-image: url(../images/large-arrow-left.svg);
        }
    }

    .slick-next{
        top: 40%;
        margin-right: 100px;
        z-index: 2;
        &::before {
            width: 70px;
            height: 70px;   
            position: absolute;
            background-repeat: no-repeat;
            background-position: center;
            color: transparent;
            background-image: url(../images/work-next.svg);
        } 
    }
}

.consult-main-wrapper {
    padding: 50px 0;
}

// software development

.software-feature-wrapper {
    display: flex;
    .software-feature-left {
        margin-right: 30px;
    }
}

.our-process-wrapper {
    padding-bottom:  50px;
}

.digitech-working-wrapper {
    padding-bottom: 100px;
}

.process-accordion-wrapper {
    position: relative;
    .accordion {
        box-shadow: 0px 0px 13px -7px var(--purple);
        .accordion-item {
            position: relative;
            border: 1px solid var(--accordion-border);
            .accordion-header {
                .accordion-button {
                    font-size: 24px;
                    padding: 27px 81px;
                    border-radius: 0;
                    box-shadow: none;
                    font-weight: 500;
                    font-family: var(--theme-font);
                    &:not(.collapsed) {
                        font-size: 32px;
                        padding-top: 66px;
                        font-weight: 600;
                        color: var(--bs-white);
                        transition: 0.2s ease-in all;
                        background-color: var(--theme-color);
                    }
                    &::after {
                        background-image: none;
                    }
                }
            }
            .accordion-collapse {
                border: 0;
                border-radius: 0;
                .accordion-body {
                    padding: 27px 81px 100px;
                    transition: 0.2s all ease-in;
                    background-color: var(--theme-color); 
                    p {
                        font-size: 20px;
                        line-height: 36px;
                        color: var(--bs-white);
                    }
                    .accordion-number {
                        top: 0;
                        right: 0;
                        z-index: 9;
                        position: absolute;
                        margin-right: 40px;
                        span {
                            font-size: 60px;
                            font-weight: 600;
                            color: var(--bs-white);
                            font-family: var(--heading-font);
                        }
                    }
                }
            }
        }
    }
}

.software-technologies-card {
    flex-direction: inherit;
    border-radius: 0;
    margin-bottom: 24px;
    padding: 30px;
    .technologies-left-icon {
        height: 100%;
        width: 110px;
        display: flex;
        margin: 0 auto;
        align-items: center;
        justify-content: center;
        .card-img {
            width: 100%;
        }
    }
    .card-body {
        padding: 0;
        padding-left: 40px;
        .card-text {
            min-height: 90px;
        }
    }
}

.uiux-design-tabs {
    .rts___tabs___container {
        .rts___tabs { 
            scroll-behavior: smooth;
            .rts___tab {
                padding: 60px 50px;
                margin-bottom: 24px;
                border-radius: 0;
                border: 1px solid var(--light-grey) !important;
                &.active {
                    border-radius: 0;
                    padding-bottom: 90px;
                    border: 1px solid var(--secondary-color) !important;
                    background-color: var(--secondary-color);
                }
                &:last-child {
                    border-radius: 0;
                    margin-bottom: 0;
                }
                &:first-child {
                    border-radius: 0;
                }
                .serial-number {
                    display: none;
                }
                h5 {
                    font-size: 24px !important; 
                }
                p {
                    padding-left: 0;
                }
            }
        }
    }
}

.responsive-cards-wrapper {
    padding-left: 80px;
    .responsive-card-inner {
        padding: 45px 30px 45px 50px;
        background-color: var(--bs-white);
        margin-bottom: 30px;
        p{
            margin-bottom: 0;
            padding-right: 0;
        }
        .responsive-icon-wrapper {
            background-color: var(--secondary-color);
        }
    }
}


.responsive-img {
    display: flex;
    height: 100%;
    align-items: center;
    img {
        margin: auto 0;
        filter: drop-shadow(10px 10px 16px rgba(0, 0, 0, 0.2));
    }
}

.uiux-process-wrapper {
    padding-bottom: 50px;
}

.uiux-design-wrapper {
    padding-bottom: 50px;
}

.uiux-process-boxes {
    margin-top: 60px;
    padding-left: 0;
    display: flex;
    .process-item-wrapper {
        width: 224px;
        height: 610px;
        cursor: pointer;
        padding: 50px 20px;
        border: 1px solid var(--light-grey);
        transition-duration: 700ms;
        &.marketing-process-wrapper {
            height: 630px;
        }
        &.active {
            flex: 5;
            padding: 50px 79px;
            color: var(--bs-white);
            border: 1px solid var(--secondary-color);
            background-color: var(--secondary-color);
            .process-number {
                color: var(--bs-white);
                justify-content: start;
            }
            .process-img {
                opacity: 1;
                visibility: visible;
                margin: 0 auto 70px;
                background-color: var(--bs-white);
            }
            .process-content {
                h4 {
                    font-size: 32px;
                    margin-bottom: 40px !important;
                }
                p {
                    display: block;
                }
            }
        }
        .process-number {
            display: flex;
            font-size: 28px;
            font-weight: 600;
            color: var(--primary-color);
            justify-content: center;
        }
        .process-img {
            width: 160px;
            height: 160px;
            display: flex;
            opacity: 0;
            margin-bottom: 70px;
            visibility: hidden;
            align-items: center;
            border-radius: 50%;
            justify-content: center;
            transition: all 0.3s ease-in;
            img {
                width: 86px;
            }
        }
        .process-content {
            h4 {
                width: 100%;
                font-size: 28px;
                text-align: center;
                font-weight: 600;
                font-family: var(--theme-font);
                &.process-second-title {
                    font-size: 22px;
                }
            }
            p {
                display: none;
                text-align: center;
            }
        }
    }
}

.service-accordion-wrapper {
    display: none;
}

// overlapping Boxes

.overlapping-main-wrapper {
    position: relative;
    padding-bottom: 50px;
    .paragraph-wrapper {
        p {
            padding-left: 180px;
        }
    }
    .offer-heading-wrapper {
        h2 {
            padding-right: 210px;
        }
    }
}

.overlapping-wrapper {
    position: relative;
    height: 560px;
    margin-top: 60px;
    &.brand-overlap-wrapper {
        height: 598px;
    }
    .page {
        position: absolute;
        width: 100%;
        z-index: 1;
        display: flex;
        align-items: center;
        overflow: hidden;
        background-color: var(--extra-light-green-card);
        
        .next-slide-btn {
            display: none;
        }
        &.overlap-box-second,
        &.overlap-box-third {
            transform: translateY(620px);
            background-color: var(--light-green-card);
            margin-top: 30px;
        }
        &.overlap-box-third {
            background-color: var(--light-golden-card);
            margin-top: 60px;
        }
        .offer-deals-wrapper {
            padding-top: 80px;
            padding-left: 144px;
            .offer-text-wrapper {
                width: 679px;
                padding-bottom: 60px;
                h2 {
                    font-size: 40px;    
                    font-family: var(--theme-font);
                }
                p {
                    margin: 42px 0 50px;
                }
                .offer-btn {
                    border-radius: 0;
                    padding: 13px 40px;
                    color: var(--bs-white);
                    background-color: var(--primary-color);
                }
                
            }
            .offer-Img-wrapper {
                width: 100%;
                padding-left: 60px;
                max-width: calc(100% - 679px);
                img {
                    width: 100%;
                }
                &.brand-img-wrapper {    
                    padding-bottom: 60px;
                    padding-right: 120px;
                }
            }
        }
        &.brand-Overlapping-wrapper {
            background-color: var(--brand-elevate-one);
            .elevate-brand-wrapper {
                padding: 80px 120px 90px;
                .offer-text-wrapper {
                    padding: 0;
                }
                .brand-img-wrapper {
                    padding: 0;
                    padding-left: 60px;
                }
            }
            &.overlap-box-second {
                background-color: var(--brand-elevate-two);
            }
            &.overlap-box-third {
                background-color: var(--brand-elevate-three);
                .offer-deals-wrapper {
                    .offer-text-wrapper {
                        h2 {
                            padding-right: 120px;
                        }
                    }
                }
            }
        }
    }
}

// Looking- for packages
.looking-packages-wrapper {
    margin-top: 100px;
    display: flex;
    padding: 70px 60px;
    align-items: center;
    justify-content: space-between;
    background-color: var(--extra-light-grey);
}

.marketing-success-wrapper {
    padding-bottom: 50px;
}

.marketing-stories-slider {
    .marketing-img {
        padding: 112px 75px;
        display: flex;
        width: 964px;
        margin-right: 24px;
        align-items: center;
        justify-content: center;
        background-color: var(--marketing-bg-one);
        img {
            width: 100%;
        }
        &.ozen-marketing-img {
            background-color: var(--ozen-case-study-bg);
        }
        &.kanifushi-marketing-bg {
            background-color: var(--kanifushi-bg);
        }
    }
    .marekting-content-wrapper {
        padding: 65px 128px;
        width: 100%;
        max-width: calc(100% - 992px);
        border: 1px solid var(--light-grey);
        .marketing-small-title {
            color: var(--marketing-bg-two);
            &.ozen-small-title {
                color: var(--ozen-case-study-bg);
            }
            &.kanifushi-small-title {
                color: var(--kanishi-dark-bg);
            }
        }
        .marketing-large-title {
            font-size: 28px;
            font-family: var(--theme-font);
        }
        .keywords-ranking-wrapper {
            .keyword-title {
                display: block;
                margin-top: 66px;
                margin-bottom: 10px;
                font-weight: 500;
            }
            .keywords-list-wrapper {
                .list-group {
                    .list-group-item {
                        padding: 20px 23px;
                        .keyword-list-number {
                            font-size: 18px;
                            padding-left: 27px;
                            border-left: 1px solid var(--light-grey);
                            font-family: var(--theme-font);
                        }
                    }
                }
            }
        }
        
        .keywords-cards-wrapper {
            .statistics-card-wrapper {
                padding: 27px;
                margin-top: 15px;
                border-radius: 10px;
                color: var(--bs-white);
                background-color: var(--statistics-cards-bg);
                &.ozen-fist-card {
                    background-color: var(--ozen-case-study-bg);
                }
                &.kanifushi-fist-card {
                    background-color: var(--kanishi-dark-bg);
                }
                &.ozen-second-card {
                    margin-right: 0;
                    background-color: var(--ozen-marketing-card);
                }
                &.kanifushi-second-card {
                    background-color: var(--ozen-marketing-card);
                }
                &.marketing-spcae {
                    margin-right: 0;
                    background-color: var(--statistics-cards-two);
                }
                .card-text {
                    font-size: 14px;
                }
                .card-body {
                    .card-img {
                        filter: invert(2);
                    }
                }
            }
        }
    }

    .slick-slider, .slick-prev {
        top: 50%;
    }
}

.software-statistics-wrapper {
    .software-inner-statistics {
        background-color: var(--mob-extra-light-purple);
        &.statistics-wrapper-two {
            background-color: var(--theme-color);
        }
        &.statitics-cards-third {
            background-color: var(--primary-color);
        }
    }
}

.brand-benefits-wrapper {
    padding-top: 140px;
}

.brand-slider {
    .slick-slide {
        padding: 0 10px;
    }
}
.consult-fix-width{
    width: 230px;
}

.showcase-heading {
    margin-bottom: 60px;
}

.mob-features-heading {
    margin-bottom: 75px;
}

.statistics-wrapper-left {
    padding-top: 60px;
}

// privacy-policy 

.privacy-policy-wrapper {
    padding-bottom: 100px;
}

.privacy-policy-text {
    margin-top: 60px;
    h4 {
        font-family: var(--theme-font);
    }
}

.user-information {
    .user-name {
        width: 103px;
        height: 80px;
        img {
            width: 100%;
            border-radius: 50px;
        }
    }
}

.technology-slider-wrapper {
    display: none;
}

.technology-slider-img {
    width: 90px;
}

.mob-technology-img {
    width: 70px;
}

.comet-folio-heading {
    text-transform: uppercase;
}

// casestudy responsive tabs

.responsive-digitech-tab {
    display: none;
}

.home-testimonail-para {
    padding-right: 80px;
}

.web-feature-space {
    margin-bottom: 0 !important;
}

.mobile-feature-space  {
    margin-bottom: 0 !important;
}

// pricing 

.pricing-banner-wrappeer {
    padding-bottom: 290px;
    border-radius: 0 0 60px 60px;
    background: var(--background-gradient);
}

.pricing-inner {
    width: 100%;
    max-width: 742px;
    margin: 0 auto;
    h3 {
        font-weight: 600;
        font-family: var(--theme-font);
        margin-bottom: 37px;
    }
}

.pricing-wrapper {
    padding-bottom: 100px;
    .container {
        width: 1140px;
    }
}

.pricing-info-wrapper {
    .list-group {
        flex-direction: row;
        .list-group-item {
            border-radius: 0;
            width: 100%;
            padding: 0;
            .package-info {
                padding: 90px 52px 43px;
                .package-heading {
                    h4 {
                        font-family: var(--theme-font);
                        margin-bottom: 0;
                    }
                    span {
                        font-size: 14px;
                        margin: 20px 0 0;
                        display: inline-block;
                    }
                    h3 {
                        font-size: 36px;
                        font-weight: 600;
                        margin-bottom: 0;
                        font-family: var(--theme-font);
                        sup {
                            font-size: 20px;
                            font-weight: 700;
                        }
                    }
                    .custom-select {
                        border-radius: 0;
                        padding: 14px 20px;
                    }
                }

                .cut-off-price {
                    margin-top: 33px;
                    .cut-heading {
                        font-size: 20px;
                        font-weight: 500;
                        margin-bottom: 0;
                        color: var(--month-text);
                        font-family: var(--theme-font); 
                        sup {
                            font-size: 16px;
                            margin: 0;
                            top: -4px;
                        }
                    }
                }
                
                .discount-text {
                    font-size: 18px;
                    margin: 0;
                    text-align: center;
                    font-weight: 600;
                    display: block !important;
                }
            }
            &:nth-child(1) {
                border-right: 0;
            }
            &:nth-child(2) {
                display: block;
                border: 0;
                position: relative;
                z-index: 1;
                margin-top: -24px;
                border-bottom: 1px solid var(--bs-border-color);
                box-shadow: rgba(214, 209, 228, 0.5) 0px 0px 29px 0px;
                .package-info {
                    padding: 54px 52px 43px;
                    .package-btn-wrapper {
                        margin-top: 52px;
                    }
                }
                .recommended-wrapper {
                    padding: 10px 10px 0;
                    .recommended-text {
                        padding: 15px;
                        color: white;
                        font-size: 14px;
                        font-weight: 600;
                        background-color: var(--theme-color);
                    }
                }
            }
            &:nth-child(3) {
                border-left: 0;
            }
        }
        .collabrators-list-wrapper {
            margin-top: 60px;
            .collabrator-list-group {
                padding: 0 !important;
                border: 0 !important;
                margin-top: 0;
                font-size: 14px;
                border-bottom: 0 !important;
                margin-bottom: 20px;
                box-shadow: none !important;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        .package-btn-wrapper {
            margin-top: 52px;
            .pricing-btn {
                color: var(--bs-white);
                border: 1px solid var(--theme-color);
                background-color: var(--theme-color);
                &:hover {
                    border: 1px solid var(--primary-color);
                    background-color: var(--primary-color);
                }
            }
        }
    }

}

.compare-wrapper {
    padding-top: 55px;
    .payment-wrapper {
        margin-top: 40px;
        padding: 58px 53px;
        border: 1px solid var(--border-color);
    }
}


.compare-heading {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .compare-feature-wrapper {
        width: 273px;
        padding: 14px 27px;
        font-weight: 500;
        color: var(--primary-color);
        text-transform: uppercase;
        text-decoration: none;
        border: 1px solid var(--border-color);
        background-color: var(--bs-white);
    }
}

.payment-wrapper {
    border-radius: 0;
    .list-group-item {
        width: 33.3%;
        border: 0;
        margin-bottom: 24px;
        padding: 0 40px 0 40px;
        border-right: 1px solid var(--border-color);
        .payment-inner-wrapper {
            display: flex;
            .payment-icon {
                width: 100%;
                height: 50px;
                max-width: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 15px;
                border-radius: 50px;
                background-color: var(--theme-color);
                img {
                    width: 60%;
                }
            }
            .payment-text {
                h6 {
                    font-size: 14px;
                    font-weight: 600;
                    font-family: var(--theme-font);
                }
                p {
                    font-size: 13px;
                    line-height: 20px;
                }
            }
        }
        &:nth-child(1) {
            padding-left: 0;
        }
        &:nth-child(4) {
            padding-left: 0;
            margin-bottom: 0;
        }
        &:nth-child(3) {
            border-right: 0;
            padding-right: 0;
        }
        &:nth-child(6) {
            border-right: 0;
            padding-right: 0;
            margin-bottom: 0;
        }
        &:nth-child(5) {
            margin-bottom: 0;
        }
    }
}

.show-less-wrapper {
    width: 30px;
    height: 30px;
    border: 1px solid #797979;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    margin: -14px auto 0;
    svg {
        font-size: 12px;
    }
}

.payment-accepted-method {
    margin-top: 50px;
    border: 1px solid var(--border-color);
    padding: 19px 20px;
    .payment-accepted-text {
        margin-right: 35px;
        font-weight: 500;
    }
    .payment-accepted-wrapper {
        display: flex;
        align-items: center;
        .payment-image {
            width: 75px;
            height: 45px;
            border-radius: 8px;
            padding: 10px 6px;
            margin-right: 6px;
            display: inline-block;
            border: 1px solid var(--border-color);
            img {
                width: 100%;
            }
        }
    }
}

// pricing table

.pricing-table-wrapper {
    margin-top: 80px;
    overflow: hidden;
    .pricing-table-inner {
        border: 1px solid var(--border-color);
    }
    .package-table-headings  {
        width: 310px;
    }
    .pricing-data-inner {
        width: calc(100% - 310px);
    }
    .pricing-inner-table {
        padding: 6px 6px 25px;
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: end;
        border-right: 1px solid var(--border-color);
        p{
            line-height: normal;
        }
        h6 {
            font-weight: 600;
            font-family: var(--theme-font);
        }
        h4 {
            font-weight: 600;
            font-family: var(--theme-font);
            sup {
                font-weight: 500;
                font-size: 14px;
            }
        }
    }
    .table-responsive-wrapper {
        display: flex; 
        justify-content: space-between; 
        &.hide-pricing-box {
            display: none;
        }
        &.hide-table-responsive {
            display: none;
        }
    }
    .blank-box {
        height: 155.2px;
        border-right: 1px solid var(--border-color);
        &.hide-blank-box {
            display: none;
        }
        &.feature-right-blank {
            height: 60.8px;
            width: 100%;
            border-right: 0;
            border-top: 1px solid var(--border-color);
        }
    }
    .table-recommended-wrapper {
        display: block;
        font-weight: 500;
        padding: 11px 20px;
        text-align: center;
        margin-bottom: 14px;
        text-transform: uppercase;
        color: var(--bs-white);
        background-color: var(--theme-color);
    }

    .site-feature-heading {
        height: 60.8px;
        padding: 20px 30px;
        border-top: 1px solid var(--border-color) !important;
        h6 {
            font-weight: 600;
            margin-bottom: 0;
            font-family: var(--theme-font);
        }
    }

    .custom-domain-wrapper {
        padding: 15px 30px;
        height: 60.8px;
        border-right: 1px solid var(--border-color);
        border-top: 1px solid var(--border-color);
    }
    .customer-accounts-text {
        justify-content: center;
    }
    
    // .responsive-heading-box {
    //     border-bottom: 1px solid var(--border-color);
    // }
}

.hide-main-heading {
    display: none;
}
.border-bottom-show {
    border-bottom: 1px solid var(--border-color);
}

// atmosphere folio page

.atmosphere-banner-left {
    padding-right: 217px;
    h3 {
        margin-bottom: 40px;
    }
    .sub-heading-wrapper {
        margin-bottom: 40px;
        .folio-created-date {
            font-weight: 500;
            padding-right: 31px;
            display: inline-block;
            border-right: 1px solid var(--primary-color);
        }
        .folio-sub-heading {
            font-weight: 500;
            padding-left: 31px;
        }
    }
    a {
        margin-top: 40px;
    }
}

.atmosphere-banner-right {
    padding-left: 130px;
    h3 {
        font-size: 32px;
        font-weight: 600;
        margin-bottom: 30px;
        font-family: var(--theme-font);
    }
    .folio-deliverables-tabs {
        span {
            font-weight: 500;
            color: var(--primary-color);
            border: 1px solid var(--primary-color);
        }
    }
}

.heading-font-family {
    font-weight: 600;
    font-family: var(--theme-font);
}

.color-usage-heading {
    margin-bottom: 60px;
}

.primary-color-usage {
    ul {
        border-radius: 0;
        flex-direction: row;
        flex-wrap: wrap;
        li {
            width: 32%;
            height: 100%;
            margin-right: 24px;
            padding: 30px;
            margin-bottom: 24px;
            background-color: var(--atmosphere-color-one);
            .color-wrapper {
                h6 {
                    text-align: end;
                    color: var(--bs-white);
                    margin-bottom: 67px;
                }
                p {
                    font-size: 14px;
                    color: var(--bs-white);
                }
            }
            
            &:nth-child(2) {
                background-color: var(--atmosphere-color-two);
                h6 {
                    color: var(--primary-color);
                }
                p {
                    color: var(--primary-color);
                }
            }
            &:nth-child(3) {
                margin-right: 0;
                background-color: var(--atmosphere-color-three);
            }
            
            &:nth-child(4) {
                margin-bottom: 0;
                background-color: var(--atmosphere-color-four);
            }
            &:nth-child(5) {
                margin-bottom: 0;
                background-color: var(--atmosphere-color-five);
            }
            &:nth-child(6) {
                margin-right: 0;
                margin-bottom: 0;
                background-color: var(--atmosphere-color-six);
            }
        }
    }

    &.secondry-color-usage {
        ul {
            li {
                margin-top: 50px;
                background-color: var(--atmosphere-color-four);
                h6 {
                    color: var(--primary-color);
                }
                p {
                    color: var(--primary-color);
                }
                &:nth-child(2) {
                    margin-top: 0;
                    background-color: var(--atmosphere-color-five);
                    h6 {
                        color: var(--bs-white);
                    }
                    p {
                        color: var(--bs-white);
                    }
                }
                &:nth-child(3) {
                    background-color: var(--atmosphere-color-six);
                    h6 {
                        color: var(--bs-white);
                    }
                    p {
                        color: var(--bs-white);
                    }
                }
            }
        }
    }

    &.ahr-color-wrapper {
        ul {
            li {
                background-color: var(--case-study-color);
                &:nth-child(2) {
                    background-color: var(--pale-green);
                }
                &:nth-child(3) {
                    background-color: var(--off-white);
                    h6 {
                        color: var(--primary-color);
                    }
                    p {
                        color: var(--primary-color);
                    }
                }
                &:nth-child(4) {
                    background-color: var(--ahr-chamoisee-color);
                }
                &:nth-child(5) {
                    background-color: var(--ahr-moonstone-color);
                }
            }
        }
    }

    &.games-stores-colors {
        ul {
            li {
                background-color: var(--game-stores-green);
                h6 {
                    color: var(--primary-color);
                }
                p {
                    color: var(--primary-color);
                }
                &:nth-child(2) {
                    background-color: var(--game-store-theme);
                    h6 {
                        color: var(--bs-white);
                    }
                    p {
                        color: var(--bs-white);
                    }
                }
                &:nth-child(3) {
                    margin-bottom: 0;
                    background-color: var(--game-stores-light-grey);
                }
            }
        }
    }
    
    .primary-heading {
        margin-top: 82px;
    }
}

.atmosphere-website-folio {
    padding-bottom: 50px;
    overflow: hidden;
    .service-card-heading {
        margin-bottom: 40px;
    }

    
}

.website-tabs-wrapper {
    .rts___tabs___container {
        .rts___tabs  {
            scroll-behavior: smooth;
            .rts___tab {
                border-radius: 0; 
                margin-right: 24px;
                color: var(--primary-color);
                transition: all 0.4s ease-in;
                border: 1px solid var(--border-color) !important;
                &.rts___tab___selected {
                    color: var(--bs-white);
                    background-color: var(--primary-color);
                }
            }
        }
    }

    .tab-image-content {
        padding: 80px 0;
        margin-top: 40px;
        position: relative;
        background-color: var(--ozen-website-background);
        &.ahr-tab-content {
            background-color: var(--pale-green);
        }
        &.atmosphere-living-tab-content {
            background-color: var(--atmosphere-living-website-color);
        }
        &.game-stores-content {
            background-color: var(--game-store-case-study);
        }
        &.ucms-tab-image {
            padding: 80px !important;
            // .upper-frame {
            //     display: none !important;
            // }
        }
        .upper-frame {
            display: block;
            width: 960px;
            height: 32px !important;
            margin: 0 auto; 
            img {
                width: 100%;
            }
        }
        .right-side-image {
            padding: 0;
            width: 960px;
            overflow: hidden;
            margin: 0 auto; 
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
            cursor: n-resize;
            img {
                width: 100%;
                object-fit: cover;
                height: 630px !important;
                object-position: top;
                transition: 9s all ease-in;
            }
            &:hover {
                img {
                    object-position: bottom;
                }
            }
        }
        .website-dropshadow {
            width: 50%;
            height: 10px;
            margin: 60px auto 0;
            border-radius: 100%;
            filter: blur(12px);
            background-color: rgba(45, 94, 90, 0.5);
            &.ahr-dropshadow {
                background-color: #6C9993;
            }
            &.ozen-dropshadow {
                background-color: #55748D;
            }
            &.game-stores-shadow {
                background-color: var(--game-stores-shadow);
            }
        }
    }
    .ucmstab-image-content {
        padding: 80px 0 80px;
        margin-top: 40px;
        position: relative;
        background-color: var(--ozen-website-background);
            
        .upper-frame {
            width: 80%;
            display: block;
            margin: 0 auto;
            img {
                width: 100%;
            }
        }
        .right-side-image {
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                width: 80%;
            }
            &.hide-scroll-animation {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100% !important;
                height: 100% !important;
                padding: 0 !important;
                margin-top: 0 !important;
                img {
                    width: 80%;
                    cursor: auto;
                    position: relative;
                    bottom: 0 !important;
                    height: 100% !important;
                }
            }
        }
        &.ahr-ucms-wrapper {
            background-color: var(--pale-green);
        }
        .ahr-website-dropshadow {
            width: 50%;
            height: 10px;
            margin: 30px auto 0;
            border-radius: 100%;
            filter: blur(12px);
            background-color: rgba(45, 94, 90, 0.5);
            &.ucms-dropshadow {
                background-color: rgba(99, 127, 151, 0.5);
            }
        }
    }
}



.visit-site-btn {
    .theme-btn {
        padding: 14px 33px;
        border: 1px solid var(--primary-color);
        &:hover {
            color: var(--bs-white);
            background-color: var(--primary-color);
        }
    }
}

.atmosphere-mobile-folio {
    padding: 50px 0;
    .service-card-heading {
        margin-bottom: 40px;
    }
}

.atmosphere-brochure-folio {
    padding: 50px 0 0;
    .service-card-heading {
        margin-bottom: 40px;
    }
}

.collaterals-wrapper {
    .row {
        .col-lg-4 {
            margin-bottom: 24px;
            &:nth-child(4) {
                margin-bottom: 0;
            }
            &:nth-child(5) {
                margin-bottom: 0;
            }
            &:nth-child(6) {
                margin-bottom: 0;
            }
        }
    }
}

.healthism-btn-wrapper {
    display: block;
    margin-top: 100px;
    color: var(--bs-white);
    text-decoration: none;
    padding: 130px 0;
    &.ahr-ozen-wrapper {position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url(../images/ozen-bg-folio.webp);
        &::before {
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            backdrop-filter: blur(3px);
            position: absolute;
            background-color: rgba(50, 62, 72, 0.6);
        }
    }
    &.ozen-living-wrapper { 
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url(../images/atmosphere-living-bg.webp);
        &::before {
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            backdrop-filter: blur(3px);
            background-color: rgba(73, 91, 90, 0.6);
        }
    }
    &.atmosphere-living-wrapper {
        position: relative;
        overflow: hidden;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url(../images/al-link-bg.webp);
        &::before {
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            backdrop-filter: blur(3px);
            background-color: rgba(68, 85, 83, 0.7);
        }
    }
    span {
        font-size: 28px;
        margin-bottom: 45px;
        position: relative;
    }
    h2 {
        font-weight: 800;
        margin-bottom: 0;
        position: relative;
    }
}

.ahr-kanifushi-wrapper {
    padding-bottom: 50px;
}

.ozen-color-wrapper {
    ul {
        flex-wrap: wrap;
        &.ozen-color-usage {
            li {
                margin-bottom: 24px;
                background-color: var(--ozen-charcoal);
                &:nth-child(2) {
                    background-color: var(--ozen-timberwolf);
                }
                &:nth-child(3) {
                    background-color: var(--ozen-rich-black);
                    h6 {
                        color: var(--bs-white);
                    }
                    p {
                        color: var(--bs-white);
                    }
                }
                &:nth-child(4) {
                    margin-bottom: 0;
                    background-color: var(--ozen-small-heading);
                }
                &:nth-child(5) {
                    margin-bottom: 0;
                    background-color: var(--ozen-ecru);
                }
                &:nth-child(6) {
                    margin-bottom: 0;
                    background-color: var(--ozen-bettleship-grey);
                }
            }
        }
        li {
            background-color: var(--case-study-color);
            h6 {
                text-align: end;
                margin-bottom: 67px !important; 
            }
            p {
                text-align: left;
            }
            
            &:nth-child(3) {
                margin-right: 0;
                background-color: var(--off-white);
            }
            &:nth-child(2) {
                margin-top: 0;
                background-color: var(--pale-green);
            }
            &:nth-child(6) {
                margin-right: 0;
            }
        }
    }
}


.webasite-para-wrapper  {
    margin-bottom: 60px;
    .website-para {
        width: 81%;
    }
}

.collateral-heading {
    margin-bottom: 40px;
}

.pricing-tabs-wrapper {
    margin-top: -250px;
    .rts___tabs___container {
        margin-bottom: 30px;
        justify-content: center;
        .rts___nav___btn___container {
            display: flex !important;
            .rts___nav___btn {
                border: 0;
                padding: 0 8px;
                &:hover {
                    background-color: transparent;
                }
            }  
        }
        .rts___tabs {
            scroll-behavior: smooth;
            .rts___tab {    
                margin: 0 30px;
                padding: 10px 28px;
                border: 1px solid var(--dark-grey) !important;
                &.rts___tab___selected {
                    color: var(--bs-white);
                    background-color: var(--theme-color);
                    box-shadow: none;
                    border: 1px solid var(--theme-color) !important;
                }
            }
        }
    }
    
}




.monthly-toggle-btn {
    margin-bottom: 30px;
    .btn-primary {
        padding: 13px 20px;
    }
}


    .switches-container {
        width: 256px;
        position: relative;
        display: flex;
        position: relative;
        background-color: var(--bs-white);
        line-height: 44px;
        border-radius: 3rem;
        margin-left: auto;
        margin-right: 0;
        input {
            visibility: hidden;
            position: absolute;
            top: 0;
            &:nth-of-type(1) {
                &:checked~.switch-wrapper {
                    transform: translateX(0%);
                    .switch {
                        .monthly {
                            &:nth-of-type(1) {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
            &:nth-of-type(2) {
                &:checked~.switch-wrapper {
                    transform: translateX(100%);
                    .switch {
                        .monthly {
                            &:nth-of-type(2) {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
        label {
            width: 50%;
            padding: 0;
            font-size: 12px;
            margin: 0;
            text-align: center;
            cursor: pointer;
            color: var(--primary-color);
        }
    }


    .switch-wrapper {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 50%;
        padding: 0.15rem;
        z-index: 3;
        transition: transform .5s cubic-bezier(.77, 0, .175, 1);
        .switch {
            border-radius: 3rem;
            background-color: var(--theme-color);
            height: 100%;
            .monthly {
                width: 100%;
                text-align: center;
                opacity: 0;
                display: block;
                font-size: 12px;
                color: var(--bs-white) ;
                transition: opacity .2s cubic-bezier(.77, 0, .175, 1) .125s;
                will-change: opacity;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }


.show-features-wrapper {
    margin-top: 35px;
    .show-more-btn {
        font-size: 14px;
        padding: 14px 34px;
        color: var(--primary-color);
        text-decoration: none;
        border: 1px solid var(--border-color);
        svg {
            font-size: 11px;
        }
    }
}

// model

.get-started-model {
    .modal-dialog {
        max-width: 840px;
        .modal-content {
            border: 0;
            padding: 80px;
            .modal-header {
                padding: 0;
                border-bottom: 0;
                margin-bottom: 60px;
                .btn-close {
                    top: 0;
                    right: 0;
                    margin-top: 30px;
                    margin-right: 30px;
                    position: absolute;
                }
            }
            .modal-body {
                padding: 0;
                border: 0;
            }
            .modal-footer {
                padding: 0;
                border: 0;
                justify-content: start;
                .orange-btn {
                    border: 0;
                    margin: 0;
                    padding: 20px 37px;
                    border-radius: 0;
                    background-color: var(--secondary-color);
                }
            }
        }
    }
}

.brand-responsive-image {
    display: none !important;
}


.pricing-links-wrapper {
    margin-top: 60px;
    .pricing-heading {
        font-family: var(--theme-font);
    }
    .header-pricing-btn {
        color: var(--primary-color);
    }
}
  

.pricing-tab-screen {
    animation: fadeIn 1s backwards;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.colo-usage-main {
    margin-top: 100px;
}

.folio-logo-wrapper {
    padding: 43px 0;
    margin-top: -200px;
    background-color: var(--bs-white);
    box-shadow: 6px 7px 16px  var(--shadow-color);
    &.games-stores-logo {
        background-color: var(--game-store-theme);
        ul {
            li {
                .logo-image-wrapper {
                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
    &.ahr-logo-wrapper {
        ul {
            flex-wrap: wrap;
            li {
                padding: 0 90px;
                &:first-child {
                    .logo-image-wrapper {
                        img {
                            width: 170px;
                        }
                    }
                }
            }
        }
    }
    ul {
        flex-direction: row;
        align-items: center;
        justify-content: center;
        li {
            border: 0;
            padding: 0 124px;
            border-radius: 0 !important;
            border-right: 1px solid var(--border-color);
            background-color: transparent;
            &:last-child {
                border-right: 0;
            }
            .logo-image-wrapper {
                img {
                    width: 116px;
                }
                &.atmosphere-living-logo {
                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.typography-wrapper {
    &.iconography-wrapper {
        margin-top: 100px;
        .typography-inner-wrapper {
            padding: 62px 80px;
            &.ahr-icon-typography-wrapper {
                background-color: var(--atmosphere-living-light-bg) !important;
            }
            &.ahr-icon-typography-wrapper {
                background-color: var(--ahr-folio-bg) !important;
                .ozen-icon-wrapper {
                    h6 {
                        color: var(--primary-color);
                    }
                }
            }
            .row {
                .col-lg-4 {
                    &:nth-child(1) {
                        padding-right: 40px;
                    }
                    &:nth-child(2) {
                        padding: 0 40px;
                    }
                    &:nth-child(3) {
                        padding-left: 40px;
                    }
                }
            }
        }
    }
    .typography-heading {
        margin-bottom: 30px;
        h3 {
            font-size: 32px;
            font-weight: 600;
            font-family: var(--theme-font);
        }
    }
    .typography-inner-wrapper {
        padding: 80px;
        background-color: var(--ozen-charcoal);
        &.ahr-typography-wrapper {
            background-color: var(--ahr-folio-bg) !important;
            .typography-family-info {
                &.typography-family-right {
                    .alphabet-font-wrapper {
                        h1 {
                            font-weight: 400;
                            font-family: "Arial";
                            color: var(--case-study-color);
                        }
                        span {
                            font-weight: 600;
                            color: var(--case-study-color);
                        }
                    }
                    .alphabets-wrapper {    
                        padding-right: 100px;
                        h2 {
                            font-weight: 400;
                            font-family: "Arial";
                            color: var(--case-study-color);
                        }
                        h3 {
                            font-weight: 400;
                            font-family: "Arial";
                            color: var(--case-study-color);
                        }
                    }
                }
                .alphabet-font-wrapper {
                    h1 {
                        font-weight: 400;
                        font-family: "Georgia";
                        color: var(--case-study-color);
                    }
                    span {
                        font-weight: 600;
                        color: var(--case-study-color);
                    }
                }
                .alphabets-wrapper {    
                    padding-right: 140px;
                    h2 {
                        font-weight: 400;
                        font-family: "Georgia";
                        color: var(--case-study-color);
                    }
                    h3 {
                        font-weight: 400;
                        font-family: "Georgia";
                        color: var(--case-study-color);
                    }
                }
            }
        }
        .typography-family-info {
            .alphabet-font-wrapper {
                h1 {
                    margin-right: 74px;
                    font-family: var(--raleway-font);
                    color: var(--ozen-timberwolf);
                    font-size: 140px;
                }
                span {
                    color: var(--ozen-timberwolf);
                    font-size: 30px;
                }
            }
            .alphabets-wrapper {
                margin-top: 91px;
                padding-right: 150px;
                h2 {
                    font-size: 36px;
                    font-weight: 300;
                    word-break: break-all;
                    text-transform: uppercase;
                    color: var(--ozen-timberwolf);
                    font-family: var(--raleway-font);
                }
                h3 {
                    font-size: 28px;
                    font-weight: 300;
                    margin-top: 47px;
                    word-break: break-all;
                    color: var(--ozen-timberwolf);
                    font-family: var(--raleway-font);
                }
            }
        }
        .typography-font-info {
            .typography-font {
                margin-bottom: 40px;
                h1 {
                    font-size: 36px;
                    font-weight: 500;
                    margin-left: 115px;
                    color: var(--ozen-timberwolf);
                    font-family: var(--theme-font);
                }
                h2 {
                    font-size: 28px;
                    font-weight: 500;
                    margin-left: 115px;
                    color: var(--ozen-timberwolf);
                    font-family: var(--theme-font);
                }
                h3 {
                    font-size: 24px;
                    font-weight: 500;
                    margin-left: 115px;
                    color: var(--ozen-timberwolf);
                    font-family: var(--theme-font);
                }
                h4 {
                    font-size: 18px;
                    font-weight: 500;
                    margin-left: 115px;
                    color: var(--ozen-timberwolf);
                    font-family: var(--theme-font);
                }
                h5 {
                    font-size: 16px;
                    font-weight: 500;
                    margin-left: 115px;
                    color: var(--ozen-timberwolf);
                    font-family: var(--theme-font);
                }
                h6 {
                    font-size: 14px;
                    font-weight: 500;
                    margin-left: 115px;
                    color: var(--ozen-timberwolf);
                    font-family: var(--theme-font);
                }
                span {
                    font-size: 20px;
                    font-weight: 500;
                    color: var(--ozen-timberwolf);
                }
            }
        }
        &.atmosphere-living-typography {
            background-color: var(--atmosphere-living-light-bg);
            .typography-family-info {
                .alphabet-font-wrapper {
                    h1 {
                        color: var(--atmosphere-color-five);
                        font-family: "DunbarTall";
                        font-weight: normal;
                    }
                    span {
                        color: var(--atmosphere-color-five);
                        font-family: "DunbarTall";
                    }
                }
                .alphabets-wrapper {
                    h2 {
                        color: var(--atmosphere-color-five);
                        font-family: "DunbarTall";
                    }
                    h3 {
                        color: var(--atmosphere-color-five);
                        font-family: "DunbarTall";
                    }
                }
            }
            .typography-family-right {
                .alphabet-font-wrapper {
                    h1 {
                        font-family: "Public Sans", sans-serif;
                    }
                    span {
                        font-family: "Public Sans", sans-serif;
                    }
                }
                .alphabets-wrapper {
                    h2 {
                        font-family: "Public Sans", sans-serif;
                    }
                    h3 {
                        font-family: "Public Sans", sans-serif;
                    }
                }
            }
        }
        &.game-stores-typography-wrapper {
            background-color: var(--games-stores-bg);
            .typography-family-info {
                .alphabet-font-wrapper {
                    h1 {
                        font-weight: 600;
                        font-family: var(--poppins-font);
                        color: var(--game-store-theme);
                    }
                    span {
                        font-weight: 600;
                        color: var(--game-store-theme);
                    }
                }
                .alphabets-wrapper {    
                    padding-right: 140px;
                    h2 {
                        font-weight: 400;
                        font-family: var(--poppins-font);
                        color: var(--game-store-theme);
                    }
                    h3 {
                        font-weight: 400;
                        font-family: var(--poppins-font);
                        color: var(--game-store-theme);
                    }
                }
            }
            .typography-font-info {
                .typography-font {
                    margin-bottom: 40px;
                    h1 {
                        font-size: 40px;
                        font-weight: 500;
                        margin-left: 115px;
                        color: var(--game-store-theme);
                        font-family: var(--theme-font);
                    }
                    h2 {
                        font-size: 36px;
                        font-weight: 500;
                        margin-left: 115px;
                        color: var(--game-store-theme);
                        font-family: var(--theme-font);
                    }
                    h3 {
                        font-size: 22px;
                        font-weight: 500;
                        margin-left: 115px;
                        color: var(--game-store-theme);
                        font-family: var(--theme-font);
                    }
                    h4 {
                        font-size: 18px;
                        font-weight: 500;
                        margin-left: 115px;
                        color: var(--game-store-theme);
                        font-family: var(--theme-font);
                    }
                    h5 {
                        font-size: 16px;
                        font-weight: 500;
                        margin-left: 115px;
                        color: var(--game-store-theme);
                        font-family: var(--theme-font);
                    }
                    h6 {
                        font-size: 14px;
                        font-weight: 500;
                        margin-left: 115px;
                        color: var(--game-store-theme);
                        font-family: var(--theme-font);
                    }
                    span {
                        font-size: 20px;
                        font-weight: 500;
                        color: var(--game-store-theme);
                    }
                }
            }
        }
    }

    
}


.ozen-icon-wrapper {
    h6 {
        margin-bottom: 47px;
        font-family: var(--theme-font);
        color: var(--ozen-timberwolf);
    }
}



.ozen-inner-icons {
    margin-bottom: 20px;
    &.ahr-inner-icon {
        margin-bottom: 0px;
        .icon-wrapper {
            margin-bottom: 64px;
            background-color: var(--bs-white) !important;
        }
    }
    .icon-wrapper {
        width: 66px;
        height: 66px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--ozen-rich-black);
        &.yellow-icons {
            margin-bottom: 62px;
            background-color: transparent;
        }
    }
}

.atmosphere-ui-sheet {
    padding-top: 50px;
    .website-img {
        padding: 0 20px;
        background-color: var(--ozen-website-background);
        img {
            width: 100%;
        }
    }
}

.copywriting-list-wrapper {
    .list-group {
        .list-group-item {
            border: 0;
            padding: 24px 0;
            border-radius: 0;
            border-bottom: 1px solid var(--border-color);
            h5 {
                font-weight: 600;
                font-family: var(--theme-font);
            }
            p {
                margin-bottom: 0;
            }
            &:last-child {
                border-bottom: 0;
                padding-bottom: 0;
            }
        }
    }
}

.ahr-ui-sheet-wrapper {
    padding: 15px;
    background-color: var(--pale-green);
}

.al-ui-sheet-wrapper {
    padding: 15px;
    background-color: var(--al-ui-sheet-bg);
}

.payment-wrapper {
    padding-bottom: 100px;
    .payment-inner-wrapper {
        width: 100%;
        max-width: 650px;
        margin: 0 auto;
        h2 {
            font-size: 40px;
            font-weight: 700;
            margin-bottom: 20px;
        }
    }
}

.payment-radio {
    .form-check-input {
        &:checked {
            border: 1px solid var(--dark-grey);
            background-color: var(--dark-grey);
        }
        &:focus {
            box-shadow: none;
        }
    }
}

.payment-select-wrapper {
    border: 1px solid var(--border-color);
    &:hover {
        border: 1px solid var(--secondary-color);
    }
}

.payment-custom-select {
    width: 100px;
}

// custom tooltip
.tooltip {
    position: relative;
    opacity: 1;
    &.hide-tooltip {
        display: none;
    }
    .tooltiptext {
        visibility: hidden;
        width: 370px;
        font-size: 14px;
        background-color: var(--primary-color);
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 10px;
        position: absolute;
        z-index: 1;
        top: 0;
    }
    svg {
        font-size: 20px;
    }
    &:hover {
        .tooltiptext {
            visibility: visible;
        }
    }
  }

.al-brochure-wrapper {
    padding: 119px 94px;
    background-color: var(--atmosphere-color-five);
}
 
.collateral-wrapper {
    .row {
        .col-lg-4 {
            &:nth-child(1) {
                margin-bottom: 24px;
            }
            &:nth-child(2) {
                margin-bottom: 24px;
            }
            &:nth-child(3) {
                margin-bottom: 24px;
            }
        }
    }
    .al-collaterals-wrapper {
        overflow: hidden;
        img {
            transition: 0.4s all ease;
        }
        &:hover {
            img {
                transform: scale(1.1);
            }
        }
    }
}

.digital-marketing-heading-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .marekting-heading {
        border-radius: 50px;
        padding: 10px 28px;
        color: var(--bs-white);
        background-color: var(--theme-color);
    }
}



.dm-pricing-data-inner {
    display: flex;
    width: 100% !important;
    align-items: center; 
    .digital-marketing-table-responsive {
        display: flex;
        width: calc(100% - 324px);
        border-left: 1px solid var(--border-color);
    }
    .digital-marketing-blank-box {
        width: 324px;
        height: 100%;
    }
}

.dm-show-text {
    display: flex !important;
}

.dm-hide-text {
    display: none !important;
}

.folio-tabs-navigation {
    .folio-tab-back {
        display: none;
    }
}

.game-store-ui-wrapper {
    background-color: var(--game-store-case-study);
}

// ecommerce-logos

.ecommerce-product-logo {
    overflow: hidden;
    position: reletive;
}

.ecommerce-slider-wrapper {
    .slick-slider {
        padding: 0 10px;
        .slick-list {
            .slick-track {
                display: flex;
                align-items: center;
                .slick-slide {
                    display: flex !important;
                    align-items: center;
                    justify-content: center;
                    .ecommerce-logo-slide {
                        display: flex !important;
                        align-items: center;
                        justify-content: center;
                        height: 170px;
                        width: 230px !important;
                        img {
                            width: 90%;
                            &.ecommerce-img {
                                width: 70%;
                            }
                            &.ecommerce-img-bfsg {
                                width: 60%;
                            }
                            &.ecommerce-img-hummings {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}